import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import Header from './../parts/Header';
import features_text from '../assets/features_text.json'
import '../common.css';
import './feature.css';
import {functionFields} from '../MyFunction';
import {Helmet} from 'react-helmet';
import settings from '../../settings.json';
//import bootstrap from './assets/bootstrap.css';

function Privacy() {
  const [seoTitle, setSeoTitle] = useState('');
  const [seoDescri, setSeoDescri] = useState('');

  useEffect(() => {

    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {
    
    }else{
      //console.log('_style_pc');
      const $style = document.createElement("link");
      $style.rel = "stylesheet";
      $style.type = "text/css";
      $style.href = "/css/pc.css";
      document.head.appendChild($style);
    }

    setSeoTitle("（株）まるいちの仏壇 個人情報の取り扱いについて/"+settings.TITLE);
    setSeoDescri("個人情報の取り扱いに関するページ/"+settings.DESCRIPTION);
  }, []);



  return (
  <> 
    <Helmet>
      <title>{seoTitle}</title>
      <meta
        name="description"
        content={seoDescri}
      />
    </Helmet>

    <Header />

    <div className="ItemList">
      <p className="pankuzu"><a href="/">トップ</a> ＞ 個人情報保護方針</p>
      
      <h2 className="page-h2 feature-h2">個人情報保護方針</h2>
      <img src="images/top/privacy-1.jpg" alt={'京都の小型仏壇店まるいちの特徴:'+'個人情報'} className="feature-img" />
      <div className="feature-text">

        <div>

          {/*<h2 className="page-h2 feature-h2 pb-0" id="q1">当社の個人情報保護方針</h2>*/}
          <p>株式会社まるいちは、お客様より提供頂いた個人情報を重要なものと認識し、慎重に取り扱いしております。</p>
          <p>個人情報保護に関する法令を遵守するとともに、弊社が保管する個人情報は厳重に管理しております。</p>

          <h2 className="page-h2 feature-h2 pb-0 t-center" id="q1">個人情報の利用目的</h2>
          <ul className="single-page-ul">
            <li>葬儀および当社事業に関する情報のご案内・サービス提供</li>
            <li>官公庁などの要請等法令により、必要な業務遂行のため</li>
          </ul>
          <p>官公庁などの要請等法令による場合を除き、ご本人の同意なく第三者への開示・提供は行いません。</p>
        </div>

      </div>
      
    </div>
  </> 
  );
}

export default Privacy;
