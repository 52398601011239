import { useEffect, useState } from "react";
import TopHeader from './TopHeader';
import FloatingButton from "../components/parts/FloatingButton";
import {functionFields} from '../components/MyFunction';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast'
import settings from '../settings.json';
import './assets/admin.css';
//import bootstrap from './assets/bootstrap.css';

import SortableList, { SortableItem } from 'react-easy-sort'
import arrayMove from 'array-move'

function ListItemAdmin() {
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState();
  const [pageCount, setpageCount] = useState(10);
  const [searchType, setSearchType] = useState('admin_list');
  const [pageTitle, setPageTitle] = useState('');
  const [itemType, setItemType] = useState('');


  var url = settings.GET_OBUTSUDAN_API_URL;

  useEffect(() => {
    //console.log('⭐️');
    var type = functionFields.getParameterByName('type');
    var type = 'list_'+type;
    
    const getDBData = async () => {
      const res = await fetch(
        url + `?type=`+type+'&sort_type=""'
      );
      const data = await res.json();
      //console.dir(data);
      const total = JSON.parse(data.total);

      document.getElementsByTagName('body')[0].style.borderTop = 'initial';
      document.getElementsByClassName('header')[0].style.display = 'none';  

      if(total>0){
        var dataText = data.data;
        var dataText = dataText.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
        //console.log(dataText);
        setItems(JSON.parse(dataText));
      }
      
      setTotal(data.total);

    };
    getDBData();

    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {
    
    }else{
      //console.log('_style_pc');
      const $style = document.createElement("link");
      $style.rel = "stylesheet";
      $style.type = "text/css";
      $style.href = "/css/pc.css";
      document.head.appendChild($style);

    }

    var pageTitle = functionFields.getParameterByName('type');
    if(pageTitle==='butsudan'){
      setPageTitle('お仏壇');
      setItemType('butsudan');
    }else if(pageTitle==='butsugu'){
      setPageTitle('仏具');
      setItemType('butsugu');
    }


    //footer hidden
    document.getElementById('footer').style.display = 'none'; 
    


    //Bootstrap
    //const link = document.createElement('link');
    //link.href = {bootstrap};
    //link.rel = "stylesheet";
    //link.integrity = "sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3";
    //document.head.appendChild(link);
  }, []);


  const fetchData = async (paramerter, value) => {
    //console.log('⭐️_1');
    //console.log(paramerter);
    //console.log(value);
    //if(paramerter==='currentPage'){
    //  var fetchUrl = url + `?type=`+searchType+`&text=`+searchText+`&page=`+String(value)+`&limit=`+String(limit);
    //}else if(paramerter==='text'){
    //  var fetchUrl = url + `?type=`+searchType+`&text=`+value+`&page=`+String(currentPage)+`&limit=`+String(limit);
    //}else if(paramerter==='type'){
    //  var fetchUrl = url + `?type=`+value+`&text=`+searchText+`&page=`+String(currentPage)+`&limit=`+String(limit);
    //}else if(paramerter==='list'){
    //  var fetchUrl = url + `?type=`+searchType+`&text=`+searchText+`&page=`+String(currentPage)+`&limit=`+value;
    //}
    //console.log(fetchUrl);
//
//
    //const res = await fetch(fetchUrl);
    //const data = await res.json();
    //console.dir(data);
    //return data;
  };




//LIST変更
  const handleChangeSelect = async(event) => {
    //console.log('⭐️_5');

  }

  const delItem = (e) => {
    //console.dir(e.target.id);
    //itemType //butsudan, butsugu
    var query = '?item_type='+itemType+'&db_id='+String(e.target.id)+'&function=del';
    //console.log('query '+query);

    var url = 'https://kyoto-butsudan.com/API/db_crud.php'+query;
    
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    
    axios.get(url).then((response) => {
      //console.dir(response);
      if(response.data.result==='remove_ok'){
        toast.success('削除OK', {duration: 5000});
        setTimeout(function(){
          window.location.reload(true);
        }, 2000);//wait 2 seconds
      }else{
        toast.error('削除失敗...', {duration: 5000});
      }
    });

  }



  const onSortEnd = (oldIndex, newIndex) => {
    setItems((array) => arrayMove(array, oldIndex, newIndex));
    var oldIndex = oldIndex+1;
    var newIndex = newIndex+1;
    //console.log('old-new '+String(oldIndex)+' '+String(newIndex));
    var query = '?item_type='+itemType+'&db_id='+String(oldIndex)+'&new_id='+String(newIndex)+'&function=change_id';
    //console.log('query '+query);

    var url = 'https://kyoto-butsudan.com/API/db_crud.php'+query;
    
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    
    axios.get(url).then((response) => {
      //console.dir(response);
      if(response.data.result==='ok'){
        //console.log('list_change_ok');
        //toast.success('変更OK:'+String(oldIndex)+' -> '+String(newIndex), {duration: 2000});
      }else{
        toast.error('変更失敗.'+String(oldIndex)+' -> '+String(newIndex), {duration: 2000});
      }
    });
  }



  return (
    <div className="">
      <link rel='stylesheet' href='https://fonts.googleapis.com/icon?family=Material+Icons' />
      <TopHeader/>
      <p>{pageTitle}一覧 <span className="fontsize-s">({total}点)</span></p>
      <ul className="item-list-admin">

      <SortableList
        onSortEnd={onSortEnd}
        className="list"
        draggedItemClassName="dragged"
      >
        {items.map((item)=>{
          //console.log('map');
          //console.log(item);
          var itemText = item.text_obj_string.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
          var thisItemObj = JSON.parse(itemText);
          var thisImage = settings.IMG_BASE_URL_BUTSUDAN + thisItemObj.img_1;
          
          var thisSpecObj = JSON.parse(item.spec_obj_string);
          var thisColor = thisSpecObj.color;
          if(thisColor===null){
            thisColor='';
          }

          var itemName = item.name;
          //console.log(itemName+' '+String(itemName.length));
          if(itemName.length+thisColor.length>11){
            var itemName = itemName.substring(0,8)+'..';
            var thisColor = thisColor.substring(0,3)+'..';
          }

          return(
            <SortableItem key={item.id}>
            <li key={item.id}>
              <div>
                <div>{item.id}</div>
                <div>{itemName} <span className="fontsize-s">({thisColor})</span></div>
                <div><img className="admin-list-img" src={thisImage}/></div>
                <div>
                  {(() => {
                    if (item.status==='on') {
                      return <p className="fontsize-s">公開中</p>
                    } else {
                      return <p className="fontsize-s">非公開</p>
                    }
                  })()}
                  
                </div>
                <div>
                  <a href={"/crud?type=edit&item="+itemType+"&db_id=" + item.db_id} > 
                    <i className="material-icons">create</i> 
                  </a>
                </div>
                <div onClick={delItem} id={item.db_id}>
                  <a className="no-link" href={"/crud?type=edit&item="+itemType+"&db_id=" + item.db_id} > 
                    <i className="material-icons">delete</i> 
                  </a>
                </div>
              </div>
            </li>
            </SortableItem>
          )
        })}

      </SortableList>
      </ul>

      <FloatingButton />

      <div><Toaster position="top-right" /></div>
    </div>
  );
}

export default ListItemAdmin;
