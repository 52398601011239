//管理メニューからの休店時間追加
//参考 https://www.positronx.io/react-datepicker-tutorial-with-react-datepicker-examples/
import React, { Component } from 'react';
import DatePicker, { registerLocale } from "react-datepicker/dist/es"
import MultiDatePicker from "react-multi-date-picker"//https://shahabyazdi.github.io/react-multi-date-picker/other-examples/
import ja from 'date-fns/locale/ja';

import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import isSameDay from 'date-fns/isSameDay';
import addDays from 'date-fns/addDays';
import settings from '../../settings.json';
import "react-datepicker/dist/react-datepicker.css";

import { functionFields } from '../MyFunction';
import toast, { Toaster } from 'react-hot-toast'
import axios from "axios";


class Calender2 extends Component {
  constructor (props) {
    super(props)
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      holiday: new Date(),
      buttonDisplay:true,
      allExcludeTimes:[],
      excludeTimes:'',
      holidays:[],
      selectBreakTimeObj:new Date(),
      checkBreakTimeResult:'OK',
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleChangeHolyday = this.handleChangeHolyday.bind(this);
    this.onFormSubmit_1 = this.onFormSubmit_1.bind(this);
    this.onFormSubmit_2 = this.onFormSubmit_2.bind(this);
  }

  componentDidMount () {
    registerLocale("ja", ja); 
    //予約済み,休店
    var url = settings.GET_NOT_RESERVE_TIME_API_URL;
    const getDBData = async () => {
      const res = await fetch(url+'?type=calender');
      const data = await res.json();
      var dateDbArray = [];
      try{
        var dateArray = JSON.parse(data.data);
        //console.dir( dateArray);
        for(var i = 0; i < dateArray.length; i++){
          var dateObjArray = functionFields.convertdate('str-obj-full', dateArray[i]);
          //console.log(dateObjArray);
          dateDbArray.push(dateObjArray[0]);
          if(dateObjArray[1]!==''){
            dateDbArray.push(dateObjArray[1]);
          }
        }
      }catch(e){
        console.log('予約なし');
      }

      this.setState({allExcludeTimes:dateDbArray});
      this.setState({excludeTimes:dateDbArray});

    };
    getDBData();
    //ページ読み込み時予約読み込み
    const getExcludeTimesForDate = (date) =>
      this.state.allExcludeTimes.filter((time) => isSameDay(date, time));
    this.setState({excludeTimes:getExcludeTimesForDate(new Date())} );

    
    //営業時間外を非表示に
    var startTime = settings.startTime;//開店時間 10時
    var endTime = settings.endTime;//閉店時間 20時、19時まで受付
    const $style = document.createElement("style");
    document.head.appendChild($style);
    $style.innerHTML = `
      .react-datepicker__time-list-item:nth-of-type(-n+`+String(startTime)+`),
      .react-datepicker__time-list-item:nth-of-type(n+`+String(endTime+1)+`){
        display:none;
      }
    `;
  
    //休日
    var url = settings.GET_NOT_RESERVE_TIME_API_URL;
    const getDBData2 = async () => {
      const res = await fetch(url+'?type=get_holidays');
      const data = await res.json();
      var dateDbArray = [];
      try{
        var dateArray = data.data;
        //console.dir( dateArray);
        for(var i = 0; i < dateArray.length; i++){
          var dateObj = new Date(dateArray[i]);
          dateDbArray.push(dateObj);
        }
      }catch(e){
        console.log('予約なし');
      }
      this.setState({holidays:dateDbArray});

    };
    getDBData2();



    //var holidays = [addDays(new Date(), 1)];
    //this.setState({holidays:holidays});
    
    document.getElementsByClassName("react-datepicker-wrapper")[0].style.position = "initial";
    document.getElementsByClassName("react-datepicker-wrapper")[1].style.position = "initial";
  }
  


  handleChangeStart(date) {
    this.setState({
      startDate: date
    })
    this.setState({selectBreakTimeObj:date});
  }

  handleChangeEnd(date) {
    this.setState({endDate: date})

    //console.dir(date);//date obj   Sat May 21 2022 10:00:00 GMT+0900 (日本標準時)
    var postDatePbj = new Object();
    postDatePbj['start'] = this.state.selectBreakTimeObj.getTime();
    postDatePbj['end'] = date.getTime();
    postDatePbj['type'] = 'checkBreakTime';
    //console.dir(postDatePbj);

    var url = settings.GET_NOT_RESERVE_TIME_API_URL;
    var res = axios.post(url, postDatePbj);//Promise
    res.then((value)=>{
      //console.dir(value.data);
      if(value.data.check_result==='NO'){
        var reserveText = value.data.reserve_dates;
        var reserveText = reserveText.slice(1);
        var reserveText = reserveText.slice(0, -1);
        var reserveText = reserveText.replace(',', ',\n');

        this.setState({checkBreakTimeResult:'NO'});
        alert('来店予約とかぶっています.\n'+reserveText);
      }else{
        //console.log('__OK');
        this.setState({checkBreakTimeResult:'OK'});
      }

    })
  }

  handleChangeHolyday(date) {
    //console.dir(date);
    this.setState({
      holiday: date
    })
  }
  onFormSubmit_1(e) {//休店時間
    //console.log(this.state.checkBreakTimeResult);
    e.preventDefault();
    if(this.state.checkBreakTimeResult==='OK'){
      //console.log(this.state.startDate)//Obj
      var s = functionFields.convertdate('obj-str-full', this.state.startDate );
      var e = functionFields.convertdate('obj-str-full', this.state.endDate );
  
      var result = window.confirm('休店時間の設定でOKですか？\n('+s+' から '+e+')');
      if( result ) {
        //console.log('OKがクリックされました');
        var s = functionFields.getStringFromDate(this.state.startDate);
        var e = functionFields.getStringFromDate(this.state.endDate);
        var url = settings.GET_NOT_RESERVE_TIME_API_URL;
        var getDBData = async () => {
          const res = await fetch(url+'?type=add_break_time&s='+s+'&e='+e);
          const data = await res.json();
          //console.log(data);
          if(data.result==='ok'){
            toast.success('設定しました.');
            setTimeout(function(){
              window.scrollTo(0, 0);
              window.location.reload(true);
            }, 3000);//wait 2 seconds
          }else{
            alert('他予定と重複したため、本設定はキャンセルしました.');
          }
        };
        getDBData();
  
      }
      else {
        console.log('キャンセル');
      }
    }else{
      alert('休店おわり時間の設定が正しくありません.');
    }

  }

  onFormSubmit_2(e) {//休業日
    e.preventDefault();
    //console.log(this.state.holiday);
    var holidays = this.state.holiday;
    var holidaysArray = [];
    for(var i=0; i<holidays.length; i++){
      holidaysArray.push(holidays[i]);
    }
    var holidaysString = holidaysArray.join();
    var result = window.confirm('休業日の設定でOKですか？\n('+holidaysString +')');
    if( result ) {
      var url = settings.GET_NOT_RESERVE_TIME_API_URL;
      var getDBData = async () => {
        const res = await fetch(url+'?type=add_holidays&dates='+holidaysString);
        const data = await res.json();
        //console.log(data);
        if(data.result==='ok'){
          toast.success('設定しました.');
          setTimeout(function(){
            window.scrollTo(0, 0);
            window.location.reload(true);
          }, 3000);//wait 2 seconds
        }else{
          alert('他予定と重複したため、本設定はキャンセルしました.');
        }
      };
      getDBData();
    }
    else {
        console.log('キャンセル');
    }
  }

  
  
  render() {
    //console.log(this.state.holidays);
    //console.log(this.state.allExcludeTimes);
    //console.log(this.state.excludeTimes);

    //registerLocale('ja', ja);

    const handleCalendarOpen = () => {
      //console.log('open');
      this.setState({buttonDisplay:false})

      if(this.state.pageType==='admin'){
        document.getElementById("fab1").style.display='none';
        var i = 0;
        while(i<6){
          document.getElementsByClassName("fab")[i].style.display='none';
          i+=1;
        }
      }

      document.getElementsByClassName("react-datepicker__day--today")[0].click();

    }
    const handleCalendarClose = () => {
      //console.log('close');
      this.setState({buttonDisplay:true})

      if(this.state.pageType==='admin'){
        document.getElementById("fab1").style.display='flex';
        var i = 0;
        while(i<6){
          document.getElementsByClassName("fab")[i].style.display='flex';
          i+=1;
        }
      }
    }



    const getExcludeTimesForDate = (date) => this.state.allExcludeTimes.filter((time) => isSameDay(date, time));

    const months = ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
    const weekDays = [
      ["sun", "日"], //[["name","shortName"], ... ]
      ["mon", "月"],
      ["tue", "火"],
      ["wed", "水"],
      ["thu", "木"],
      ["fri", "金"],
      ["sat", "土"],
    ]

    //console.log(this.state.allExcludeTimes);
    return (
      <>
        <div><Toaster position="top-right" /></div>
        <form onSubmit={ this.onFormSubmit_1 }>
          <div className="form-group add-break-time-form">
            <p>休店開始時間</p>
            <DatePicker
              locale="ja"
              //months={months}
              //weekDays={weekDays}

              minTime={setHours(setMinutes(new Date(), 0), settings.startTime)}
              maxTime={setHours(setMinutes(new Date(), 31), settings.endTime+1)}
  
              id='start_data'
              selected={ this.state.startDate }
              onChange={ this.handleChangeStart }
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={60}
              timeCaption="時間"
              dateFormat="yyyy.MM.dd aa h:mm"
              onCalendarOpen={handleCalendarOpen}
              onCalendarClose={handleCalendarClose}
              onSelect={(date) => {
                this.setState({excludeTimes:getExcludeTimesForDate(date)} );
                //console.log(date);
              }}
              //excludeTimes={this.state.allExcludeTimes}
              excludeTimes={this.state.excludeTimes}
              excludeDates={this.state.holidays}
            />
            <p>休店終了時間</p>
            <DatePicker
              locale="ja"
              //months={months}
              //weekDays={weekDays}

              minTime={setHours(setMinutes(new Date(), 0), settings.startTime)}
              maxTime={setHours(setMinutes(new Date(), 31), settings.endTime+1)}
              id='end_data'
              selected={ this.state.endDate }
              onChange={ this.handleChangeEnd }
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={60}
              timeCaption="時間"
              dateFormat="yyyy.MM.dd aa h:mm"
              onCalendarOpen={handleCalendarOpen}
              onCalendarClose={handleCalendarClose}
              //onSelect={(date) => {
              //  this.setState({excludeTimes:getExcludeTimesForDate(date)} );
              //  console.log(date);
              //}}
              //excludeTimes={this.state.allExcludeTimes}
              //excludeTimes={this.state.excludeTimes}//<--
              excludeDates={this.state.holidays}
            />
            <button className={ this.state.buttonDisplay ? "btn btn-primary" : "btn btn-primary btn-dispaly-no" }>OK</button>
          </div>
        </form>

        <div className="strike">
          <span>OR</span>
        </div>

        <form onSubmit={ this.onFormSubmit_2 }>
          <div className="form-group add-break-time-form">
            <p>休店日 <span className='small-text'>(複数選択可)</span></p>
            <MultiDatePicker
              //locale="ja"
              months={months}
              weekDays={weekDays}

              minDate={this.state.open}
              id='holiday'
              selected={ this.state.holiday }
              onChange={ this.handleChangeHolyday }
              dateFormat="yyyy.MM.dd"
              //onCalendarOpen={handleCalendarOpen}
              //onCalendarClose={handleCalendarClose}
              onSelect={(date) => {
                this.setState({excludeTimes:getExcludeTimesForDate(date)} );
                //console.log(date);
              }}
              //excludeTimes={this.state.allExcludeTimes}
              //excludeDates={this.state.holidays}
              
              format="M/D"
              formattingIgnoreList={["Date"]}
              multiple
              style={{ 
              }}

              mapDays={({ date }) => {
                //console.log(date.month);
                var today_s = new Date(date.year, date.month.index, date.day, 0, 0, 0);
                var today_e = new Date(date.year, date.month.index, date.day, 23, 59, 59);
                //let isWeekend = [0, 6].includes(date.weekDay.index)

                //var holidayObj = this.state.holidays[0];
                //console.log(holidayObj);
                for(var i = 0; i < this.state.holidays.length; i++){
                  var holidayObj = new Date(this.state.holidays[i]);
                  if(today_s<=holidayObj && holidayObj<=today_e) return {
                    disabled: true,
                    style: { color: "#FF5C5C" },
                    onClick: () => alert("お休みに設定済みです.")
                  }
                }

                for(var i = 0; i < this.state.allExcludeTimes.length; i++){
                  var excludeObj = new Date(this.state.allExcludeTimes[i]);
                  if(today_s<=excludeObj && excludeObj<=today_e) return {
                    disabled: true,
                    style: { color: "#eee" },
                    onClick: () => alert("来店予約ありの日です.お休みにできません.")
                  }
                }
                
                //if (isWeekend) return {
                //  disabled: true,
                //  style: { color: "#ccc" },
                //  onClick: () => alert("この日は来店予定ありで、お休みにできません.")
                //}
              }}
            />
          <button className={ this.state.buttonDisplay ? "btn btn-primary" : "btn btn-primary btn-dispaly-no" }>OK</button>

          </div>
        </form>
      </>
    );
  }
  
}
export default Calender2;