import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import features_text from '../assets/features_text.json'
import '../common.css';
import './feature.css';
import {functionFields} from '../MyFunction';
import {Helmet} from 'react-helmet';
import Header from './../parts/Header';
import settings from '../../settings.json';
//import bootstrap from './assets/bootstrap.css';

function Features() {
  const [id, setId] = useState('');
  const [title_text, setTitle_text] = useState('');
  const [subtitle_text, setSubtitle_text] = useState('');
  const [hero_img, setHero_img] = useState('');
  const [text_1, setText_1] = useState([]);
  const [tokutyou, setTokutyou] = useState(true);
  const [textKey, setTextKey ] = useState('');

  const [seoTitle, setSeoTitle] = useState('');
  const [seoDescri, setSeoDescri] = useState('');

  const [devImg, setDevImg] = useState('');

  useEffect(() => {
    var textKey = functionFields.getParameterByName('textKey');
    setTextKey(textKey);
    //console.log(textKey);
    if(Number(textKey)>7){
      setTokutyou(false);
    }

    //var fileObj = JSON.parse(features_text);
    //console.log(features_text[textKey]['title']);

    setId(features_text[textKey]['id']);

    let this_title = features_text[textKey]['title'];
    if(this_title.indexOf('ショールームのご紹介') !== -1){
      this_title = 'ショールームのご紹介';
    }

    setTitle_text(this_title);
    setSubtitle_text(features_text[textKey]['subtitle']);
    setHero_img(features_text[textKey]['header_img']);
    setText_1(features_text[textKey]['text_1']);

    //setSeoTitle(features_text[textKey]['title']+"/"+settings.TITLE);
    setSeoTitle(features_text[textKey]['title']);
    //setSeoDescri("当仏具店の特徴紹介:"+features_text[textKey]['title']+"/"+settings.DESCRIPTION);
    setSeoDescri(features_text[textKey]['description']);

    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {
    
    }else{
      console.log('_style_pc');
      const $style = document.createElement("link");
      $style.rel = "stylesheet";
      $style.type = "text/css";
      $style.href = "/css/pc.css";
      document.head.appendChild($style);
    }

    if(settings.DEV==='/_dev'){
      setDevImg('https://kyoto-butsudan.com/');
    }else{
      setDevImg('');
    }

  }, []);

  
  if(textKey==='5'){
    window.addEventListener("click", (event) => {
      try{
        if(document.getElementById("pressReserve")!=null && event.target.childNodes[0].id==='pressReserve'){
          document.getElementsByClassName('banner-text-2')[0].click();
        }
      }catch(e){

      }

    });
  }





  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta
          name="description"
          content={seoDescri}
        />
      </Helmet>

      <Header />

      <div className="ItemList">
        <p className="pankuzu">
          {
            (() => {
                if (tokutyou)
                  return <><a href="/">トップ</a> ＞ 特徴{id}:{title_text}</>
                else
                  return <><a href="/">トップ</a> ＞ {title_text}</>
            })()
          }

        </p>
        
        <h2 className="page-h2 feature-h2">
          {parse(title_text)}
          {
              (() => {
                  if (subtitle_text.length > 0)
                    return <span className="feature-mini-title">{subtitle_text}</span>
                  else
                    return <></>
              })()
          }
        </h2>

        <img src={devImg+hero_img} alt={'京都の小型仏壇店まるいちの特徴:'+title_text} className="feature-img" />
        <div className="feature-text">
          {text_1.map((text, index)=>{
            return(
              <div key={index}>
                {parse(text)}
              </div>
            )
          })}
        </div>
        
      </div>
    </>
  );
}

export default Features;
