import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import Header from './../parts/Header';
import features_text from '../assets/features_text.json'
import '../common.css';
import './feature.css';
import {functionFields} from '../MyFunction';

import {Helmet} from 'react-helmet';
import settings from '../../settings.json';
//import bootstrap from './assets/bootstrap.css';

function Access() {
  const [seoTitle, setSeoTitle] = useState('');
  const [seoDescri, setSeoDescri] = useState('');
  const [devImg, setDevImg] = useState('');

  useEffect(() => {

    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {
    
    }else{
      //console.log('_style_pc');
      const $style = document.createElement("link");
      $style.rel = "stylesheet";
      $style.type = "text/css";
      $style.href = "/css/pc.css";
      document.head.appendChild($style);
    }

    setSeoTitle("（株）まるいちの仏壇 アクセス（交通手段）/"+settings.TITLE);
    setSeoDescri("（株）まるいちの仏壇 アクセス（交通手段）/"+settings.DESCRIPTION);


    if(settings.DEV==='/_dev'){
      setDevImg('https://kyoto-butsudan.com/');
    }else{
      setDevImg('');
    }
  }, []);



  return (
  <>
    <Helmet>
      <title>{seoTitle}</title>
      <meta
        name="description"
        content={seoDescri}
      />
    </Helmet>

    <Header />

    <div className="ItemList">
      <p className="pankuzu"><a href="/">トップ</a> ＞ アクセス</p>
      
      <h2 className="page-h2 feature-h2">アクセス</h2>
      <img src={devImg+"images/features/access_1.jpg"} alt={'京都の小型仏壇店のまるいち:'+'アクセス'} className="feature-img" />
      <div className="feature-text ">

        <div>
          <ul className="pl-1 access-ul">
            
            <li className="access-li">〒６０２－８３０４<br />京都市上京区千本通上立売下る作庵町５３５－４</li>
            <li>最寄りバス停　千本上立売　徒歩１分</li>
            <li className="access-li">京都市バス停車系統（６・<span>４６</span>・<span>５９</span>・<span>２０６</span>）</li>
            <li>千本上立売交差点を東へ<span>１０</span>メートルほど進んでいただくと右手にございます。</li>
          </ul>
          <ul className="pl-1 ul-mark-kome mt--05">
            <li>お車でお越しのお客様は弊社いほりホール駐車場へお止めいただきご来店ください。<span>　（徒歩１分）</span></li>
            <li>千本通東側より建物奥へお進みいただきますと駐車場がございます（約２０台）</li>
          </ul>

          <img src="images/features/access_2.jpg" alt={'京都の小型仏壇店のまるいち:'+'アクセス'} className="feature-img" />
          <img src="images/features/access_3.jpg" alt={'京都の小型仏壇店のまるいち:'+'アクセス'} className="feature-img mt--05" />
          <img src="images/features/access_4.jpg" alt={'京都の小型仏壇店のまるいち:'+'アクセス'} className="feature-img mt--05" />

        </div>

      </div>
      
    </div>
  </>
  );
}

export default Access;
