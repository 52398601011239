import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Home from './components/Home';

import Home_DEV from './components/Home_DEV';

import ItemList from "./components/ItemList";
import Item from './components/Item';
import FeaturesPage from './components/child_page/Features';
import HamburgerMenu from "./components/parts/HamburgerMenu";

import Reserve from "./components/parts/Reserve";

import ButsudanOwakare from "./components/child_page/ButsudanOwakare";
import QA from "./components/child_page/QA";
import AboutReserve from "./components/child_page/AboutReserve";
import Access from "./components/child_page/Access";
import Privacy from "./components/child_page/Privacy";
import About from "./components/child_page/About";

import Admin from './admin/Top';
import SchedulePage from "./admin/SchedulePage";
import SEOChartPage from "./admin/SEOChartPage";
import ListItemAdmin from "./admin/ListItemAdmin";

import CRUDBase from "./admin/CRUD/CRUDBase";
import GoogleAuth from "./admin/GoogleAuth";

import logo from './logo.svg';
import './App.css';

import {functionFields} from './components/MyFunction';

function App() {
  const [title, setTitle] = useState('まるいちのお仏壇');
  const [description, setDescription] = useState('上置きタイプ専門のお仏壇店　まるいちのお仏壇');
  const thisUrl = window.location.pathname;
  
  useEffect(() => {
    var titleText = functionFields.getTitle();
    setTitle(titleText[0]);
    setDescription(titleText[1]);

    //var thisUrl = location.pathname;
    
    //var thisUrl = location.pathname;
    var footerShow = functionFields.checkFooterShow(thisUrl);
    if(!footerShow){
      document.getElementById('footer').style.display='none';
    }
  }, []);


  //let DEV = '/_dev';
  let DEV = '';

  return (
    <Router>
      <div className="App">
        
        <Switch>
          <Route exact path={DEV+"/"} component={Home} />
          <Route exact path={DEV+"/develop"} component={Home_DEV} />
          <Route path={DEV+"/item-list"} component={ItemList} />
          <Route path={DEV+"/item"} component={Item} />
          <Route path={DEV+"/features"} component={FeaturesPage} />
          <Route path={DEV+"/reserve"} component={Reserve} />

          <Route path={DEV+"/obutsudan-owakare"} component={ButsudanOwakare} />
          <Route path={DEV+"/q-a"} component={QA} />
          <Route path={DEV+"/about-reserve"} component={AboutReserve} />
          <Route path={DEV+"/access"} component={Access} />
          <Route path={DEV+"/privacy"} component={Privacy} />
          <Route path={DEV+"/about"} component={About} />

          <Route path={DEV+"/admin"} component={Admin} />
          <Route path={DEV+"/schedule"} component={SchedulePage} />
          <Route path={DEV+"/seo"} component={SEOChartPage} />

          <Route path={DEV+"/list-item"} component={ListItemAdmin} />

          <Route path={DEV+"/crud"} component={CRUDBase} />
          <Route path={DEV+"/google_auth"} component={GoogleAuth} />
        </Switch>
        
      </div>
    </Router>
  );
}

export default App;
