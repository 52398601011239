import React, { Component } from "react";
import PDFexport from "./PDFexport";
import {functionFields} from './../MyFunction';
import './pdf.css';
import logo from './../assets/img/logo.png';

export default class PDF extends Component{
  state={
  }

  

  componentDidMount () {
      
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
  }


  render(){
    //console.dir(this.props.data.gusokus);

    var goukei = 100000 + Number(this.props.data.gusokus[2]) + Number(this.props.data.honzon[2]) + Number(this.props.data.takatuki[2]) + Number(this.props.data.orin[2]) + Number(this.props.data.service[2] );
    var syoukei = Math.ceil(goukei/1.1);
    var tax = Math.ceil(goukei-syoukei);
    

    return(
      <div id="pdf">
        <h2>まるいちのお仏壇　セット内容</h2>
        <p>2021年11月13日</p>
        <div>
          <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>品目</th>
              <th>価格</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>上置き仏壇　雅</td>
              <td>100,000 円</td>
            </tr>
            <tr>
              <td>2</td>
              <td>
                {this.props.data.gusokus[1]}
                {this.props.data.gusokus[2]==='0'&&(
                  <>（基本プラン）</>
                )}
              </td>
              <td>
                {Number(this.props.data.gusokus[2]).toLocaleString()} 円
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>
                {this.props.data.honzon[1]}
                {this.props.data.honzon[2]==='0'&&(
                  <>（基本プラン）</>
                )}
              </td>
              <td>
                {Number(this.props.data.honzon[2]).toLocaleString()} 円
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>
                {this.props.data.takatuki[1]}
                {this.props.data.takatuki[2]==='0'&&(
                  <>（基本プラン）</>
                )}
              </td>
              <td>
                {Number(this.props.data.takatuki[2]).toLocaleString()} 円
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>
                {this.props.data.orin[1]}
                {this.props.data.orin[2]==='0'&&(
                  <>（基本プラン）</>
                )}
              </td>
              <td>
                {Number(this.props.data.orin[2]).toLocaleString()} 円
              </td>
            </tr>
            <tr>
              <td >6</td>
              <td >
                {this.props.data.service[1]}
              </td>
              <td >
                {Number(this.props.data.service[2]).toLocaleString()} 円
              </td>
            </tr>
            <tr>
              <td colSpan="2">小計</td>
              <td>{syoukei.toLocaleString()} 円</td>
            </tr>
            <tr>
              <td colSpan="2">消費税（10%）</td>
              <td>{tax.toLocaleString()} 円</td>
            </tr>
            <tr>
              <td colSpan="2">合計</td>
              <td>{goukei.toLocaleString()} 円</td>
            </tr>
          </tbody>
          </table>
        </div>
        <div className="pdf-footer">
          <div>
            <img src={logo}/>まるいち<span className="no">の</span>お仏壇
          </div>
          <div>京都市上京区千本通五辻通上る牡丹鉾町543</div>
          <div>☎：（075）441-〇〇〇〇</div>
          <div>営業時間　平日 午前10時<span className="no"> から </span>午後4時</div>
        </div>
      </div>
    );
  };
}
