import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import Parser from 'html-react-parser';
import features_text from '../assets/features_text.json'
import '../common.css';
import './feature.css';
import {functionFields} from '../MyFunction';
import axios from 'axios';
import {Helmet} from 'react-helmet';
import Header from './../parts/Header';
import settings from '../../settings.json';
import this_hero_img from './../assets/img/about.jpg';

//import bootstrap from './assets/bootstrap.css';

function About() {
  const [mapHeight, setMapHeight] = useState('300');
  const [mobileJudge, setMobileJudge] = useState(false);

  const [rating_1, setRating_1] = useState('');
  const [rating_2, setRating_2] = useState('');
  const [rating_3, setRating_3] = useState('');
  const [viewCount_1, setViewCount_1] = useState('');
  const [viewCount_2, setViewCount_2] = useState('');
  const [viewCount_3, setViewCount_3] = useState('');

  const [seoTitle, setSeoTitle] = useState('');
  const [seoDescri, setSeoDescri] = useState('');

  useEffect(() => {
    var map_1_url = 'https://kyoto-butsudan.com/API/cron/map_1.json';
    var map_2_url = 'https://kyoto-butsudan.com/API/cron/map_2.json';
    var map_3_url = 'https://kyoto-butsudan.com/API/cron/map_3.json';
    
    axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    
    axios.get(map_1_url).then((response) => {
      //console.dir(response.data.result);
      setRating_1(response.data.result.rating);
      setViewCount_1(response.data.result.user_ratings_total);
    });

    axios.get(map_2_url).then((response) => {
      //console.dir(response.data.result);
      setRating_2(response.data.result.rating);
      setViewCount_2(response.data.result.user_ratings_total);
    });

    axios.get(map_3_url).then((response) => {
      console.dir(response.data.result);
      setRating_3(response.data.result.rating);
      setViewCount_3(response.data.result.user_ratings_total);
    });

    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {
      setMapHeight('260');
      setMobileJudge(true);
    }else{
      //console.log('_style_pc');
      const $style = document.createElement("link");
      $style.rel = "stylesheet";
      $style.type = "text/css";
      $style.href = "/css/pc.css";
      document.head.appendChild($style);

      setMapHeight('400');
    }

    setSeoTitle("会社概要/"+settings.TITLE);
    //setSeoDescri("会社概要ページ/"+settings.DESCRIPTION);
    setSeoDescri('会社概要｜京都の「現代風小型（上置）仏壇」を専門に販売している【株式会社 まるいち】洋風でおしゃれな小さい仏壇に標準仏具を付属し、お求めやすいセット価格にて販売。完全予約制・同一時間1組限定。');

  }, []);
  


  var showroomMobileText = '<div className="line-height-1">仏壇<br>ショールーム</div>';
  var showroomPcText = '<div>仏壇ショールーム</div>';
  
  return (
  <>
    <Helmet>
      {/*<title>{seoTitle}</title>*/}
      <title>会社概要｜上置仏壇専門店　京都【株式会社 まるいち】</title>
      <meta
        name="description"
        content={seoDescri}
      />
    </Helmet>

    <Header />

    <div className="ItemList">
      <p className="pankuzu"><a href="/">トップ</a> ＞ 会社概要</p>
      
      <h2 className="page-h2 feature-h2">会社概要</h2>
      <img src={this_hero_img} alt={'京都の小型仏壇店まるいちの特徴:'+'会社概要'} className="feature-img" />
      <div className="feature-text feature-text-x2 mt-06">
        <div>
          {/*<h2 className="feature-h2 " id="q1">会社概要</h2>*/}
          <div className="table-grid">
            <div>名称</div>
            <div>株式会社　まるいち</div>
          </div>
          <div className="table-grid">
            <div>代表者</div>
            <div>代表取締役社長　小林　正明</div>
          </div>
          <div className="table-grid">
            <div>本社</div>
            <div>
              <p className="p-mb-minus-03">〒602-8304</p>
              <p className=" line-heoght-15">京都市上京区千本通上立売上る<span>作庵町</span><span>518</span></p>
              <p className="p-mb-minus-03">📞　（075）441-6254</p>
              <p className="p-mb-minus-03">📞　（075）431-7023</p>
              <p>📠　（075）414-2990</p>
            </div>
          </div>
          <div className="table-grid">
            <div className="line-height-1">いほり<span>ホール</span></div>
            <div>
              <p className="p-mb-minus-03">〒602-8475</p>
              <p className=" line-heoght-15">京都市上京区五辻通上る<span>牡丹鉾町</span><span>543</span></p>
            </div>
          </div>
          <div className="table-grid">
            {mobileJudge ? Parser(showroomMobileText) : Parser(showroomPcText) } 
            <div>
              <p className="p-mb-minus-03">〒602-8304</p>
              <p className=" line-heoght-15">京都市上京区五辻通下る<span>作庵町</span><span>543番4</span></p>
            </div>
          </div>
          <div className="table-grid">
            <div>創業</div>
            <div>1957年</div>
          </div>
          <div className="table-grid">
            <div>資本金</div>
            <div>1,000万円</div>
          </div>
          <div className="table-grid">
            <div>営業種目</div>
            <div>
              <p className="">葬儀式典の請負・一般貨物（霊柩）</p>
              <p>自動車運送事業</p>
              <p>仏壇・仏具の販売</p>
            </div>
          </div>
          <div className="table-grid">
            <div>所属団体</div>
            <div>
              <ul>
                <li className="line-height-1">全日本葬祭業協同組合連合会 <span>京都</span><span>中央</span><span>葬祭業</span><span>協同</span><span>組合</span><span>一般</span><span>社団</span><span>法人</span></li>
                <li>全国霊柩自動車協会</li>
              </ul>
            </div>
          </div>

          {/* ICON LIST  https://fonts.google.com/icons?icon.query=building */}
          {/* MAPにレビュー、スマホの幅ではレビュー消えるのでAPI処理、JSONデータはCROSエラー等でアクセスしにくいのでサーバー側でCRON処理 kyoto-butsudan.com/API/cron/get_map_review.php */}
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
          <div className="map-box"  id="map-1">
            <iframe src="https://kyoto-butsudan.com/map/map-1.html" width="100%" height={mapHeight} frameBorder="0" allowFullScreen=""></iframe>
            <div className="map-text-area">
              <div>株式会社まるいち</div>
              <div>
                <span className="map-rating-number">{rating_1}</span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="map-review-link"><a target="_blank" href="https://search.google.com/local/reviews?placeid=ChIJDcvknO8HAWARDkoSVG10wuE&q=%EF%BC%88%E6%A0%AA%EF%BC%89%E3%81%BE%E3%82%8B%E3%81%84%E3%81%A1+%E3%80%90%E3%81%8A%E8%91%AC%E5%BC%8F%E3%80%91&hl=ja&gl=JP">{viewCount_1}件のクチコミ</a></span>
              </div>
              <div>
                <a target="_blank" href="https://www.google.com/maps?ll=35.032593,135.741878&z=18&t=m&hl=ja&gl=JP&mapclient=embed&cid=16267692816926394894">拡大地図を表示</a>
              </div>
            </div>
          </div>

          <div className=''>
            <p className='mb-02'>葬儀会館　いほりホール</p>
            <div className='feature-img-grid mt-03'>
              <div id='feature-img-3-5-bg'></div>
              <div id='feature-img-3-6-bg'></div>
            </div>
          </div>




          {/* MAPにレビュー、スマホの幅ではレビュー消えるのでAPI処理、JSONデータはCROSエラー等でアクセスしにくいのでサーバー側でCRON処理 kyoto-butsudan.com/API/cron/get_map_review.php */}
          <div className="map-box"  id="map-2">
            <iframe src="https://kyoto-butsudan.com/map/map-2.html" width="100%" height={mapHeight} frameBorder="0" allowFullScreen=""></iframe>
            <div className="map-text-area">
              <div>いほりホール （株）まるいち</div>
              <div>
                <span className="map-rating-number">{rating_2}</span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="map-review-link"><a target="_blank" href="https://search.google.com/local/reviews?placeid=ChIJVVX5ee8HAWARQUbZyJRY4ik&q=%E3%81%84%E3%81%BB%E3%82%8A%E3%83%9B%E3%83%BC%E3%83%AB+%E3%80%90%E3%81%8A%E8%91%AC%E5%BC%8F%E3%80%91&hl=ja&gl=JP">{viewCount_2}件のクチコミ</a></span>
              </div>
              <div>
                <a target="_blank" href="https://maps.google.com/maps?ll=35.032244,135.742125&z=18&t=m&hl=ja&gl=JP&mapclient=embed&cid=3018072096339740225">拡大地図を表示</a>
              </div>
            </div>
          </div>
          



          <div className=''>
            <p className='mb-02'>仏壇ショールーム</p>
            <div className='feature-img-grid mt-03'>
              <div id='feature-img-4-1-bg'></div>
              <div id='feature-img-4-2-bg'></div>
            </div>
          </div>

          <div className="map-box"  id="map-3">
            <iframe src="https://kyoto-butsudan.com/map/map-3.html" width="100%" height={mapHeight} frameBorder="0" allowFullScreen=""></iframe>
            <div className="map-text-area">
              <div>まるいちのお仏壇</div>
              <div>
                <span className="map-rating-number">{rating_3}</span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="map-review-link"><a target="_blank" href="https://search.google.com/local/reviews?placeid=ChIJWRO-hXoHAWARFj8NLxNOEOY&hl=ja&gl=JP">{viewCount_3}件のクチコミ</a></span>
              </div>
              <div>
                <a target="_blank" href="https://www.google.com/maps/place/%E3%81%BE%E3%82%8B%E3%81%84%E3%81%A1%E3%81%AE%E3%81%8A%E4%BB%8F%E5%A3%87+%E4%BA%AC%E9%83%BD/@35.0325409,135.7410018,18z/data=!3m1!4b1!4m6!3m5!1s0x6001077a85be1359:0xe6104e132f0d3f16!8m2!3d35.0325409!4d135.7420811!16s%2Fg%2F11qbdv9mw8?hl=ja">拡大地図を表示</a>
              </div>
            </div>
          </div>




        </div>

      </div>
      
    </div>
  </>
  );
}

export default About;
