import React, { Component, useState, Fragment } from "react";
import Calender from "./Calender";
import Modal from 'react-modal';
import {functionFields} from './../MyFunction';
import axios from "axios";

import settings from '../../settings.json';
import closeImg from './../assets/img/close.png';
import './parts.css';

  export default class Reserve extends Component{
    constructor(){
      super();

      var todayDate = new Date();
      var hour24 = todayDate.getHours();
      var hour12 = hour24 % 12;
      if(hour24<12){
        var ampm = '午前';
      }else{
        var ampm = '午後';
      }
      var todayDateString = todayDate.getFullYear() + '年' + (todayDate.getMonth()+1) + '月' + todayDate.getDate() + '日 ' + ampm + hour12 + '時';

      this.state={
        data:this.props,
        name_1:'',
        name_2:'',
        email:'',
        address:'',
        phone:'',
        Date:todayDateString,
        showModal: false,
        pageType:'customer',
        edit:false,
        formTitle:'Web来店予約',
        submitText:'予約する',
        userId:'',
        reserveData:true,
        accessType:'car',
      }

      this.handleOpenModal = this.handleOpenModal.bind(this);
      this.handleCloseModal = this.handleCloseModal.bind(this);

      
    }

    componentDidMount () {
      //モバイル
      if (navigator.userAgent.match(/iPhone|Android/)) {
      
      }else{
        //console.log('_style_pc');
        const $style = document.createElement("link");
        $style.rel = "stylesheet";
        $style.type = "text/css";
        $style.href = "/css/pc.css";
        document.head.appendChild($style);
      }


      const thisUrl = window.location.pathname;
      var isCustomerView = functionFields.checkFooterShow(thisUrl);
      if(!isCustomerView){
        this.setState({pageType:'admin'});
        this.setState({formTitle:'新規予約追加'});
        this.setState({submitText:'追加する'});
      }

      var userId = functionFields.getParameterByName('id');
      if(userId){
        this.setState({userId:userId})
        //console.log('user_id:'+userId);
        this.setState({edit:true});
        this.setState({pageType:'customer-edit'});
        this.setState({formTitle:'予約変更'});
        this.setState({submitText:'変更する'});
        //
        const getDBData = async (userId) => {
          var url = settings.GET_NOT_RESERVE_TIME_API_URL;
          const res = await fetch(url+'?type=get_reserve_data&id='+userId);
          const data = await res.json();
          //console.dir(data);
          try{
            if(data.result==='ok'){
              this.setState({name_1:data.name_1});
              this.setState({name_2:data.name_2});
              this.setState({address:data.address});
              this.setState({phone:data.phone});
              this.setState({email:data.email});
              this.setState({accessType:data.access_type});
              //this.setState({Date:data.reserve_date});
              var dayText = data.reserve_date;
              var thisDay = dayText.slice(0, -3);
              document.getElementById('calender').value = thisDay;
              this.setState({Date:data.reserve_date});
            }
          }catch(e){
            //console.log('予約なし');
            this.setState({reserveData:false});
          }
    
        };
        getDBData(userId);
      }
      
    }

    onChanegeDay(newDay){
      //console.log(newDay);
      this.setState({Date:newDay});

    }


    handleOpenModal () {
      this.setState({ showModal: true });
    }
    handleCloseModal () {
      this.setState({ showModal: false });
    }
    

    render(){ 
      //console.log(this.state.reserveData);
      if(this.state.reserveData===false){
        alert('該当するご予約情報なし.\nトップに移動します.');
        //window.location.href = "/";
      }
      //console.log(this.state.edit);

      var changeStatus = () =>{
        this.props.changeStatus(false);
      }

      var handleChange = (event) => {
        var key = event.target.name;
        //console.log(key);
        //console.log(event.target.value);
        //console.dir(this.state);
        this.setState({[key]: event.target.value});
      }

      //var handleSubmit = async (event) => {
      var handleSubmit = (event) => {
        //console.log(this.state.name_1);
        //console.log(this.state.phone);
        //console.log(this.state.email);
        //console.log(this.state.accessType);
        //console.log(this.state.Date);//string
        var dateObj = functionFields.convertdate('str-obj-full', this.state.Date)[0];
        //console.log(dateObj)
        var whatTimeOkHour = settings.StoreHowManyHoursOK;//String 2

        var validation = false;
        var now = new Date();
        if( (dateObj < now.setHours(now.getHours() + Number(whatTimeOkHour))) || isNaN(dateObj) ){
          this.props.toastStatus('over_time');
          var validation = 'error';
        }

        if(this.state.pageType!=='customer-edit'){
          var tel = this.state.phone.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi,'');
          var tel = functionFields.hankaku2Zenkaku(tel);
          //console.log(tel);
          if(!tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)){
            this.props.toastStatus('phone_error');
            var validation = 'error';
          }else{
            this.state.phone = tel;
          }
  
          if(!this.state.name_1){//バリデーション
            this.props.toastStatus('no_name');
            var validation = 'error';
          }
        }


        var emailArray = JSON.stringify(settings.RESERVE_NOTIFICATION_EMAIL);
        if(this.state.pageType!=='customer-edit'){
          var dataObj = {'type':'reserve'};
        }else{
          var dataObj = {'type':'reserve-edit', 'id':this.state.userId};
        }
        
        dataObj['data'] = this.state;
        dataObj['emailArray'] = emailArray;
        //console.dir(dataObj);
        //console.log('validation:'+validation)
        //メール
        //カレンダー
        //functionFields.pushMailMaruichi('reserve', this.state);
        try {
          //var res = await axios.post(settings.PUSH_EMAIL_API_URL, dataObj);
          if(validation!=='error'){
            var res = axios.post(settings.PUSH_EMAIL_API_URL, dataObj);//Promise
            res.then((value)=>{
              //console.log(value.data.save_response);
              if(value.data.save_response==='予約しました'){
                this.props.toastStatus('reserve_ok');
                setTimeout(function(){
                  window.scrollTo(0, 0);
                  window.location.reload(true);
                }, 3000);//wait 2 seconds
              }else if(value.data.save_response==='予約日時を変更しました'){
                //this.props.toastStatus('change_ok');
                alert('予約日時を変更しました');
                setTimeout(function(){
                  window.scrollTo(0, 0);
                  window.location.href = "/";
                }, 3000);//wait 2 seconds
              }else{
                this.props.toastStatus('reserve_error');
              }
              //this.props.changeStatus(false);
              //this.props.toastStatus(false);
            })
          }else{
            this.props.toastStatus('input_error');
          }
        } catch {

        }



        event.preventDefault();
        
      }

      //console.log(this.state.edit);
      //console.log(this.state.pageType);
      //console.log(this.state.accessType);

      return (
        <>
          <div className={this.state.edit ? "display-none" : "close-button" }>
            <button onClick={changeStatus}>
              <img src={closeImg}/>
            </button>
          </div>
          <div className="Reserve">
            <h2 style={{['textAlign']:'center'}}>
              {this.state.formTitle}
              {/*{this.state.pageType==='customer' ? "Web来店予約" : '新規予約追加' }*/}
            </h2>
            <p>お仏壇<span className="text-small">の</span>まるいち{this.state.edit ? "予約変更フォーム" : "来店予約フォーム"}</p>
            <form onSubmit={handleSubmit}>
              <div className="form-input-row">
                <div>
                  <label htmlFor="name_1">
                    お名前
                  </label>
                </div>
                <div>
                  <input 
                    type="text" 
                    name="name_1"
                    id="name_1"
                    placeholder="例　大島　幾二"
                    value={this.state.name_1} 
                    onChange={handleChange} 
                    disabled={this.state.pageType==='customer-edit' ? 'disabled' : ''}
                    className={this.state.pageType==='customer-edit' ? 'input-border-initial' : ''}
                  />
                </div>
              </div>
              {/*
              <div className="form-input-row">
                <div>
                  <label htmlFor="name_2">
                    名前
                  </label>
                </div>
                <div>
                  <input 
                    type="text" 
                    name="name_2"
                    id="name_2"
                    value={this.state.name_2} 
                    onChange={handleChange} 
                    disabled={this.state.pageType==='customer-edit' ? 'disabled' : ''}
                  />
                </div>
              </div>
              */}

              <div className="form-input-row">
                <div>
                  <label htmlFor="address">
                    住所
                  </label>
                </div>
                <div>
                  <input 
                    type="text" 
                    name="address"
                    id="address"
                    value={this.state.address} 
                    onChange={handleChange} 
                    disabled={this.state.pageType==='customer-edit' ? 'disabled' : ''}
                    className={this.state.pageType==='customer-edit' ? 'input-border-initial' : ''}
                  />
                </div>
              </div>

              <div className="form-input-row">
                <div>
                  <label htmlFor="phone">
                    電話番号
                  </label>
                </div>
                <div>
                  <input 
                    type={this.state.pageType!=='customer-edit' ? 'number' : 'text'} 
                    name="phone"
                    id="phone"
                    placeholder="- (ハイフン)なし　半角数字"
                    value={this.state.phone} 
                    onChange={handleChange} 
                    disabled={this.state.pageType==='customer-edit' ? 'disabled' : ''}
                    className={this.state.pageType==='customer-edit' ? 'input-border-initial height-19' : 'height-19'}
                  />
                </div>
              </div>
            
              <div className="form-input-row">
                <div>
                  <label htmlFor="email">
                    メール
                  </label>
                </div>
                <div>
                  <input 
                    type="text" 
                    name="email"
                    id="email"
                    value={this.state.email} 
                    onChange={handleChange} 
                    disabled={this.state.pageType==='customer-edit' ? 'disabled' : ''}
                    className={this.state.pageType==='customer-edit' ? 'input-border-initial' : ''}
                  />
                </div>
              </div>
              <div className="form-input-row ">
                <div>
                  <label htmlFor="email" className="calsender-label">
                    日時<p className="close-on-calender" id="calender-close-outside">閉</p>
                  </label>
                </div>
                <Calender 
                  changeDayFunction={this.onChanegeDay.bind(this)} 
                />
              </div>

              <div className="form-input-row mb-05">
                <div className="w-105">
                  <label htmlFor="email">
                    ご来店方法
                  </label>
                </div>
                <div className="input-radio-box">
                  <div className="input-radio mr-08">
                    <label htmlFor="radio-car">お車</label>
                    <input 
                      type="radio" 
                      name="accessType"
                      value="car"
                      onChange={handleChange} 
                      checked={this.state.accessType==='car'}
                      className="form-check-input"
                      id="radio-car"
                    />
                  </div>
                  <div className="input-radio mr-08">
                    <label htmlFor="radio-bus">バス</label>
                    <input 
                      type="radio" 
                      name="accessType"
                      value="bus"
                      onChange={handleChange} 
                      checked={this.state.accessType==='bus'}
                      className="form-check-input"
                      id="radio-bus"
                    />
                  </div>
                  <div className="input-radio">
                    <label htmlFor="radio-other">その他</label>
                    <input 
                      type="radio" 
                      name="accessType"
                      value="other"
                      onChange={handleChange} 
                      checked={this.state.accessType==='other'}
                      className="form-check-input"
                      id="radio-other"
                    />
                  </div>

                </div>
              </div>

              <div className="text-small notification-text-box">
                {this.state.pageType==='customer' ? "上記個人情報は、法令遵守に従い、適切な管理を行います。当店及び株式会社まるいちのサービス提供のために利用します。" : '' }
              </div>

              <div className="reserve-submit-button">
                <input type="submit" value={this.state.submitText} />
              </div>
            </form>


          </div>


          {/*予約結果でモーダル切り替える*/}
          <Modal 
             isOpen={this.state.showModal}
             contentLabel="Minimal Modal Example"
          >
            <button onClick={this.handleCloseModal}>Close Modal</button>
          </Modal>
          <button onClick={this.handleOpenModal} className="display-none"></button>{/*hidden*/}
        </>
      );
    }
}
