//import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";

import './assets/admin.css';



function TopHeader() {

  //let url = 'https://keiei-support-plus-a.com/kaisha-setsuritsu/teikan-jigyou-mokuteki/api/post.php';
  //let limit = 10;

  useEffect(() => {
    //console.log('HELLO');
    var url = window.location.href;
    if(url.indexOf('admin') != -1){
      //document.getElementsByClassName('header')[0].style.display = 'none';
      
    }
    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {

    }

  }, []);





  return (
    <>
      <div id="overlay" ></div>
      <div className="admin-header">
        <div>
          <a href="/admin">管理TOP</a>
        </div>
        <div>
          <a href="/schedule">営業時間・予約表</a>
        </div>
        <div>
          <a href="/seo">Viewチャート</a>
        </div>
      </div>
    </>
  );
}

export default TopHeader;
