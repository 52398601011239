import React, { Component, useState, Fragment } from "react";
import Modal from 'react-modal';
import Reserve from "./Reserve";
import {functionFields} from './../MyFunction';
import './parts.css';

  export default class ItemOption extends Component{
    constructor(props){
      super(props);
      this.state={
        //gusokus:this.props.data.gusokus,
        dataArray:[],
        otherDataArray:[],
        optionId:'1',
        optionTitle:'',
        optionPrice:'',
        optionImg:'',
        optionText:'',
        optionType:'',//gusoku, honzon...
        optionTypeName:'',

        modalIsOpen:false,
        modalIsOpenSecond:false,

        optionSelectData:{},

      }
    }

    componentDidMount () {
      //DB初期値
      var dataArray = functionFields.getButsugu(this.props.type);
      if(this.props.type==='service'){
        var otherDataArray = functionFields.getButsugu('other');
        this.setState({otherDataArray:otherDataArray});
      }
      this.setState({dataArray:dataArray});

      //console.dir(dataArray);


      //初期値
      for (const element of dataArray) {
        //console.dir(element);
        if(element.price==='0'){
          this.setState({optionTitle:element.name});
          this.setState({optionPrice:element.price});
          this.setState({optionImg:element.img});
          this.setState({optionText:element.text});
        }
        if(element.id==='1'){
          var returnData = [element.id, element.name, element.price, element.img, element.text];
        }

      }
      this.setState({optionType:this.props.type});
      var optionTypeName = functionFields.getOptionTypeName(this.props.type);
      this.setState({getOptionTypeName:optionTypeName});

      
      this.props.changeOption(this.props.type, returnData);// PDF用に返す必要あり, ValueはArrayに

      //モーダルの高さ .modal-item-option>div>div //枠線範囲
      var howLong = dataArray.length*150 + 250;
      const $style = document.createElement("style");
      document.head.appendChild($style);
      $style.innerHTML = '.modal-item-option>div>div{height:'+howLong+'px;/*モーダル線高さ*/}';
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      //何のイベントにも反応するよ


    }



    render(){ 
      var changeSelect = (event) => {
        var selectType = event.target.name;
        var selectValue = event.target.value;
        //console.log(selectType);
        //console.log(selectValue);//String
        //console.dir(this.state.dataArray[0]);
        //console.dir( this.state.dataArray[Number(selectValue-1)] );
        const targetDataObj = this.state.dataArray[Number(selectValue-1)];

        this.setState({optionId:targetDataObj.id});
        this.setState({optionTitle:targetDataObj.name});
        this.setState({optionPrice:targetDataObj.price});
        this.setState({optionImg:targetDataObj.img});
        this.setState({optionText:targetDataObj.text});

        var returnData = [targetDataObj.id, targetDataObj.name, targetDataObj.price, targetDataObj.img, targetDataObj.text];
        this.props.changeOption(selectType, returnData);// PDF用に返す必要あり, ValueはArrayに
      }

      var changeModalStatus = (event) =>{
        console.dir(event);
      }


      var changeCheckBox = (event) => {
        console.dir(event.target);
        var selectType = event.target.name;
        var selectValue = event.target.value;
        console.log(selectType);
        console.log(selectValue);
        console.log(event.target.defaultChecked);
        this.props.changeOtherOption(selectValue);// PDF用に返す必要あり, ValueはArrayに
      }


      var showOptionDetail = (event) =>{
        //console.dir(event.target);
        this.setState({modalIsOpenSecond:true});
        //console.dir(event.target.parentElement.parentElement.parentElement.parentElement.parentElement.children[0].children[0].id);
        var selectId = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.children[0].children[0].id;
        if(selectId.indexOf('other') === -1){
          var selectData = this.state.dataArray[Number(selectId)-1];
        }else{//otherの場合
          var selectId = selectId.replace('other_', '');
          var selectData = this.state.otherDataArray[Number(selectId)-1];
        }
        
        //console.dir(selectData);
        this.setState({optionSelectData:selectData});
      }



      //console.dir(this.props.data.other);
      //console.dir(this.props.data.other.indexOf("1"));
      return (
        <div className="ItemOption">
            <div className="option-grid-box">
              <div>
                <img src={this.state.optionImg} />
              </div>
              <div>
                <div>
                  {this.state.optionTitle}
                </div>
                <div>
                  {this.state.optionPrice==='0'&&(
                    <>
                      基本プラン
                    </>
                  )}
                  {Number(this.state.optionPrice)>0&&(
                    <>
                      + {Number(this.state.optionPrice).toLocaleString()} 円<span className="zeikomi">（税込）</span>
                    </>
                  )}
                  {Number(this.state.optionPrice)<0&&(
                    <>
                      {Number(this.state.optionPrice).toLocaleString()} 円<span className="zeikomi">（税込）</span>
                    </>
                  )}
                </div>
              </div>
              <div>
                <button
                  onClick={(e)=> this.setState({modalIsOpen:true})}
                >
                  変更
                </button>
              </div>
            </div>



          {/*モーダルの中身*/}
          <Modal 
            isOpen={this.state.modalIsOpen}
            ariaHideApp={false}
            portalClassName="modal-item-option"
          >
            <h2>{this.state.getOptionTypeName}</h2>
            {this.state.dataArray.map(butsugu=>{
              //console.dir(butsugu.name);
              //if( butsugu.name.indexOf('処分') !== -1){
              //  //pass お仏壇の処分はチェックボックスに
              //}else{
              return(
                <div key={butsugu.id} >
                  <p scope="row" className="text-center align-middle">{butsugu.name}</p>
                  <div key={butsugu.id} className="option-modal">{/*id1に初期チェック*/}
                    <div>
                      <input 
                        type="radio"
                        value={butsugu.id}
                        id={butsugu.id}
                        name={this.state.optionType}
                        checked={butsugu.id===this.state.optionId} 
                        onChange={(e)=>changeSelect(e)}
                      />
                    </div>
                    <div>
                      <div>
                        <label htmlFor={butsugu.id}>
                          <img src={butsugu.img} />
                        </label>
                      </div>
                      <div> 
                        <label htmlFor={butsugu.id}>

                          <p>
                            {Number(butsugu.price)===0&&(
                              <>基本プラン</>
                            )}
                            {Number(butsugu.price)>0&&(
                              <>+ {Number(butsugu.price).toLocaleString()}円<span className="zeikomi">（税込）</span></>
                            )}
                            {Number(butsugu.price)<0&&(
                              <>{Number(butsugu.price).toLocaleString()}円<span className="zeikomi">（税込）</span></>
                            )}
                          </p>
                          <p>
                            {butsugu.text.length>32
                              ? <>{butsugu.text.substring(0,32)+"..."}</>
                              : <>{butsugu.text}</>
                            
                            }　
                            <button 
                              className="go-option-item-link-button"
                              onClick={(e) => showOptionDetail(e)}
                            >
                              詳細
                            </button>
                          </p>

                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

            {this.state.optionType==='service'&&(
              <>
                {this.state.otherDataArray.map(otherService=>{
                  //console.dir(otherService.name);
                  //if( otherService.name.indexOf('処分') !== -1){
                  //  //pass お仏壇の処分はチェックボックスに
                  //}else{
                  return(
                    <div key={otherService.id} >
                      <p scope="row" className="text-center align-middle">{otherService.name}</p>
                      <div key={otherService.id} className="option-modal">{/*id1に初期チェック*/}
                        <div>
                          <input 
                            type="checkbox"
                            value={otherService.id}
                            id={'other_'+otherService.id}
                            name={'other_'+otherService.id}
                            onChange={(e)=>changeCheckBox(e)}
                            checked={this.props.data.other.indexOf(otherService.id)===0} 
                          />
                        </div>
                        <div>
                          <div>
                            <label htmlFor={otherService.id}>
                              <img src={otherService.img} />
                            </label>
                          </div>
                          <div> 
                            <label htmlFor={otherService.id}>
                  
                              <p>
                                {Number(otherService.price)===0&&(
                                  <>基本プラン</>
                                )}
                                {Number(otherService.price)>0&&(
                                  <>+ {Number(otherService.price).toLocaleString()}円<span className="zeikomi">（税込）</span></>
                                )}
                                {Number(otherService.price)<0&&(
                                  <>{Number(otherService.price).toLocaleString()}円<span className="zeikomi">（税込）</span></>
                                )}
                              </p>
                              <p>
                                {otherService.text.length>32
                                  ? <>{otherService.text.substring(0,32)+"..."}</>
                                  : <>{otherService.text}</>
                                
                                }　
                                <button 
                                  className="go-option-item-link-button"
                                  onClick={(e) => showOptionDetail(e)}
                                >
                                  詳細
                                </button>
                              </p>
                              
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            )}

            <div className="modal-close">
              <button 
                onClick={(e)=> this.setState({modalIsOpen:false})}
              >閉じる</button>
            </div>
          </Modal>


          <Modal
            isOpen={this.state.modalIsOpenSecond}
            ariaHideApp={false}
            portalClassName="modal-item-option-second"
          >
            <h2>{this.state.optionSelectData.name}</h2>
            <div>
              <div><img src={this.state.optionSelectData.img} /></div>
              <div>
                {Number(this.state.optionSelectData.price)===0&&(
                  <>基本プラン</>
                )}
                {Number(this.state.optionSelectData.price)>0&&(
                  <>+ {Number(this.state.optionSelectData.price).toLocaleString()}円<span className="zeikomi">（税込）</span></>
                )}
                {Number(this.state.optionSelectData.price)<0&&(
                  <>{Number(this.state.optionSelectData.price).toLocaleString()}円<span className="zeikomi">（税込）</span></>
                )}
              </div>
              <div>{this.state.optionSelectData.text}</div>
            </div>

            <div className="modal-close">
              <button 
                onClick={(e)=> this.setState({modalIsOpenSecond:false})}
              >閉じる</button>
            </div>
          </Modal>


        </div>
      );
    }
}
