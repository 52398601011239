//import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";

import TopHeader from './TopHeader';
import {functionFields} from '../components/MyFunction';
import FloatingButton from "../components/parts/FloatingButton";
//import StoreSchedule from "./StoreSchedule";


import './assets/admin.css';

import {Line} from 'react-chartjs-2'
import Chart from 'chart.js/auto';
import axios from "axios";

function SEOChartPage() {
  const [which, setWhich] = useState('view1');

  const [labelData, setLabels] = useState([""]);
  const [viewArray, setViewArray] = useState([""]);
  const [graphData, setGraphData] = useState({labels:{}, datasets: []});
  const [options, setOptions] = useState({maintainAspectRatio: false});

  const [total, setTotal] = useState('');
  const [totalWeb, setTotalWeb] = useState('');
  const [totalCall, setTotalCall] = useState('');
  const [totalImpression, setTotalImpression] = useState('');
  const [totalClick, setTotalClick] = useState('');

  const [labelCss , setLabelCss] = useState('label_1');


  var todayDate = new Date();
  var todayDateString = todayDate.getFullYear() + '-' + String(todayDate.getMonth()+1) + '-' +  todayDate.getDate();

  useEffect(() => {
    //console.log('HELLO');
    var url = window.location.href;
    if(url.indexOf('admin') != -1){
      //document.getElementsByClassName('header')[0].style.display = 'none';
      
    }
    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {

    }

    //
    document.getElementsByTagName('body')[0].style.borderTop = 'initial';
    document.getElementsByClassName('header')[0].style.display = 'none';
    
    var authCookieVal = functionFields.getAuthCookieVal('__kb_user_auth');
    if(authCookieVal===''){//未認証
      window.location.href = '/google_auth';
    }

    //http://maruichi.s17.valueserver.jp/python/GoogleAnalyticsAPI/ga.json
    //var url = 'https://kyoto-butsudan.com/API/cron/seo_1.json';
    var url = 'https://kyoto-butsudan.com/API/cron/GoogleAnalyticsButsudan.json';
    axios.get(url).then( res => {
      console.dir(res.data.pageViews);
      var responseArray = res.data.pageViews;
      var labelData = [];
      var viewArray = [];
      var totalView = 0;
      for (let i = 0; i < responseArray.length; i++) {
        var thisDate = responseArray[i].date;
        var thisDate = thisDate.slice(4);
        var month = thisDate.slice(0,2);
        var day = thisDate.slice(2);
        labelData.push(month+'/'+day);

        var thisView = responseArray[i].views;
        viewArray.push(thisView);
        var totalView = totalView+thisView;
      }
      setLabels(labelData);
      setViewArray(viewArray);



          //var labelData = ["1 月", "2 月", "3 月", "4 月", "5 月", "6 月"];
      var graphData = {
        labels: labelData,
        datasets: [
          {
            label: "閲覧数("+String(totalView)+")",
            data: viewArray,
            borderColor: "#2cb696",
            fill: false,
            lineTension: 0.3,
            pointBorderColor: "rgba(75,192,192,1)",          
            pointBackgroundColor: "#fff",             
            pointRadius: 0,
            pointHoverRadius: 8,
            pointHoverBackgroundColor: "rgba(75,192,192,1)",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHitRadius: 10,
          },
          //{
          //  label: "B社",
          //  data: [60, 55, 57, 61, 75, 50],
          //  borderColor: "rgb(75, 100, 192)",
          //},
        ],
      };

      var options = {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position:"bottom",
            labels: {
              padding: 10,
              font: {
                size: 16,
              },
            },
          },
        },
      };

      //setLabels(labelData);
      setGraphData(graphData);
      setOptions(options);

    })
    




  }, []);

  const changeView = (e) =>{
    
    console.dir(e.target.id);
    setWhich(e.target.id);


    if(e.target.id==='view3'){
      //https://kyoto-butsudan.com/API/cron/seo_3.json
      var url = 'https://kyoto-butsudan.com/API/cron/seo_3.json';
      axios.get(url).then( res => {
        console.dir(res.data);
        var responseArray = res.data.pageViews;
        var labelData = [];
        var viewArrayWeb = [];
        var viewArrayCall = [];
        for (let i = 0; i < responseArray.length; i++) {
          var thisDate = responseArray[i].date;
          var thisDate = thisDate.slice(4);
          var month = thisDate.slice(0,2);
          var day = thisDate.slice(2);
          labelData.push(month+'/'+day);
  
          var thisView = responseArray[i].view_web;
          viewArrayWeb.push(thisView);
          var thisView = responseArray[i].view_call;
          viewArrayCall.push(thisView);
        }

        var total_web = String(res.data.total.web);
        var total_call = String(res.data.total.call);
        if(total_web.length>total_call.length){
          var diff = total_web.length-total_call.length;
          var i=0
          while(i<diff){
            var total_call = '_' + total_call;
            i+=1;
          }
        }else if(total_web.length<total_call.length){
          var diff = total_call.length-total_web.length;
          var i=0
          while(i<diff){
            var total_web = '_' + total_web;
            i+=1;
          }
        }


        var graphData = {
          labels: labelData,
          datasets: [
            {
              label: "Ｗｅｂ予約タップ数("+String(total_web)+")",
              data: viewArrayWeb,
              borderColor: "#2cb696",
              fill: false,
              lineTension: 0.3,
              pointBorderColor: "rgba(75,192,192,1)",          
              pointBackgroundColor: "#fff",             
              pointRadius: 0,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: "rgba(75,192,192,1)",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHitRadius: 10,
            },
            {
              label: "電話予約タップ数("+String(total_call)+")　",
              data: viewArrayCall,
              borderColor: "orange",
              fill: false,
              lineTension: 0.3,
              pointBorderColor: "orange",          
              pointBackgroundColor: "#fff",             
              pointRadius: 0,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: "orange",
              pointHoverBorderColor: "orange",
              pointHitRadius: 10,
            },
          ],
        };

        setLabels(labelData);
        setGraphData(graphData);

        setTotalWeb(String(res.data.total.web));
        setTotalCall(String(res.data.total.call));

        setLabelCss('label_3');
      })
      


    }else if(e.target.id==='view2'){
      var url = 'https://kyoto-butsudan.com/API/cron/GoogleSearchConsoleButsudan.json';
      axios.get(url).then( res => {
        //console.dir(res.data);
        var responseArray = res.data.pageViews;
        var labelData = [];
        var impressionArray = [];
        var clickArray = [];
        var totalImpression = 0;
        var totalClick = 0;
        for (let i = 0; i < responseArray.length; i++) {
          var thisDate = responseArray[i].keys[0];
          var thisDate = thisDate.slice(5);
          //console.log(thisDate);
          var month = thisDate.slice(0,2);
          var day = thisDate.slice(2);
          var day = day.replace('-','');
          labelData.push(month+'/'+day);
  
          var thisView = responseArray[i].impressions;
          impressionArray.push(thisView);
          var thisView = responseArray[i].clicks;
          clickArray.push(thisView);

          totalImpression=totalImpression + responseArray[i].impressions;
          totalClick=totalClick + responseArray[i].clicks;
        }

        //setTotalImpression();
        //setTotalClick();

        var graphData = {
          labels: labelData,
          datasets: [
            {
              label: "検索結果表示回数("+String(totalImpression)+")",
              data: impressionArray,
              borderColor: "rgb(156, 156, 156)",
              fill: false,
              lineTension: 0.3,
              pointBorderColor: "rgba(75,192,192,1)",
              pointBackgroundColor: "#fff",
              pointRadius: 0,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: "rgba(75,192,192,1)",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHitRadius: 10,
            },
            {
              label: "検索結果タップ数("+String(totalClick)+")",
              data: clickArray,
              borderColor: "blue",
              fill: false,
              lineTension: 0.3,
              pointBorderColor: "orange",
              pointBackgroundColor: "#fff",
              pointRadius: 0,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: "orange",
              pointHoverBorderColor: "orange",
              pointHitRadius: 10,
            },
          ],
        };

        setLabels(labelData);
        setGraphData(graphData);

        //setTotalWeb(String(res.data.total.web));
        //setTotalCall(String(res.data.total.call));

        setLabelCss('label_2');


      })


    }else if(e.target.id==='view1'){
      var url = 'https://kyoto-butsudan.com/API/cron/GoogleAnalyticsButsudan.json';
      axios.get(url).then( res => {
        console.dir(res.data);
        var responseArray = res.data.pageViews;
        var labelData = [];
        var viewArray = [];
        var totalView = 0;
        for (let i = 0; i < responseArray.length; i++) {
          var thisDate = responseArray[i].date;
          var thisDate = thisDate.slice(4);
          var month = thisDate.slice(0,2);
          var day = thisDate.slice(2);
          labelData.push(month+'/'+day);
  
          var thisView = responseArray[i].views;
          viewArray.push(thisView);
          var totalView = totalView+thisView;
        }


        var graphData = {
          labels: labelData,
          datasets: [
            {
              label: "閲覧数("+String(totalView)+")",
              data: viewArray,
              borderColor: "#2cb696",
              fill: false,
              lineTension: 0.3,
              pointBorderColor: "rgba(75,192,192,1)",          
              pointBackgroundColor: "#fff",             
              pointRadius: 0,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: "rgba(75,192,192,1)",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHitRadius: 10,
            },
          ],
        };

        setLabels(labelData);
        setGraphData(graphData);

        setLabelCss('label_1');
      })
    }
    //setGraphData(graphData);

  }




  return (
    <>
      <TopHeader/>
      <div className="viewMenu">
        <div onClick={changeView} id="view1" className={labelCss==='label_1' ? 'black' : ''}>閲覧数</div>
        <div onClick={changeView} id="view2" className={labelCss==='label_2' ? 'black' : ''} >検索結果表示数</div>
        <div onClick={changeView} id="view3" className={labelCss==='label_3' ? 'black' : ''} >電話&申込数</div>
      </div>
      
      <div className="">{/*コンポーネントの切り替えではビヨーンと伸びる, データ変更で対応*/}
        
        <div className="chartArea" >
          <Line
            height={400}
            width={300}
            data={graphData}
            options={options}
            id="chart-key"
          />
        </div>

      </div>
      
      <FloatingButton />
    </>
  );
}

export default SEOChartPage;
