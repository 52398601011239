//import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import parse from 'html-react-parser';
import {Helmet} from 'react-helmet';

import Header from './parts/Header';

import features_text from './assets/features_text.json'
import './common.css';
import itemImg from './assets/img/obutsudan-top.jpg';

import settings from '../settings.json';


function Home() {
  const [br, setBr] = useState('<br>');
  const [mobileBr, setMobileBr] = useState('');
  const [title_text_1, setTitle_text_1] = useState('');
  const [subtitle_text_1, setSubtitle_text_1] = useState('');
  const [hero_img_1, setHero_img_1] = useState('');
  const [title_text_2, setTitle_text_2] = useState('');
  const [subtitle_text_2, setSubtitle_text_2] = useState('');
  const [hero_img_2, setHero_img_2] = useState('');
  const [title_text_3, setTitle_text_3] = useState('');
  const [subtitle_text_3, setSubtitle_text_3] = useState('');
  const [hero_img_3, setHero_img_3] = useState('');
  const [title_text_4, setTitle_text_4] = useState('');
  const [subtitle_text_4, setSubtitle_text_4] = useState('');
  const [hero_img_4, setHero_img_4] = useState('');
  const [title_text_5, setTitle_text_5] = useState('');
  const [subtitle_text_5, setSubtitle_text_5] = useState('');
  const [hero_img_5, setHero_img_5] = useState('');
  const [title_text_6, setTitle_text_6] = useState('');
  const [subtitle_text_6, setSubtitle_text_6] = useState('');
  const [hero_img_6, setHero_img_6] = useState('');
  const [title_text_7, setTitle_text_7] = useState('');
  const [subtitle_text_7, setSubtitle_text_7] = useState('');
  const [hero_img_7, setHero_img_7] = useState('');
  const [title_text_8, setTitle_text_8] = useState('');
  const [subtitle_text_8, setSubtitle_text_8] = useState('');
  const [hero_img_8, setHero_img_8] = useState('');

  const [devImg, setDevImg] = useState('');

  //let url = 'https://keiei-support-plus-a.com/kaisha-setsuritsu/teikan-jigyou-mokuteki/api/post.php';
  //let limit = 10;

  useEffect(() => {
    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {
      setBr('');
      setMobileBr('<br>');
    }else{
      //console.log('_style_pc');
      const $style = document.createElement("link");
      $style.rel = "stylesheet";
      $style.type = "text/css";
      $style.href = "/css/pc.css";
      document.head.appendChild($style);
    }

    setTitle_text_1(features_text['1']['title']);
    setSubtitle_text_1(features_text['1']['subtitle']);
    setHero_img_1(features_text['1']['header_img']);
    setTitle_text_2(features_text['2']['title']);
    setSubtitle_text_2(features_text['2']['subtitle']);
    setHero_img_2(features_text['2']['header_img']);
    setTitle_text_3(features_text['3']['title']);
    setSubtitle_text_3(features_text['3']['subtitle']);
    setHero_img_3(features_text['3']['header_img']);
    setTitle_text_4(features_text['4']['title']);
    setSubtitle_text_4(features_text['4']['subtitle']);
    setHero_img_4(features_text['4']['header_img']);
    setTitle_text_5(features_text['5']['title']);
    setSubtitle_text_5(features_text['5']['subtitle']);
    setHero_img_5(features_text['5']['header_img']);
    setTitle_text_6(features_text['6']['title']);
    setSubtitle_text_6(features_text['6']['subtitle']);
    setHero_img_6(features_text['6']['header_img']);
    setTitle_text_7(features_text['7']['title']);
    setSubtitle_text_7(features_text['7']['subtitle']);
    setHero_img_7(features_text['7']['header_img']);
    setTitle_text_8(features_text['11']['title']);
    setSubtitle_text_8(features_text['11']['subtitle']);
    setHero_img_8(features_text['11']['header_img']);


    if(settings.DEV==='/_dev'){
      setDevImg('https://kyoto-butsudan.com/');
    }else{
      setDevImg('');
    }
  }, []);


  



  return (
    <>
      <Helmet>
        {/*<title>{settings.TITLE}/トップ</title>*/}
        <title>京都市の現代風小型仏壇専門店｜上置仏壇専門店</title>
        <meta
          name="description"
          content="京都の仏壇・仏具専門店なら【株式会社 まるいち】洋風でおしゃれな小さい仏壇に標準仏具を付属し、お求めやすいセット価格にて販売。弊社スタッフが一貫して販売・納品・飾付を行う安心の品質表示。"
        />
      </Helmet>




      <Header/>

      <div className="content Home">
        <section className="feature" id="__1">
          <p className="pt-1">
            弊社では完全予約制による人件費の軽減、<span>仏壇仏具の</span><span>セット販売</span>による<span>仕入れコストの</span>軽減を<span>徹底的に</span>行いお求めやすい価格を実現しております。
            <span>そのため</span><span>仏具の</span>単品販売・日用品（線香・ローソク等）、数珠<span>などの</span>販売は一切行っておりません。
          </p>

          <h2 className="page-h2 feature-h2 pb-05 mt-03 mt-06">
            {/*
            当店8つ<span className="text-small">の</span>特徴
            <span className="feature-mini-title">選ばれる理由</span>  
            */}
            <img className="top-hero" src={devImg+"images/top/top-banner-2.jpg?v=20220426"} />
          </h2>
          <h3 className="feature-h2 mb-0 pb-05 font-09">
            ①{parse(title_text_1)}
            {/*<span className="feature-mini-title color-bk ">{subtitle_text_1}</span>*/}
          </h3>
          <img src={devImg+hero_img_1} alt={'（株）まるいちの仏壇 京都の小型仏壇店の特徴:'+title_text_1} className="feature-img" />
          <p>
            住環境の変化に合わせた「家具に合う」「洋風な」「小さい」「現代風小型（上置）仏壇」を<span>専門に</span><span>取り扱い</span>しております。
          </p>
          <div className="show-detail-button mb-45">
            <a href={settings.DEV+"/features?textKey=1"}>詳しく見る</a>
          </div>

          <h3 className="feature-h2 mb-0 pb-05">
            ②{title_text_2}
            {/*<span className="feature-mini-title color-bk ">{subtitle_text_2}</span>*/}
          </h3>
          <img src={devImg+hero_img_2} alt={'（株）まるいちの仏壇 京都の小型仏壇店の特徴:'+title_text_2} className="feature-img" />
          <p>
            お客様のお好みに合わせて選択可能な<span>多種類の</span><span>仏具の</span>ラインナップがございます。
          </p>
          <div className="show-detail-button mb-45">
            <a href={settings.DEV+"/features?textKey=2"}>詳しく見る</a>
          </div>

          <h3 className="feature-h2 mb-0 pb-05">
            ③{title_text_3}
            {/*<span className="feature-mini-title color-bk ">{subtitle_text_3}</span>*/}
          </h3>
          <img src={devImg+hero_img_3} alt={'（株）まるいちの仏壇 京都の小型仏壇店の特徴:'+title_text_3} className="feature-img" />
          <p>
            すべての仏壇に【標準仏具】を付属しお求めやすい<span>セット</span>価格にて販売しております。
          </p>
          <p>
            【標準仏具】・ご宗派の本尊・具足・リン・供物台
          </p>
          <div className="show-detail-button mb-45">
            <a href={settings.DEV+"/features?textKey=3"}>詳しく見る</a>
          </div>

          <h3 className="feature-h2 mb-0 pb-05">
            ④{title_text_4}
            {/*<span className="feature-mini-title color-bk ">{subtitle_text_4}</span>*/}
          </h3>
          <img src={devImg+hero_img_4} alt={'（株）まるいちの仏壇 京都の小型仏壇店の特徴:'+title_text_4} className="feature-img" />
          <p>
            ３６５日　１０時～２１時まで営業をしております。
          </p>
          <p>
            お仕事帰りやご家族皆様でのご来店に便利です。
          </p>
          <div className="show-detail-button mb-45">
            <a href={settings.DEV+"/features?textKey=4"}>詳しく見る</a>
          </div>

          <h3 className="feature-h2 mb-0 pb-05">
            ⑤{title_text_5}
            {/*<span className="feature-mini-title color-bk ">{subtitle_text_5}</span>*/}
          </h3>
          <img src={devImg+hero_img_5} alt={'（株）まるいちの仏壇 京都の小型仏壇店の特徴:'+title_text_5} className="feature-img" />
          <p>
            ショールーム内が密になることを避け、１組のお客様にごゆっくりとご検討してただくため、「<span>完全予約制</span>・<span>同一時間</span><span>１組限定</span>」としております。
          </p>
          <div className="show-detail-button mb-45">
            <a href={settings.DEV+"/features?textKey=5"}>詳しく見る</a>
          </div>

          <h3 className="feature-h2 mb-0 pb-05">
            ⑥{title_text_6}
            {/*<span className="feature-mini-title color-bk ">{subtitle_text_6}</span>*/}
          </h3>
          <img src={devImg+hero_img_6} alt={'（株）まるいちの仏壇 京都の小型仏壇店の特徴:'+title_text_6} className="feature-img" />
          <p>
            弊社スタッフが一貫して販売・納品・飾付を行います。お買い換えの際は古い仏壇の引取も行います。
          </p>
          
          <div className="show-detail-button mb-45">
            <a href={settings.DEV+"/features?textKey=6"}>詳しく見る</a>
          </div>

          <h3 className="feature-h2 mb-0 pb-05">
            ⑦{title_text_7}
            {/*<span className="feature-mini-title color-bk ">{subtitle_text_7}</span>*/}
          </h3>
          <img src={devImg+hero_img_7} alt={'（株）まるいちの仏壇 京都の小型仏壇店の特徴:'+title_text_7} className="feature-img" />
          <p>
            仏壇の原産国表示・材質表示など品質情報の<span>開示</span>を<span>行い、</span>信頼関係を大切にし安心して<span>お買い求め</span><span>いただける</span><span>取り組み</span>を行っています。
          </p>
          <div className="show-detail-button mb-45">
            <a href={settings.DEV+"/features?textKey=7"}>詳しく見る</a>
          </div>

          <h3 className="feature-h2 mb-0 pb-05">
            ⑧{title_text_8}
            {/*<span className="feature-mini-title color-bk ">{subtitle_text_7}</span>*/}
          </h3>
          <img src={devImg+hero_img_8} alt={'（株）まるいちの仏壇 京都の小型仏壇店の特徴:'+title_text_8} className="feature-img" />
          <p>
            仏壇・仏事に関するご相談はもちろん、<span>葬儀後の</span><span>手続き等</span>においてもトータルサポートが可能です。
          </p>
          <div className="show-detail-button mb-45">
            <a href={settings.DEV+"/features?textKey=11"}>詳しく見る</a>
          </div>

        </section>
        {/* 7つの紹介終わり */}

        <section className="obutsudan-toppage">
          {/*<h2 className="page-h2 feature-h2 pb-1">*/}
          <h2 className="feature-h2">
            ショールームのご紹介
            <span className="feature-mini-title"></span>  
          </h2>
          <img className="top-img mt-1" src="images/features/showroom.jpg" />
          <p>
            小型（上置）仏壇を専門に様々な種類の<span>仏壇・</span><span>仏具</span>を<span>展示</span>しております。落ち着いた空間にて<span>ごゆっくりと</span><span>ご覧</span>ください。
          </p>
          <div className="show-detail-button mb-45">
            <a href={settings.DEV+"/features?textKey=8"}>詳しく見る</a>
          </div>
        </section>

        <section className="obutsudan-toppage">
          {/*<h2 className="page-h2 feature-h2 pb-1">*/}
          <h2 className="feature-h2">
            お仏壇・仏具の紹介
            {/*<span className="feature-mini-title">Web見積り可能</span>*/}
          </h2>
          <img src={itemImg} alt={'お仏壇、仏具の紹介'} className="feature-img mt-1" />
          <p>
            現在ショールームにて展示している仏壇・仏具の<span>ご紹介</span>になります。
          </p>
          <p className="notification-text">※売約済み等の事情により欠品がある場合がございます。</p>
          <div className="show-detail-button mb-45">
            <a href={settings.DEV+"/item-list"}>詳しく見る</a>
          </div>
        </section>

        <section className="obutsudan-toppage">
          {/*<h2 className="page-h2 feature-h2 pb-1">*/}
          <h2 className="feature-h2">
            仏壇処分
            <span className="feature-mini-title"></span>  
          </h2>
          <img className="top-img mt-1" src="images/features/top-syobun-1.jpg"/>
          <p >仏壇の引取・処分方法についてご説明いたします。</p>
          <div className="show-detail-button mb-45">
            <a href={settings.DEV+"/obutsudan-owakare"}>詳しく見る</a>
          </div>
        </section>


        <section className="obutsudan-toppage">
          {/*<h2 className="page-h2 feature-h2 pb-1">*/}
          <h2 className="feature-h2">
            各宗派の祀り方
            <span className="feature-mini-title"></span>  
          </h2>
          <img className="top-img mt-1" src="https://maruichi-kyoto.com/img/98.jpg"/>
          <p>各宗派のお仏壇の祀り方例をご紹介します。</p>
          <div className="show-detail-button mb-45">
            <a href={settings.DEV+"/features?textKey=10"}>詳しく見る</a>
          </div>
        </section>

        <section className="obutsudan-toppage">
          {/*<h2 className="page-h2 feature-h2 pb-1">*/}
          <h2 className="feature-h2">
            お仏壇についてのQ&amp;A
            <span className="feature-mini-title"></span>  
          </h2>
          <img className="top-img mt-1" src="https://maruichi-kyoto.com/img/92.jpg"/>
          <p><span>「店舗で買うと高いんじゃない？」</span><span>など</span><span>お客様から</span><span>お寄せ頂いた</span><span>ご質問を</span>ご紹介します。</p>
          <div className="show-detail-button mb-45">
            <a href={settings.DEV+"/q-a"}>詳しく見る</a>
          </div>
        </section>


      
      </div>

    </>
  );
}

export default Home;
