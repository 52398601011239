import headText from './assets/head.json';
import settings from '../settings.json';

//const path = require('path');
//path.resolve(__dirname, '../settings.json')

class FunctionFields {
  convertWeekJa(weekLabelEn){
    switch(weekLabelEn) {
      case 'S0':
        var weekLabelJa='日';
        break;
      case 'M1':
        var weekLabelJa='月';
        break;
      case 'T2':
        var weekLabelJa='火';
        break;
      case 'W3':
        var weekLabelJa='水';
        break;
      case 'T4':
        var weekLabelJa='木';
        break;
      case 'F5':
        var weekLabelJa='金';
        break;
      case 'S6':
        var weekLabelJa='土';
        break;
    }
    return weekLabelJa;
  }



  convertMonthJa(monthText){
    switch(monthText) {
      case 'January':
        var montheJa='1月';
        break;
      case 'February':
        var montheJa='2月';
        break;
      case 'March':
        var montheJa='3月';
        break;
      case 'April':
        var montheJa='4月';
        break;
      case 'May':
        var montheJa='5月';
        break;
      case 'June':
        var montheJa='6月';
        break;
      case 'July':
        var montheJa='7月';
        break;
      case 'August':
        var montheJa='8月';
        break;
      case 'September':
        var montheJa='9月';
        break;
      case 'October':
        var montheJa='10月';
        break;
      case 'November':
        var montheJa='11月';
        break;
      case 'December':
        var montheJa='12月';
        break;
        
      case 'Jan':
        var montheJa='1月';
        break;
      case 'Feb':
        var montheJa='2月';
        break;
      case 'Mar':
        var montheJa='3月';
        break;
      case 'Apr':
        var montheJa='4月';
        break;
      case 'May':
        var montheJa='5月';
        break;
      case 'Jun':
        var montheJa='6月';
        break;
      case 'Jul':
        var montheJa='7月';
        break;
      case 'Aug':
        var montheJa='8月';
        break;
      case 'Sep':
        var montheJa='9月';
        break;
      case 'Oct':
        var montheJa='10月';
        break;
      case 'Nov':
        var montheJa='11月';
        break;
      case 'Dec':
        var montheJa='12月';
        break;
      default:
        // code block
    }
    return montheJa;
  }



  //日付Objを文字列に
  getStringFromDate(date) {
 
    var year_str = date.getFullYear();
    //月だけ+1すること
    var month_str = 1 + date.getMonth();
    var day_str = date.getDate();
    var hour_str = date.getHours();
    var minute_str = date.getMinutes();
    var second_str = date.getSeconds();
    
    
    var format_str = 'YYYY-MM-DD hh:mm:ss';
    var format_str = format_str.replace(/YYYY/g, year_str);
    var format_str = format_str.replace(/MM/g, month_str);
    var format_str = format_str.replace(/DD/g, day_str);
    var format_str = format_str.replace(/hh/g, hour_str);
    var format_str = format_str.replace(/mm/g, minute_str);
    var format_str = format_str.replace(/ss/g, second_str);
    
    return format_str;
  };



  //日付を文字列からOBJ、OBJから文字列に
  convertdate(type, date){
    if(type==='str-obj-full'){
      //2022年3月29日 午前11時
      //console.log(date);
      if(date.indexOf('-')!==-1){
        date = date.replace(/-/g,"/");
        var dateObj = new Date(date);
      }else{
        var y_s = date.indexOf('年');
        var y = date.substring(0, y_s);
  
        var m_s = date.indexOf('月');
        var m = date.substring(y_s+1, m_s);
  
        var d_s = date.indexOf('日');
        var d = date.substring(m_s+1, d_s);
  
        var h_s = date.indexOf('時');
        var h = date.substring(d_s+1, h_s);
        //var h = h.slice(3);
        var h = h.slice(1);
        if(date.indexOf('午後')!==-1){
          var h = h.replace('午後','');
          var h = Number(h) +12;
        }else if(date.indexOf('午前')!==-1){
          var h = h.replace('午前','');
          var h = Number(h);
        }
        if(date.indexOf('分')!==-1){
          var min_s = date.indexOf('分');
          var min = date.substring(h_s+1, min_s);
          var dateObj = new Date( y, m-1, d, h , min);
        }else{
          var dateObj = new Date( y, m-1, d, h);
        }
  
      }
      //console.log(dateObj);
      
      if(dateObj.getMinutes()>15){//◯時15分超えていたら次の1時間も受け付けない
        var date_1 = new Date(dateObj.setMinutes(0));
        var date_2 = new Date(dateObj.setHours(dateObj.getHours()+1));
      }else{
        var date_1 = new Date(dateObj.setMinutes(0));
        var date_2 = '';
      }

      var dateObjArray = [date_1, date_2];

      //console.log(dateObjArray);

      return dateObjArray;
    }else if(type==='obj-str-full'){
      var format = 'YYYY年MM月DD日';
      var format = format.replace(/YYYY/, date.getFullYear());
      var format = format.replace(/MM/, date.getMonth() + 1);
      var format = format.replace(/DD/, date.getDate());

      var hour24 = date.getHours();
      var hour12 = hour24 % 12;
      var hourStr = hour24 < 12 ? '午前' : '午後';
      var minutes = date.getMinutes();
      var format = format + ' ' + hourStr + hour12 +'時'+ minutes + '分';

      //console.log(format);
     
      return format;
    }else if(type==='obj-str-notime'){
      var format = 'YYYY年MM月DD日';
      var format = format.replace(/YYYY/, date.getFullYear());
      var format = format.replace(/MM/, date.getMonth() + 1);
      var format = format.replace(/DD/, date.getDate());
      //console.log(format);
     
      return format;
    }

  }



  //管理画面かどうかの確認
  checkFooterShow(thisUrl){// /admin
    var result = true;
    switch(thisUrl){
      case '/admin':
        var result = false;
        break;
      case '/schedule':
        var result = false;
        break;
      case '/seo':
        var result = false;
        break;
      case '/crud':
        var result = false;
        break;
      case '/google_auth':
        var result = false;
        break;
      case '/reserve':
        var result = false;
        break;
    }

    return result;
  }


  //cookie確認
  getAuthCookieVal(key){
    //console.log(key);
    var cookies = document.cookie; //全てのcookieを取り出して
    var cookiesArray = cookies.split(';'); // ;で分割し配列に
    //console.log(cookiesArray);
    
    var cookieVal = '';
    for(var c of cookiesArray){ //一つ一つ取り出して
        var cArray = c.split('='); //さらに=で分割して配列に
        //console.log(cArray[0]);
        cArray[0] = cArray[0].replace(/\s+/g, "");
        if( cArray[0] == key){ // 取り出したいkeyと合致したら
            //console.log('一致');
            //console.log(cArray);  // [key,value] 
            var cookieVal = cArray;
            break;
        }
    }
    //console.log(cookieVal);
    return cookieVal;
  }



  //オプショントースト　オプション種別取得
  getOptionTypeName(type){
    var optionTypeName = '';
    switch(type){
      case 'gusoku':
        var optionTypeName = '具足セット';
        break;
      case 'honzon':
        var optionTypeName = '本尊';
        break;
    }
    return optionTypeName;
  }

  //仏具アイテムの取得
  getButsugu(key){
    const url = settings.GET_BUTSUGU_API_URL + '?type=' + key;
    var Httpreq = new XMLHttpRequest();
    Httpreq.open("GET", url, false);
    Httpreq.send(null);
    //console.dir(JSON.parse(Httpreq.responseText));
    var response_obj = JSON.parse(Httpreq.responseText)['data'];
    var response_array = JSON.parse(response_obj);
    return response_array;

  }


  //商品詳細ページなど URL クエリ処理
  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2]);
  }

  
  //ページアクセス時に App.js 経由でここきて、 <head>の title, description を変える
    getTitle(){
      var urlNow =  window.location.href;
      //var urlNow = urlNow.slice(27);//本番用
      var urlNow = urlNow.slice(22);//開発用
      //console.log(urlNow);
      switch(urlNow){
        case '':
          var title = headText.top.title;
          var description = headText.top.description;
          break;
        case 'about':
          var title = headText.about.title;
          var description = headText.about.description;
          break;
      }
      return [title, description]
    }
    
    

    hankaku2Zenkaku(str) {
      return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
    }


    isSmartPhone() {
      //console.dir(navigator.userAgent);
      //if (navigator.userAgent.match(/iPhone|Android+Mobile/)) {
      if (navigator.userAgent.match(/iPhone|Android/)) {
        return true;
      } else {
        return false;
      }
    }


    sleep(waitMsec) {
      var startMsec = new Date();
      while (new Date() - startMsec < waitMsec);
    }

  
}

const functionFields = new FunctionFields();

export { functionFields };