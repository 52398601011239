//表示文字の変更　/node_modules/@devexpress/dx-react-scheduler/dist/dx-react-scheduler.es.js

//import ReactPaginate from "react-paginate";
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { ViewState, EditingState, IntegratedEditing } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Resources,
  DayView,
  WeekView,
  MonthView,
  Toolbar,
  DateNavigator,
  Appointments,
  AppointmentForm,
  AppointmentTooltip,
  ConfirmationDialog,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  blue, pink, purple, teal, amber, deepOrange, orange
} from '@mui/material/colors';
//import appointments from './assets/scadule-data/today-appointments';
//import { appointments, resourcesData } from './assets/scadule-data/appointments';

//import { useEffect, useState } from "react";
import './assets/admin.css';
import './assets/schedule.css';
import { functionFields } from "../components/MyFunction";
import toast, { Toaster } from 'react-hot-toast'
import settings from '../settings.json';
import axios from "axios";

//DevExpress
//Safariで動かないので, https://community.devexpress.com/blogs/aspnet/archive/2017/04/04/devextreme-fixing-iso-dates-v16-2-6.aspx
//DevExpress.config({
//  forceIsoDateParsing: true,
//});


//const appointments = [
//  {
//    title: 'JEPPE',
//    startDate: new Date(2022, 1, 28, 12, 0),
//    endDate: new Date(2022, 1, 28, 13, 0),
//    id: 0,
//    serializationFormat: "YYYY-MM-dd HH-mm"
//  },
//  {
//    title: '中田様1',
//    startDate: new Date(2022, 2, 25, 12, 0),
//    endDate: new Date(2022, 2, 25, 13, 0),
//    id: 0,
//    serializationFormat: "YYYY-MM-dd HH-mm"
//  },
//];


const resourcesData = [
  {
    text: '休店',
    id: 1,
    color: deepOrange,
  }, {
    text: '予約',
    id: 2,
    color: blue,
  },{
    text: '閉店',
    id: 3,
    color: orange,
  },
  
];


const PREFIX = 'Scheduler';

const classes = {
  container: `${PREFIX}-container`,
  text: `${PREFIX}-text`,
};

const StyledDiv = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    justifyContent: 'flex-end',
  },
  [`& .${classes.text}`]: {
    ...theme.typography.h6,
    marginRight: theme.spacing(2),
  },
}));

//切り替え
const ExternalViewSwitcher = ({
  currentViewName,
  onChange,
}) => (
  <RadioGroup
    aria-label="Views"
    style={{ flexDirection: 'row' }}
    name="views"
    value={currentViewName}
    onChange={onChange}
  >
    <FormControlLabel value="Day" control={<Radio />} label="日" />
    <FormControlLabel value="Week" control={<Radio />} label="週" />
    <FormControlLabel value="Month" control={<Radio />} label="月" />
  </RadioGroup>
);


export default class StoreSchedule extends React.PureComponent {
  constructor(props) {
    super(props);

    var todayDate = new Date();
    this.state = {
      //data: appointments,
      data:[],
      resources: [
        {
          fieldName: 'typeId',
          title: '種類',
          instances: resourcesData,
        },
      ],

      currentDate: todayDate.getFullYear() + '-' + toDoubleDigits(todayDate.getMonth()+1) + '-' +  toDoubleDigits(todayDate.getDate()),// '2022-03-05',
      locale: 'ja-JP',
      currentViewName: 'Month',
      labelDateStr:'',
      labelDateObj:new Date(),
      labelDateEndStr:'',
      labelDateEndObj:new Date(),
      memoToastStatus:false,
      timeCheck:'',
      timeCheckAlert:false,
      brakScreen:false,
      scheduleId:'',
      hiddenTrashbox:false,
      checkOk:'no',
    };

    this.currentViewNameChange = (e) => {
      this.setState({ currentViewName: e.target.value });
    };
    this.commitChanges = this.commitChanges.bind(this);
    this.editingAppointmentChange = this.editingAppointmentChange.bind(this);
    this.onAppointmentChangesChange = this.onAppointmentChangesChange.bind(this);
    this.visibilithChange = this.visibilithChange.bind(this);
    this.visibilithChange = this.visibilithChangeAp.bind(this);
  }

  //
  componentDidMount () {
    var url = settings.GET_NOT_RESERVE_TIME_API_URL+'?type=appoint';
    const getDBData = async () => {//予約
      const res = await fetch(url);
      const data = await res.json();
      var dbDatas = data.data;
      //console.dir(dbDatas);
      var dbDataArray = [];
      for (let i = 0; i < dbDatas.length; i++) {
        if(dbDatas[i]['id']===2){
          var thisTitle = dbDatas[i]['title']+'('+dbDatas[i]['access_type']+')';
        }else{
          var thisTitle = dbDatas[i]['title'];
        }
        var thisObj = {
          title:thisTitle, 
          startDate:new Date(dbDatas[i]['startDate'].replace(/-/g,"/")), 
          endDate:new Date(dbDatas[i]['endDate'].replace(/-/g,"/")),
          notes:dbDatas[i]['notes'],
          id:dbDatas[i]['id'],
          typeId:dbDatas[i]['typeId'],
          //serializationFormat: "YYYY-MM-dd HH-mm"
        };
        dbDataArray.push(thisObj);
      }
      //console.dir(dbDataArray);
      this.setState({data:dbDataArray});

    };
    try{
      getDBData();
    }catch(e){
      console.log('fetch erro..');
      //setTimeout(function(){
      //  window.location.reload(true);
      //}, 1000);//wait 2 seconds
    }
    


  }

  //予定変更のタップイベント 開く前
  editingAppointmentChange(e){
    //this.setState((state) => {
    //  let { data } = state;
    //  console.log(data);
    //  //if(startDate){
    //  //  console.dir(data[0]['startDate']);
    //  //}
    //})
    //console.dir(e);
    if(e){
      this.setState({scheduleId:e.id});
      if(e.typeId===2){//予約
        //日付フォーマット変換
        var startDate = e.startDate;
        var convertDataObj = new Date(startDate);
        var convertDataString = functionFields.convertdate('obj-str-full', convertDataObj);
        this.setState({labelDateStr:convertDataString});

        var endDate = e.endDate;
        var convertDataObj = new Date(endDate);
        var convertDataString = functionFields.convertdate('obj-str-full', convertDataObj);
        this.setState({labelDateEndStr:convertDataString});

      }else if(e.typeId===1){//休店
        this.setState({brakScreen:true});
        var startDate = e.startDate;
        this.setState({labelDateObj:startDate});
        var convertDataObj = new Date(startDate);
        var convertDataString = functionFields.convertdate('obj-str-full', convertDataObj);
        this.setState({labelDateStr:convertDataString});

        var endDate = e.endDate;
        this.setState({labelDateEndObj:endDate});
        var convertDataObj = new Date(endDate);
        var convertDataString = functionFields.convertdate('obj-str-full', convertDataObj);
        this.setState({labelDateEndStr:convertDataString});
      }else if(e.typeId===3){//休み
        var startDate = e.startDate;
        var convertDataObj = new Date(startDate);
        var convertDataString = functionFields.convertdate('obj-str-full', convertDataObj);
        this.setState({labelDateStr:convertDataString});

        var endDate = e.endDate;
        var convertDataObj = new Date(endDate);
        var convertDataString = functionFields.convertdate('obj-str-full', convertDataObj);
        this.setState({labelDateEndStr:convertDataString});
      }
    }


    //console.log(convertDataString);
    //console.dir( document.getElementsByClassName('Layout-dateEditors').item(0) );
  }

  //変更して保存前
  onAppointmentChangesChange(e){
    //console.dir(e);
    //console.log('this.state.checkOk '+this.state.checkOk);
    this.setState({timeCheck:''});
    //console.log(this.state.scheduleId);
    this.setState({brakScreen:false});
    if(typeof e.startDate!=='undefined' && this.state.scheduleId.indexOf('b-')===-1){
      //console.dir(e.startDate);
      var convertDataString = functionFields.convertdate('obj-str-full', e.startDate);
      this.setState({labelDateStr:convertDataString})
      //console.log(convertDataString);
      
      var timeCheck = 'ok';
      for (let i = 0; i < this.state.data.length; i++) {
        //if(this.state.scheduleId!==this.state.data[i]['id']){
          var thisStartTime = this.state.data[i]['startDate'];
          var thisEndTime = this.state.data[i]['endDate'].getTime();
          //console.log('LOOP:'+thisStartTime + ' ' + this.state.data[i]['endDate']);

          //console.log('確認');
          //console.log(e.startDate.getHours());
          //console.log(thisStartTime.getHours());
          //console.log(e.startDate.getMinutes());
          //console.log(thisStartTime.getMinutes());

          if(e.startDate.getDate()===thisStartTime.getDate() && (e.startDate.getHours()!==thisStartTime.getHours() || e.startDate.getMinutes()!==thisStartTime.getMinutes() ) ){
            let eEndDate = e.startDate.getTime()+ 3600*1000;
            //console.log(String(i)+'番目 '+this.state.data[i]['id']);
            //console.log(thisStartTime);//変更前
            //console.log(e.startDate);//変更分
            //console.log(eEndDate);//変更開始時間+1
            //console.log(thisStartTime.getTime());
            if(e.startDate<=thisStartTime && thisStartTime.getTime()<eEndDate){//来店予約重複チェック
              var timeCheck = 'double';
              this.setState({timeCheck:timeCheck});
              //console.log('ヒット！'+String(i)+'番目');
              //break;
            }else if(this.state.data[i]['id'].indexOf('h-')!== -1){//休店日チェック
              var timeCheck = 'double';
              this.setState({timeCheck:timeCheck});
              //console.log('ヒット2！'+String(i)+'番目');
            }else if(this.state.data[i]['id'].indexOf('b-')!== -1 && thisStartTime<e.startDate && e.startDate<thisEndTime){
              var timeCheck = 'double';
              this.setState({timeCheck:timeCheck});
              //console.log('ヒット3！'+String(i)+'番目');
            }
          }else if(e.startDate.getDate()===thisStartTime.getDate() && e.startDate.getHours()===thisStartTime.getHours() && e.startDate.getMinutes()===thisStartTime.getMinutes() && this.state.checkOk==='ok' ){
            var timeCheck = 'double';
            this.setState({timeCheck:timeCheck});
            //console.log('ヒット4！'+String(i)+'番目');
          }
        //}

      }
      if(timeCheck==='double'){
        this.setState({timeCheckAlert:false});
        //alert('その時間は他予定と重複します。\n他時間を選択ください。');
      }else{
        this.setState({timeCheck:''});
      }
      

      if(this.state.timeCheck==='double'){
        //console.log(this.state.timeCheckAlert);
        if(!this.state.timeCheckAlert){
          alert('その時間は他予定と重複します。\n他時間を選択ください。');
          this.setState({timeCheckAlert:true});
        }
      }

      setTimeout(function(){
        document.getElementsByClassName('Layout-dateEditors').item(0).childNodes[0].childNodes[0].firstChild.value = convertDataString;
      }, 1000);//wait 1 seconds

    }else if(typeof e.endDate!=='undefined' && this.state.scheduleId.indexOf('b-')===-1){
      //console.dir(e.endDate);//Fri May 13 2022 11:00:00 GMT+0900 (日本標準時) Obj
      //console.log('__cheange_end_date');


    }else if(this.state.scheduleId.indexOf('b-')!==-1 && typeof e.notes==='undefined'){
      //console.log('__break');
      this.setState({brakScreen:false});
      var postDatePbj = new Object();
      if(typeof e.startDate!=='undefined'){
        postDatePbj['start'] = e.startDate.getTime();
        postDatePbj['end'] = this.state.labelDateEndObj.getTime();
        var convertDataString = functionFields.convertdate('obj-str-full', e.startDate);
        this.setState({labelDateStr:convertDataString})
      }
      if(typeof e.endDate!=='undefined'){
        postDatePbj['start'] = this.state.labelDateObj.getTime();
        postDatePbj['end'] = e.endDate.getTime();
        var convertDataEndString = functionFields.convertdate('obj-str-full', e.endDate);
        this.setState({labelDateEndStr:convertDataEndString})
      }

      postDatePbj['type'] = 'checkBreakTime';
      //console.dir(postDatePbj);

      var url = settings.GET_NOT_RESERVE_TIME_API_URL;
      var res = axios.post(url, postDatePbj);//Promise
      res.then((value)=>{
        //console.dir(value.data);
        if(value.data.check_result==='NO'){
          var timeCheck = 'double';
        }else{
          //console.log('__OK');
          var timeCheck = '';
        }

        //console.log(timeCheck);
        if(timeCheck==='double'){
          this.setState({timeCheck:'double'});
          //this.setState({timeCheckAlert:true});
          //alert('その時間は他予定と重複します。\n他時間を選択ください。');
        }else{
          this.setState({timeCheck:''});
        }
        
  
        if(this.state.timeCheck==='double'){
          //console.log(this.state.timeCheckAlert);
          if(!this.state.timeCheckAlert){
            alert('その時間は他予定と重複します。\n他時間を選択ください。');
            this.setState({timeCheckAlert:true});
          }
        }else{
          console.log('変更OK');
          //console.log(typeof e.startDate);
          //console.log(e.startDate);
          //console.log(typeof e.endDate);
          //console.log(e.endDate);
        }
      })
      .catch(error => {
        console.log(error.message);
      })


      if(typeof e.startDate!=='undefined' && e.startDate!=='undefined'){
        //console.log('__1  '+convertDataString);
        try{
          if(document.getElementsByClassName('Layout-dateEditors').item(0).childNodes[0].childNodes[0].firstChild){
            setTimeout(function(){
              document.getElementsByClassName('Layout-dateEditors').item(0).childNodes[0].childNodes[0].firstChild.value = convertDataString;
            }, 1000);//wait 1 seconds
          }
        }catch(e){}
      }
      if(typeof e.endDate!=='undefined' && e.endDate!=='undefined'){
        //console.log('__2  '+convertDataEndString);
        try{
          if(document.getElementsByClassName('Layout-dateEditors').item(0).childNodes[2].childNodes[0].firstChild){
            setTimeout(function(){
              document.getElementsByClassName('Layout-dateEditors').item(0).childNodes[2].childNodes[0].firstChild.value = convertDataEndString;
            }, 1000);//wait 1 seconds
          }
        }catch(e){}
      }
   
    }

    this.setState({checkOk:'ok'});
  }


  //変更
  commitChanges({ added, changed, deleted }) {
    this.setState({brakScreen:false});
    this.setState({memoToastStatus:false});
    this.setState((state) => {
      let { data } = state;
      if (added) {
        const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
        data = [...data, { id: startingAddedId, ...added }];
      }
      if (changed && !this.state.timeCheckAlert) {
        data = data.map(appointment => (
          changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment)
        
        );
        //console.dir( Object.keys(changed)[0] );
        //console.log( changed[Object.keys(changed)[0]]['startDate'] );
        //console.log(typeof changed[Object.keys(changed)[0]]['startDate'] );
        var thisId = Object.keys(changed)[0];

        var updatedDateObj = changed[Object.keys(changed)[0]]['startDate'];
        var updatedDateEndObj = changed[Object.keys(changed)[0]]['endDate'];
        var noteData = changed[Object.keys(changed)[0]]['notes'];
        //console.dir(changed[Object.keys(changed)[0]]['notes']);
        //console.log(updatedDateObj);
        //console.log(updatedDateEndObj);

        var canYouReload = false;

        if((updatedDateObj || updatedDateEndObj) && this.state.timeCheck!=='double'){
          if(updatedDateObj!==undefined){
            var updatedDateString = functionFields.getStringFromDate(updatedDateObj);
          }else{
            var updatedDateString = functionFields.getStringFromDate(this.state.labelDateObj);
          }
          if(updatedDateEndObj!==undefined){
            var updatedDateEndString = functionFields.getStringFromDate(updatedDateEndObj);
          }else{
            var updatedDateEndString = functionFields.getStringFromDate(this.state.labelDateEndObj);
          }

          //console.log(updatedDateString);
          //console.log(updatedDateEndString);
          var getDbData_1 = async() =>{
            var url = settings.GET_NOT_RESERVE_TIME_API_URL;
            var url = url+'?type=change-date&id='+thisId+'&date='+updatedDateString+'&end='+updatedDateEndString;
            //console.log('_url '+url);
            var res = await fetch(url);
            var data_1 = await res.json();
            var response = data_1.result;
            //console.dir(response);
            if(response==='update_ok'){
              if(!this.state.memoToastStatus){
                toast.success('更新OK', {duration: 7000});
                this.setState({memoToastStatus:true});
              }
              var canYouReload = true;
              //setTimeout(function(){
              //  window.scrollTo(0, 0);
              //  window.location.reload(true);
              //}, 3000);//wait 2 seconds
            }else{
              if(!this.state.memoToastStatus){
                toast.error('あれ、エラー...', {duration: 7000});
                this.setState({memoToastStatus:true});
                var canYouReload = false;
              }
            }
            return canYouReload;
          }
          var canYouReload = getDbData_1();
        }
        if(noteData && this.state.timeCheck!=='double'){
          if(noteData.length>1000){
            toast.error('1,000文字以内でお願いします', {duration: 5000});
          }else{
            //console.dir(changed);
            var getDbData_2 = async() =>{
              var url = settings.GET_NOT_RESERVE_TIME_API_URL;
              var res = await fetch(url+'?type=change-note&id='+thisId+'&note='+noteData);
              var data_2 = await res.json();
              var response = data_2.result;
              //console.dir(response);
              if(response==='memo_ok'){
                if(!this.state.memoToastStatus){
                  toast.success('メモOK', {duration: 7000});
                  this.setState({memoToastStatus:true});
                }
                var canYouReload = true;
                //setTimeout(function(){
                //  window.scrollTo(0, 0);
                //  window.location.reload(true);
                //}, 3000);//wait 2 seconds
              }else{
                if(!this.state.memoToastStatus){
                  toast.error('あれ、エラー...', {duration: 7000});
                  this.setState({memoToastStatus:true});
                }
              }
              return canYouReload;
            }
            var canYouReload = getDbData_2();
          }
          
        }else if(this.state.timeCheck==='double'){
          //console.dir(this.props);
          //console.dir(this.state)

          //if(!this.state.timeCheckAlert){
          //  alert('時刻不備、更新なし');
          //  this.setState({timeCheckAlert:true});
          //}
          //if(!this.state.memoToastStatus){
          //  toast.error('時刻不備、更新なし', {duration: 7000});
          //  this.setState({memoToastStatus:true});
            //setTimeout(function(){
            //  window.scrollTo(0, 0);
            //  window.location.reload(true);
            //}, 2000);//wait 2 seconds
          // }

        }


      }else if(changed && this.state.timeCheckAlert){
        alert('変更日重複のため未更新！');
        this.setState({timeCheckAlert:false});
      }

      if (deleted !== undefined) {
        //console.log('del '+deleted);
        var thisId = deleted;
        data = data.filter(appointment => appointment.id !== deleted);

        //削除イベント
        var getDbData = async() =>{
          var url = settings.GET_NOT_RESERVE_TIME_API_URL;
          var res = await fetch(url+'?type=delete-date&id='+thisId);
          var data = await res.json();
          var response = data.result;
          //console.dir(response);
          if(response==='remove_ok'){
            if(!this.state.memoToastStatus){
              toast.success('削除OK', {duration: 7000});
              this.setState({memoToastStatus:true});
            }
            var canYouReload = true;
            //setTimeout(function(){
            //  window.scrollTo(0, 0);
            //  window.location.reload(true);
            //}, 3000);//wait 2 seconds
          }else{
            if(!this.state.memoToastStatus){
              toast.error('あれ、エラー...', {duration: 7000});
              this.setState({memoToastStatus:true});
            }
            
          }
          return canYouReload;
        }
        var canYouReload = getDbData();
      }

      //console.log('__reload? '+String(canYouReload));
      if(canYouReload){
        setTimeout(function(){//⭐️
          window.scrollTo(0, 0);
          window.location.reload(true);
        }, 3000);//wait 2 seconds
      }

      return { data };
    });
  }

  


  //
  visibilithChange(e) {
    //console.dir(e);
    this.setState({hiddenTrashbox:true});
    if(e){

    }
  }
  visibilithChangeAp(e) {
    //console.dir(e);
    this.setState({hiddenTrashbox:true});
  }


  render() {
    //console.log('this.state.brakScreen ' +this.state.brakScreen+ 'this.state.labelDateStr '+this.state.labelDateStr + ' this.state.labelDateEndStr '+this.state.labelDateEndStr);


    var { data, currentDate, locale, currentViewName, resources } = this.state;
    //無理矢理日付フォーマットを合わせている
    if(this.state.labelDateStr!==''){
      try{
        var labelDateStr = this.state.labelDateStr;
        function test(labelDateStr){
          try{
            //console.log('CHANGE1 '+labelDateStr);
            document.getElementsByClassName('Layout-dateEditors').item(0).childNodes[0].childNodes[0].firstChild.value = labelDateStr;
            document.getElementsByClassName('MuiInputBase-inputMultiline')[0].placeholder='';
          }catch(e){}
          
        }
        window.setTimeout(function(){test(labelDateStr)}, 1000);
        
      }catch(e){}
    }

    if(this.state.brakScreen && this.state.labelDateStr!=='' && this.state.labelDateEndStr!==''){
      try{
        var breakScreen = this.state.brakScreen;
        var labelDateStr = this.state.labelDateStr;
        var labelDateEndStr = this.state.labelDateEndStr;
        function test2(breakScreen, labelDateStr, labelDateEndStr){
          try{
            //console.log('CHANGE2 '+breakScreen);
            document.getElementsByClassName('Layout-dateEditors').item(0).childNodes[2].style.setProperty('display', 'initial', 'important');
            document.getElementsByClassName('Layout-dateEditors').item(0).childNodes[0].childNodes[0].firstChild.value = labelDateStr;
            document.getElementsByClassName('Layout-dateEditors').item(0).childNodes[2].childNodes[0].firstChild.value = labelDateEndStr;
          }catch(e){}
          
        }
        window.setTimeout(function(){test2(breakScreen, labelDateStr, labelDateEndStr)}, 1000);
        
      }catch(e){}
    }

    if(this.state.hiddenTrashbox){//閉店日は編集ボタン消す
      try{
        function test3(){
          try{
            //console.log('_DEL BUTTON FUNCTION');
            var thisCellTitle = document.getElementsByClassName('Content-dateAndTitle')[0].innerText;
            if(thisCellTitle==='閉店'){
              document.getElementsByClassName('Header-flexContainer').item(0).childNodes[0].style.display='none';
            }
          }catch(e){
            console.dir(e);
          }
        }
        window.setTimeout(function(){test3()}, 1000);
      }catch(e){
        console.dir(e);
      }
      this.setState({hiddenTrashbox:false});
    }


    //
    


      window.addEventListener('touchstart', (event) => {
        //console.dir(event);
          setTimeout(function(){
            try{
              //カレンダ
              var monthLabel = document.getElementsByClassName('PrivatePickersToolbar-root').item(0).childNodes[1].childNodes[0].childNodes[1].childNodes[0].innerText;
              //console.log(monthLabel);
              if(monthLabel.indexOf('月')===-1){
                var monthText = monthLabel.split(' ')[0];
                var montheJa = functionFields.convertMonthJa(monthText);
                document.getElementsByClassName('PrivatePickersToolbar-root').item(0).childNodes[1].childNodes[0].childNodes[1].childNodes[0].innerText=montheJa;
   
              }
            }catch(e){
            }

            try{
              var monthSelectLabel = document.getElementsByClassName('PrivatePickersFadeTransitionGroup-root').item(0).childNodes[0].innerText;
              //console.log(monthSelectLabel);
              if(monthSelectLabel.indexOf('月')===-1){
                var montheJa = functionFields.convertMonthJa(monthSelectLabel);
                document.getElementsByClassName('PrivatePickersFadeTransitionGroup-root').item(0).childNodes[0].innerText=montheJa;
              }
            }catch(e){

            }

            try{
              var weeklabels = document.getElementsByClassName('MuiTypography-caption');
              //console.log(weeklabels[0].innerText);
              var checkText = weeklabels[0].innerText;
              //console.log(checkText.match(/^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/));
              if(!checkText.match(/^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/) && weeklabels[0].innerText!=='AM'){//日本語判定
                for (let i = 0; i < weeklabels.length; i++) {
                  var weekLabel = functionFields.convertWeekJa(weeklabels[i].innerText+String(i));
                  document.getElementsByClassName('MuiTypography-caption')[i].innerText = weekLabel;
                }
              }else if(weeklabels[0].innerText==='AM'){
                document.getElementsByClassName('MuiTypography-caption')[0].innerText='午前';
                document.getElementsByClassName('MuiTypography-caption')[1].innerText='午後';
              }
            }catch(e){
              //console.log(e);
            }

            try{
              document.getElementsByClassName('MuiDialog-paperScrollPaper').item(0).childNodes[1].childNodes[0].innerText='キャンセル';
            }catch(e){
              //console.log(e);
            }
          }, 600);//wait 2 seconds
          
        
      });
    

    return (
      <div className='store-schedule'>
        <React.Fragment>
          <ExternalViewSwitcher
            currentViewName={currentViewName}
            onChange={this.currentViewNameChange}
            
          />
          <Paper>
            <Scheduler
              data={data}
              locale={locale}
              height={660}
            >
              <ViewState
                defaultCurrentDate={currentDate}
                currentViewName={currentViewName}
              />
              <EditingState
                onCommitChanges={this.commitChanges}
                onEditingAppointmentChange={this.editingAppointmentChange}
                
                onAppointmentChangesChange={(e)=>this.onAppointmentChangesChange(e)}
              />
              <IntegratedEditing />
              <ConfirmationDialog />
              <DayView
                startDayHour={9}
                endDayHour={22}
              />
              <WeekView
                startDayHour={9}
                endDayHour={22}
                cellDuration={60}
              />
              <MonthView />
              <Toolbar />
              <DateNavigator />
              <Appointments />
              <AppointmentTooltip
                showOpenButton
                showDeleteButton
                onVisibilityChange={(e)=> this.visibilithChange(e)}
              />
              <AppointmentForm
                onVisibilityChangeAp={(e)=> this.visibilithChangeAp(e)}
              />
              <Resources
                data={resources}
                mainResourceName="typeId"
              />

            </Scheduler>
          </Paper>
        </React.Fragment>

        <div><Toaster position="top-right" /></div>
      </div>
      
    );
  }
}



var toDoubleDigits = function(num) {
  num += "";
  if (num.length === 1) {
    num = "0" + num;
  }
 return num;     
};
