//import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";

import TopHeader from './TopHeader';
import FloatingButton from "../components/parts/FloatingButton";
import Modal from 'react-modal';
import {functionFields} from '../components/MyFunction';
import settings from '../settings.json';
import closeImg from '../components/assets/img/close.png';

//import StoreSchedule from "./StoreSchedule";

import './assets/admin.css';
//import StoreSchedule from "./StoreSchedule";



function Top() {
  const [break_time_array, setBreakTimeArray] = useState([]);
  const [todays_customers, setTodaysCustomers] = useState([]);
  const [total_today, setTotalToday] = useState(0);
  const [total_week, setTotalWeek] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showCustomerData, setShowCustomerdata] = useState([]);


  var todayDate = new Date();
  var todayDateString = todayDate.getFullYear() + '-' + String(todayDate.getMonth()+1) + '-' +  todayDate.getDate();

  useEffect(() => {
    var url = window.location.href;
    if(url.indexOf('admin') != -1){
      //document.getElementsByClassName('header')[0].style.display = 'none';
      
    }
    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {

    }else{
      //console.log('_style_pc');
      const $style = document.createElement("link");
      $style.rel = "stylesheet";
      $style.type = "text/css";
      $style.href = "/css/pc.css";
      document.head.appendChild($style);

    }

    //
    document.getElementsByTagName('body')[0].style.borderTop = 'initial';
    document.getElementsByClassName('header')[0].style.display = 'none';

    //
    var authCookieVal = functionFields.getAuthCookieVal('__kb_user_auth');
    if(authCookieVal===''){//未認証
      window.location.href = '/google_auth';
    }


    //予約,休店時間情報
    const getDbData = async() =>{
      var url = settings.GET_NOT_RESERVE_TIME_API_URL;
      var res = await fetch(url+'?type=admin-top');
      var data = await res.json();
      var dbDataObj = JSON.parse(data.data);
      //console.dir(dbDataObj);
      var break_time_array = dbDataObj.break_time_array;
      var todays_customers = JSON.parse(dbDataObj.today_customers);
      setBreakTimeArray(break_time_array);
      setTodaysCustomers(todays_customers);
      setTotalToday(dbDataObj.total_today);
      setTotalWeek(dbDataObj.total_week);
      
    }
    getDbData();
    
  }, []);


  //console.log(total_today);
  //console.log(total_week);
  //console.log(typeof todays_customers);
  //console.log(todays_customers);
  //console.log(typeof break_time_array);
  //console.log(break_time_array);


  const showCustomer = (e) => {
    //console.log(e.target.name);
    for(var i = 0; i < todays_customers.length; i++){
      if(todays_customers[i][0]===e.target.name){
        setShowCustomerdata(todays_customers[i]);
      }
    }
    setModalIsOpen(true);
  }
  const modalStatus = () =>{
    setModalIsOpen(false);
  }

  //console.dir(showCustomerData);


  return (
    <>
      <TopHeader/>
      {/* <StoreSchedule /> */}
      <div className="admin-top">
        <h4><a href="/">まるいちのお仏壇</a></h4>
        <ul>
          <li>{todayDateString}</li>
          <li>本日の予約数　: {String(total_today)}件</li>
          <li>１週間の予約数: {String(total_week)}件</li>
          <li>
            {break_time_array!=='undefined' ? '' : ''}
            {/*本日の休時間: 10:00-12:00*/}
          </li>
        </ul>
        <hr />
        <h4>本日のお客様</h4>

        {(() => {
          //console.log(todays_customers);
          if (todays_customers.length>0) {
            return <>
              <ul>
                {todays_customers.map((item, index)=>{
                  return(
                    <li key={index}>
                      {index+1}. {item[1]}様　{item[2]}　<button name={item[0]} onClick={showCustomer} className="button-css-none">📖</button>
                    </li>
                  )
                })}
              </ul>
            </>
          } else {
            return <div>なし</div>
          }
        })()}



        
      </div>


      
      <FloatingButton />

      <Modal 
        isOpen={modalIsOpen}
        ariaHideApp={false}
        portalClassName="modal-customer-view"
      >
        <div className="close-button">
          <button onClick={modalStatus}>
            <img src={closeImg}/>
          </button>
        </div>
        <div className="admin-view-customer-data">
          <ul>
            <li><span>予約日時</span>： {showCustomerData[4]}</li>
            <li><span>お名前</span>　： {showCustomerData[1]} {showCustomerData[3]}様</li>
            <li><span>電話番号</span>： <a href={"tel:"+showCustomerData[6]}>{showCustomerData[6]}</a></li>
            <li><span>住所</span>　　： {showCustomerData[5]}</li>
            <li><span>メール</span>　： {showCustomerData[7]}</li>
            <li><span>来店方法</span>　： {showCustomerData[11]}</li>
            <li><span>メモ</span>　： {showCustomerData[10]}</li>
            <li><span>受付日時</span>： {showCustomerData[9]}</li>
          </ul>

        </div>
      </Modal>
    </>
  );
}

export default Top;
