import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import features_text from '../assets/features_text.json'
import '../common.css';
import './feature.css';
import {functionFields} from '../MyFunction';
//import bootstrap from './assets/bootstrap.css';

function AboutReserve() {

  useEffect(() => {

    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {
    
    }

  }, []);



  return (
    <div className="ItemList">
      <p className="pankuzu"><a href="/">トップ</a> ＞ ご予約について</p>
      
      <h2 className="page-h2 feature-h2">ご予約について</h2>
      <img src="https://maruichi-kyoto.com/img/92.jpg" alt={'京都の小型仏壇店まるいちの特徴:'+'ご予約について'} className="feature-img" />
      <div className="feature-text">

        <div>
          <h2 className="feature-h2 mt-3" >来店予約について</h2>
          <h3 className="feature-h3 mb-0 t-center">１．目的</h3>
          <p>・・・・</p>

          <h3 className="feature-h3 mb-0 t-center">２．予約方法</h3>
          <p>・・・・</p>

          <h3 className="feature-h3 mb-0 t-center">３．予約の変更方法</h3>
          <p>・・・・</p>

          <h3 className="feature-h3 mb-0 t-center">４．予約の取り消し方法</h3>
          <p>・・・・</p>

          <h3 className="feature-h3 mb-0 t-center">５．免責</h3>
          <p>・・・</p>

        </div>

      </div>
      
    </div>
  );
}

export default AboutReserve;
