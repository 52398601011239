import { useEffect, useState } from "react";
import { useWindowScroll } from 'react-use';
import Modal from 'react-modal';
import HamburgerMenu from "./HamburgerMenu";
import Reserve from "./Reserve";
import toast, { Toaster } from 'react-hot-toast'//https://mebee.info/2021/04/28/post-27258/

import './../common.css';
import './parts.css';
import settings from '../../settings.json';
import goTopImg from './../assets/img/30.png';

function Footer(props) {
  const {y:pageYOffset} = useWindowScroll();
  const [visible, setVisiblity] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [transition, setTransition] = useState('not-transition');

  const modalStatus = props;

  useEffect(() => {
    //console.log('footer component');
    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {
    
    }

    if(pageYOffset<100){
      setVisiblity(false);//消える
    }else{
      setVisiblity(true);//表示
    }

    if(modalIsOpen){
      document.body.style.overflow = 'hidden';//モーダル開いている時はスクロールなし
    }else{
      document.body.style.overflow = 'initial';
    }
  }, [pageYOffset, modalIsOpen]);

  if(!visible){
    if(transition==='transition'){
      setTimeout(function(){
        setTransition('not-transition');
      }, 300);//wait 1 seconds
    }
    return false;
  }else{
    if(transition==='not-transition'){//material-iconのフォントサイズが大きいのでlocal環境では一瞬ぼやける
      setTimeout(function(){
        setTransition('transition');
      }, 300);//wait 1 seconds 
    }
  }


  const goTop = (event) =>{
    window.scrollTo({
      top:0,
      behavior:'smooth'
    })
  }

  const tracking=(e)=>{
    //console.dir(e.target);
    if(e.target.innerText.indexOf('Ｗｅｂ') !== -1){
      var key = 'web';
    }else{
      var key = 'call';
    }

    const pushTrackData = async (key) => {
      var url = 'https://kyoto-butsudan.com/API/seo_counter.php?key='+key;
      const res = await fetch(
        url
      );
    };

    pushTrackData(key);
  }

  const changeStatus = (response) =>{
    console.log(response);
    setModalIsOpen(response);
  }
  const toastStatus = (response) =>{
    //console.log(response);
    if(response==='reserve_ok'){
      toast.success('予約完了', {duration: 11000});
    }else if(response==='reserve_error'){
      toast.error('予約未完了.\n先約ありです.', {duration: 7000});
    }else if(response==='no_name'){
      toast.error('予約未完了.\n苗字未入力.', {duration: 5000});
    }else if(response==='over_time'){
      var hour = settings.StoreHowManyHoursOK;
      toast.error('予約未完了.\n'+hour+'時間後以降をご選択願います.', {duration: 7000});
    }else if(response==='phone_error'){
      toast.error('予約未完了.\n電話番号エラー.', {duration: 5000});
    }else if(response==='change_ok'){
      toast.success('予約変更完了', {duration: 7000});
    }else if(response==='input_error'){
      toast.error('入力内容が正しくないようです', {duration: 5000});
    }
  }


  return (
    <>
      <link rel='stylesheet' href='https://fonts.googleapis.com/icon?family=Material+Icons' />
      <footer>
        <div className="footer">
          <div className="footer-info">
            <div className="text-box">
              <ul className="footer-info-ul">
                <li>
                  <div className="footer-li-grid">
                    <div><img src="/images/top/logo-footer.png" /></div>
                    <div>株式会社　まるいち　<span>上置仏壇専門店</span></div>
                  </div>
                </li>
                <li>本社<br />〒602-8304&nbsp;京都市上京区千本通上立売上る作庵町518</li>
                <li>仏壇ショールーム<br />〒602-8304&nbsp;京都市上京区千本通上立売下る作庵町535-4</li>
                <li>葬儀会館いほりホール<br />〒602-8475&nbsp;京都市上京区千本通五辻上る牡丹鉾町543</li>
                <li>📞：<a href='tel:075-441-6254' >（075）441-6254</a>　<a href='tel:075-431-7023' >（075）431-7023</a></li>
              </ul>
            </div>
            {/*
            <div className="img-box">
              <img src='https://maruichi-kyoto.com/img/no-img.jpg' />
            </div>
            */}
          </div>
    
          <p className="hr-title">サイトマップ</p>
          <div className="text-hr">
            <span className="text-hr__text">8つの特徴</span>
          </div>

          <div className="footer-info footer-sitemap">
            <div><a href="/features?textKey=1">小型（上置）仏壇<span>専門店</span></a></div>
            <div><a href="/features?textKey=2">選べる豊富な仏具</a></div>
            <div><a href="/features?textKey=3">仏壇・選べる仏具を<span>含んだ</span><span>明朗会計</span></a></div>

            <div><a href="/features?textKey=4">幅広い営業日・営業<span>時間</span></a></div>
            <div><a href="/features?textKey=5">完全予約制・同一時間<span>１組限定</span></a></div>
            <div><a href="/features?textKey=6">安心の自社納品・<span>飾付・</span><span>引取作業</span></a></div>
            
            <div><a href="/features?textKey=7">安心の品質表示</a></div>
            <div><a href="/features?textKey=11">葬儀社が主宰する<span>仏壇店</span></a></div>
            <div></div>
          </div>

          <div className="text-hr mt-1 mb-05">
            <span className="text-hr__text">サービス内容</span>
          </div>

          <div className="footer-info footer-sitemap">
            <div><a href="/features?textKey=8">ショールームのご紹介</a></div>
            <div><a href="/item-list">お仏壇・仏具の紹介</a></div>
            <div><a href="/features?textKey=10">各宗派の祀り方</a></div>

            <div><a href="/q-a" className="font-size-68">お仏壇についての<span>Q＆A</span></a></div>
            <div><a href="/obutsudan-owakare">仏壇処分</a></div>
            <div></div>
          </div>

          <div className="text-hr mt-1 mb-05">
            <span className="text-hr__text">当社について</span>
          </div>

          <div className="footer-info footer-sitemap">
            <div><a href="/about">会社概要</a></div>
            <div><a href="/access">アクセス</a></div>
            <div><a href="/privacy">個人情報保護方針</a></div>

            <div>{/* <a href="/about-reserve">予約について</a> */}</div>
            <div></div>
            <div></div>
          </div>

        </div>
        <div className="copyright">
          Copyright © お仏壇<span className="text-small">の</span>まるいち
        </div>
      </footer>


      <div className={"call-bar " + transition} style={{'display': 'block'}}>
        <div className="banner-grid">
          <div>
            <a className="call-text" href="tel:075-441-6254" onClick={tracking}>
              <div className="call-text-area footer-grid-child">
                {/*<HamburgerMenu/>*/}
                <div className="t-right pr-05 pt-03"><i className="material-icons icon-big">call</i></div>
                <div className="letter-sp-2 pt-03">
                  <span className="letter-sp-1">電話</span><span className="text-small">で　</span><br />来店予約
                </div>
              </div>
            </a>
          </div>
          <div className="banner-text1">
              <p className="banner-text-1 bannar-label">来店前のご予約にご協力ください</p>
              <div className="footer-grid-child" onClick={tracking}>
                <div className="t-right pr-05 pt-06"><i className="material-icons icon-big">public</i></div>
                <div className="letter-sp-2 ">
                  <div
                    className="call-text-area pt-03 banner-text-2" 
                    onClick={()=>setModalIsOpen(true)}
                  >
                    Ｗｅｂ<span className="text-small">で</span><br />来店予約
                  </div>
                </div>

              </div>
          </div>
          <div onClick={goTop} className="go-top show pt-03 mr-02" id="myBtn">
              <img src={goTopImg} />
          </div>
        </div>
      </div>


      <Modal 
        isOpen={modalIsOpen}
        ariaHideApp={false}
        portalClassName="modal-reserve"
      >
        <Reserve
          changeStatus = {changeStatus}
          toastStatus = {toastStatus}
        />
      </Modal>
      <div><Toaster position="top-right" /></div>
    </>
  );
}

export default Footer;
