import { useEffect, useState } from "react";

import Obutsudan from './Obutsudan';
import {functionFields} from '../../components/MyFunction';
import settings from '../../settings.json';


function CRUDBase() {
  var todayDate = new Date();
  var todayDateString = todayDate.getFullYear() + '-' + String(todayDate.getMonth()+1) + '-' +  todayDate.getDate();
  const [itemType, setItemType] = useState('butsudan');
  const [pageType, setPageType] = useState('');
  const [specObj, setSpecObj] = useState();
  const [textObj, setTextObj] = useState();
  const [dbObj, setDbObj] = useState({});

  useEffect(() => {
    var url = window.location.href;
    if(url.indexOf('admin') != -1){
      
    }
    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {

    }

    //
    document.getElementsByTagName('body')[0].style.borderTop = 'initial';
    document.getElementsByClassName('header')[0].style.display = 'none';

    var itemType = functionFields.getParameterByName('item');//butsudan, butsugu
    setItemType(itemType);
    var pageType = functionFields.getParameterByName('type');//new , edit
    setPageType(pageType);

    var authCookieVal = functionFields.getAuthCookieVal('__kb_user_auth');
    if(authCookieVal===''){//未認証
      window.location.href = '/google_auth';
    }

  }, []);


  if(itemType==='butsudan'){
    var title = 'お仏壇の';
    var urlKey = 'butsudan';
    var materialLabel = '主芯材';
    var materialPlaceholder = '';
  }else{
    var title = '仏具の';
    var urlKey = 'butsugu';
    var materialLabel = '素材';
    var materialPlaceholder = '';
  }


  const config_new = {
    pageType:'new',
    api: 'https://kyoto-butsudan.com/API/db_crud.php',
    title: title+'追加',
    itemType: itemType,
    reverse:'/admin',
    successMessage: 'OK',
    errorMessage: '未入力メッセージ',
    fields:{
      post_type: '',
      status: '',
      type_id: '',
      sub_type_id: '',
      name: '',
      price_zeikomi:'',
      price_zeinashi:'',
      H:'',
      W:'',
      D:'',
      color:'',
      function_1:'',
      function_2:'',
      function_3:'',
      madein:'',
      material:'',
      weight:'',
      catalog_page:'',

      dairin:'',
      toita:'',
      hyoumen:'',
    },
    fieldsConfigButsudan:  [
      { id: 1, label: '新規？編集？', fieldName: 'post_type', type: 'text',placeholder:'新規？編集？: new? edit?', isRequired: true , klassName:'first-name-field input-img-tag'},
      { id: 2, label: '公開', fieldName: 'status', type: 'radio', klassName:''},
      { id: 28, label: '非公開', fieldName: 'status', type: 'radio', klassName:''},
      { id: 3, label: '型番', fieldName: 'type_id', type: 'text', placeholder: '型番:', isRequired: false , klassName:'first-name-field'},
      { id: 4, label: '型番サブ', fieldName: 'sub_type_id', type: 'text', placeholder: '型番サブ: 13-18など', isRequired: false , klassName:'first-name-field'},
      { id: 5, label: '名前', fieldName: 'name', type: 'text', placeholder: '名前: オリオン', isRequired: true , klassName:'first-name-field'},
      { id: 101, label: '分類', fieldName: 'classification', type: 'text', placeholder: '具足、リンなど', isRequired: false , klassName:'first-name-field'},
      { id: 6, label: '税込価格', fieldName: 'price_zeikomi', type: 'text', placeholder: '税込価格:', isRequired: false , klassName:'first-name-field'},
      { id: 7, label: '税抜価格', fieldName: 'price_zeinashi', type: 'text', placeholder: '税抜価格:', isRequired: false , klassName:'first-name-field'},
      { id: 8, label: '高さ H（cm）', fieldName: 'H', type: 'number', placeholder: '高さ（H）', isRequired: false , klassName:'first-name-field'},
      { id: 9, label: '幅 W（cm）', fieldName: 'W', type: 'number', placeholder: '幅（W）', isRequired: false , klassName:'first-name-field'},
      { id: 10, label: '奥行 D（cm）', fieldName: 'D', type: 'number', placeholder: '奥行（D）', isRequired: false , klassName:'first-name-field'},
      { id: 11, label: 'カラー', fieldName: 'color', type: 'text', placeholder: 'カラー', isRequired: true , klassName:'first-name-field'},
      { id: 12, label: '機能1', fieldName: 'function_1', type: 'text', placeholder: '機能1', isRequired: false , klassName:'first-name-field'},
      { id: 13, label: '機能2', fieldName: 'function_2', type: 'text', placeholder: '機能2', isRequired: false , klassName:'first-name-field'},
      { id: 14, label: '機能3', fieldName: 'function_3', type: 'text', placeholder: '機能3', isRequired: false , klassName:'first-name-field'},
      { id: 15, label: '原産国', fieldName: 'madein', type: 'text', placeholder: '原産国: 海外', isRequired: false , klassName:'first-name-field'},

      { id: 102, label: '台輪', fieldName: 'dairin', type: 'text', placeholder: '', isRequired: false , klassName:'first-name-field'},
      { id: 103, label: '戸板', fieldName: 'toita', type: 'text', placeholder: '', isRequired: false , klassName:'first-name-field'},
      { id: 16, label: materialLabel, fieldName: 'material', type: 'text', placeholder: materialPlaceholder, isRequired: false , klassName:'first-name-field'},
      { id: 18, label: 'カタログページ', fieldName: 'catalog_page', type: 'text', placeholder: 'カタログページ: 122', isRequired: false , klassName:'first-name-field'},
      { id: 105, label: '表面仕上', fieldName: 'hyoumen', type: 'text', placeholder: '', isRequired: false , klassName:'first-name-field'},
      { id: 17, label: '重量(kg)', fieldName: 'weight', type: 'number', placeholder: '重量(kg): 18000', isRequired: false , klassName:'first-name-field'},
 
      { id: 19, label: 'ミニタイトル①', fieldName: 'mini_title_1', type: 'text', placeholder: 'ミニタイトル１', isRequired: false , klassName:'first-name-field'},
      { id: 20, label: 'テキスト①', fieldName: 'text_1', type: 'text', placeholder: 'テキスト１、一覧ページ画像下にも表示', isRequired: false , klassName:'first-name-field'},
      { id: 22, label: 'ミニタイトル②', fieldName: 'mini_title_2', type: 'text', placeholder: 'ミニタイトル2', isRequired: false , klassName:'first-name-field'},
      { id: 23, label: 'テキスト②', fieldName: 'text_2', type: 'text', placeholder: 'テキスト2', isRequired: false , klassName:'first-name-field'},
      { id: 25, label: 'ミニタイトル③', fieldName: 'mini_title_3', type: 'text', placeholder: 'ミニタイトル3', isRequired: false , klassName:'first-name-field'},
      { id: 26, label: 'テキスト③', fieldName: 'text_3', type: 'text', placeholder: 'テキスト3', isRequired: false , klassName:'first-name-field'},
      { id: 27, label: '画像', fieldName: 'img', type: 'file', placeholder: 'img3のファイル名', isRequired: false },
    ]
  }

  console.log(pageType);
  if(pageType==='edit'){
    var config_edit = {
      pageType:'edit',
      api: 'https://kyoto-butsudan.com/API/db_crud.php',
      title: title+'編集',
      itemType: itemType,
      reverse:'/list-item?type='+urlKey,
      successMessage: 'OK',
      errorMessage: '未入力メッセージ',
      fieldsConfigButsudan:  [
        { id: 1, label: '新規？編集？', fieldName: 'post_type', type: 'text',placeholder:'新規？編集？: new? edit?', isRequired: true , klassName:'first-name-field input-img-tag'},
        { id: 2, label: '公開', fieldName: 'status', type: 'radio', klassName:''},
        { id: 28, label: '非公開', fieldName: 'status', type: 'radio', klassName:''},
        { id: 3, label: '型番', fieldName: 'type_id', type: 'text', placeholder: '型番:', isRequired: false , klassName:'first-name-field'},
        { id: 4, label: '型番サブ', fieldName: 'sub_type_id', type: 'text', placeholder: '型番サブ: 13-18など', isRequired: false , klassName:'first-name-field'},
        { id: 5, label: '名前', fieldName: 'name', type: 'text', placeholder: '名前: オリオン', isRequired: true , klassName:'first-name-field'},
        { id: 101, label: '分類', fieldName: 'classification', type: 'text', placeholder: '具足、リンなど', isRequired: false , klassName:'first-name-field'},
        { id: 6, label: '税込価格', fieldName: 'price_zeikomi', type: 'text', placeholder: '税込価格:', isRequired: false , klassName:'first-name-field'},
        { id: 7, label: '税抜価格', fieldName: 'price_zeinashi', type: 'text', placeholder: '税抜価格:', isRequired: false , klassName:'first-name-field'},
        { id: 8, label: '高さ H（cm）', fieldName: 'H', type: 'number', placeholder: '高さ（H）', isRequired: false , klassName:'first-name-field'},
        { id: 9, label: '幅 W（cm）', fieldName: 'W', type: 'number', placeholder: '幅（W）', isRequired: false , klassName:'first-name-field'},
        { id: 10, label: '奥行 D（cm）', fieldName: 'D', type: 'number', placeholder: '奥行（D）', isRequired: false , klassName:'first-name-field'},
        { id: 11, label: 'カラー', fieldName: 'color', type: 'text', placeholder: 'カラー', isRequired: true , klassName:'first-name-field'},
        { id: 12, label: '機能1', fieldName: 'function_1', type: 'text', placeholder: '機能1', isRequired: false , klassName:'first-name-field'},
        { id: 13, label: '機能2', fieldName: 'function_2', type: 'text', placeholder: '機能2', isRequired: false , klassName:'first-name-field'},
        { id: 14, label: '機能3', fieldName: 'function_3', type: 'text', placeholder: '機能3', isRequired: false , klassName:'first-name-field'},
        { id: 15, label: '原産国', fieldName: 'madein', type: 'text', placeholder: '原産国: 海外', isRequired: false , klassName:'first-name-field'},

        { id: 102, label: '台輪', fieldName: 'dairin', type: 'text', placeholder: '', isRequired: false , klassName:'first-name-field'},
        { id: 103, label: '戸板', fieldName: 'toita', type: 'text', placeholder: '', isRequired: false , klassName:'first-name-field'},
        { id: 16, label: materialLabel, fieldName: 'material', type: 'text', placeholder: materialPlaceholder, isRequired: false , klassName:'first-name-field'},
        { id: 18, label: 'カタログページ', fieldName: 'catalog_page', type: 'text', placeholder: 'カタログページ: 122', isRequired: false , klassName:'first-name-field'},
        { id: 105, label: '表面仕上', fieldName: 'hyoumen', type: 'text', placeholder: '', isRequired: false , klassName:'first-name-field'},
        { id: 17, label: '重量(kg)', fieldName: 'weight', type: 'number', placeholder: '重量(kg): 18000', isRequired: false , klassName:'first-name-field'},
   
        { id: 19, label: 'ミニタイトル①', fieldName: 'mini_title_1', type: 'text', placeholder: 'ミニタイトル１', isRequired: false , klassName:'first-name-field'},
        { id: 20, label: 'テキスト①', fieldName: 'text_1', type: 'text', placeholder: 'テキスト１、一覧ページ画像下にも表示', isRequired: false , klassName:'first-name-field'},
        { id: 22, label: 'ミニタイトル②', fieldName: 'mini_title_2', type: 'text', placeholder: 'ミニタイトル2', isRequired: false , klassName:'first-name-field'},
        { id: 23, label: 'テキスト②', fieldName: 'text_2', type: 'text', placeholder: 'テキスト2', isRequired: false , klassName:'first-name-field'},
        { id: 25, label: 'ミニタイトル③', fieldName: 'mini_title_3', type: 'text', placeholder: 'ミニタイトル3', isRequired: false , klassName:'first-name-field'},
        { id: 26, label: 'テキスト③', fieldName: 'text_3', type: 'text', placeholder: 'テキスト3', isRequired: false , klassName:'first-name-field'},
        { id: 27, label: '画像', fieldName: 'img', type: 'file', placeholder: 'img3のファイル名', isRequired: false },

      ]
    }
  }else{
    var config_edit = {};
  }



  return (
    <>
      
      {(() => {
        if (pageType==='new') {
          return <> <Obutsudan config={config_new} /> </>
        }else if(pageType==='edit'){
          return <> <Obutsudan config={config_edit} /> </>
        }
      })()}
      
    </>
  );
}

export default CRUDBase;
