import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import parse from 'html-react-parser';
import Header from './../parts/Header';
import features_text from '../assets/features_text.json'
import '../common.css';
import './feature.css';
import {functionFields} from '../MyFunction';
import {Helmet} from 'react-helmet';
import settings from '../../settings.json';
//import bootstrap from './assets/bootstrap.css';

function QA() {
  const [seoTitle, setSeoTitle] = useState('');
  const [seoDescri, setSeoDescri] = useState('');

  useEffect(() => {

    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {
    
    }else{
      //console.log('_style_pc');
      const $style = document.createElement("link");
      $style.rel = "stylesheet";
      $style.type = "text/css";
      $style.href = "/css/pc.css";
      document.head.appendChild($style);
    }

    setSeoTitle("（株）まるいちの仏壇 Q&A/"+settings.TITLE);
    setSeoDescri("Q&Aのページ/"+settings.DESCRIPTION);

  }, []);



  return (
  <>
    <Helmet>
      <title>{seoTitle}</title>
      <meta
        name="description"
        content={seoDescri}
      />
    </Helmet>

    <Header />

    <div className="ItemList">
      <p className="pankuzu"><a href="/">トップ</a> ＞ お仏壇についてのQ&A</p>
      
      <h2 className="page-h2 feature-h2">お仏壇についてのQ&A</h2>
      <img src="https://maruichi-kyoto.com/img/92.jpg" alt={'京都の小型仏壇店まるいちの特徴:'+'Q&A'} className="feature-img" />
      <div className="feature-text">
        <div className="mokuji">
          <h3 className="t-center">Q&A一覧</h3>
          <ol>
            <li><a href="#q1">お仏壇をお祀りする意味はなんですか？</a></li>
            <li><a href="#q2">お仏壇は必ず買わなければならないのですか？</a></li>
            <li><a href="#q3">お仏壇は高価なものから安価なものまであるけどなぜ？</a></li>
            <li><a href="#q4">お仏壇が70%OFFという広告がきたけど,本当？</a></li>
            <li><a href="#q5">お仏壇はいつごろ購入すればいいのですか？</a></li>
            <li><a href="#q6">和室がなくてもお仏壇を置ける？</a></li>
            <li><a href="#q7">お仏壇を選ぶポイントは？</a></li>
            <li><a href="#q8">お仏壇を設置する場所に決まりはある？</a></li>
            <li><a href="#q9">お仏壇はどんな形がありますか？</a></li>
            <li><a href="#q10">お仏壇の色の違いは？</a></li>
            <li><a href="#q11">お仏壇購入の予算は？</a></li>
            <li><a href="#q12">インターネットでもお仏壇が売られていますが、お店で見たほうがいい？</a></li>
            <li><a href="#q13">お仏壇を購入した後は、どうすればいい？</a></li>
            <li><a href="#q14">お仏壇を買い換えたいのですが、古いお仏壇はどうしたらいいでしょうか？</a></li>
            <li><a href="#q15">お仏壇のお手入れ方法を教えてください。</a></li>
            <li><a href="#q16">葬儀で使用したお位牌とは別にお位牌を用意するの？</a></li>
            <li><a href="#q17">お位牌は生前に作っても大丈夫ですか？</a></li>
            <li><a href="#q18">浄土真宗ですが、お位牌を作ってもよい？</a></li>
            <li><a href="#q19">お位牌はお仏壇のどこに祀ればいいのですか？</a></li>
            <li><a href="#q20">無宗派で戒名がないのですが、お位牌はどうすればいいのですか？</a></li>
            <li><a href="#q21">仏具は宗派によって異なるのでしょうか？</a></li>
            <li><a href="#q22">仏具を購入する際に表示されている三具足・五具足とは？</a></li>
            <li><a href="#q23">車でお店に行きたいのですが止める場所はありますか？</a></li>
            <li><a href="#q24">高額なものばかりすすめられるのではと、なかなかお店にいけないのですが？</a></li>
          </ol>
        </div>

        <div>
            <h2 className="feature-h2 mt-3" id="q1">お仏壇をお祀りする意味はなんですか？</h2>
            <p>もともとお仏壇は自宅におけるお寺として各家庭にあり、仏様を祀るものでした。しかし現在では、本尊を祀ることよりも、大切だった故人やご先祖様を祀るためにお仏壇を用意することが多くなりました。</p>
            <p>お仏壇に毎日手を合わせるということは、大切だった故人と語らい、生きるあなたや家族の無病息災を祈るということです。</p>

            <h2 className="feature-h2 mt-3" id="q2">お仏壇は必ず買わなければならないのですか？</h2>
            <p>マンションなどお仏壇を置くスペースがないのでお仏壇がないご家庭も増えていますが、大切だった故人やご先祖様を祀るため購入される方も多くいらっしゃいます。</p>
            <p>最近では省スペースで設置可能なタイプや、洋室和室と置く場所を選ばない家具調のお仏壇などもあります。</p>
            <p>ご自宅の設置環境に応じたお仏壇を選び、祈りの場を設けてみてはいかがでしょうか。</p>

            <h2 className="feature-h2 mt-3" id="q3">お仏壇は高価なものから安価なものまであるけどなぜ？</h2>
            <p>簡単に申しますと、家具にもカラーボックスのように数千円で購入できるものから、数十万円するサイドボードまで様々な価格帯の商品がございます。</p>
            <p>お仏壇の場合、使用する材料の価値の違いや彫刻などの細工によって価格が大幅に変わってきます。</p>
            <p>しかし、それらの違いは一般的ではないため、多くの方に知られていないのが現状です。</p>
            <p>優良な仏壇店では販売員がご説明しますが、これらを説明せずに販売しようとするお店もございます。</p>
            <p>使用する材料の材質や品質の違いについては当HPにて詳しく説明しておりますので、そちらを参考にしてみてください。</p>

            <h2 className="feature-h2 mt-3" id="q4">お仏壇が70％OFFという広告が来たけど、本当ですか？</h2>
            <p>仏壇業界では一時、二重価格による販売合戦が盛んに行われてきました。</p>
            <p>その名残で、現在も一部の悪質な業者が広告などで二重表示を行っているのでしょう。</p>
            <p>お仏壇はメーカー型番などもあまりなく、同じ商品を探すことも難しいという特性を悪用し、もともと３０万円の商品を１００万円の７０％OFFと表示しているのでしょう。</p>
           

            <h2 className="feature-h2 mt-3" id="q5">お仏壇はいつごろ購入すればいいのですか？</h2>
            <p>お仏壇を購入しなければならない明確な時期はございません。</p>
            <p>ご不幸があり、お仏壇の購入を考えていらっしゃる方は、本位牌と合わせて四十九日の法要までにご用意するといいでしょう。</p>
            <p>それ以外に家の新築時、年回忌にお求めのかたも多くいらっしゃいます。</p>
            <p>特に家を新築される場合、先にお仏壇を決めてからその大きさにあわせて仏間を設けるほうがよいでしょう。</p>

            <h2 className="feature-h2 mt-3" id="q6">和室がなくてもお仏壇を置くことができますか？</h2>
            <p>近年、住環境の変化に伴い、和室・洋室どちらにおいても違和感がない”家具調のお仏壇”が登場しました。</p>
            <p>これらは、色の種類やサイズも豊富なため、設置場所の雰囲気に合わせたお仏壇を選ぶことができます。</p>

            <h2 className="feature-h2 mt-3" id="q7">お仏壇を選ぶポイントは？</h2>
            <p>お仏壇は、設置すると毎日お参りする場所として家の一部となります。</p>
            <p>そのため、まずはお参りがしやすいお仏壇の設置位置を定め、お部屋の雰囲気にあった色・形のものをお探しください。</p>
            <p>お仏壇の購入を考えている方は下記の１～６を行うことで、後悔しない仏壇選びができます。</p>
            <ol className="pl-1 t-small">
                <li>お仏壇の設置場所を決め、設置場所のサイズを計る</li>
                <li>宗派を確認する</li>
                <li>設置場所にあったお仏壇の種類を知っておく</li>
                <li>お部屋や家具に合わせたお仏壇の色合いを考える</li>
                <li>予算をたてる</li>
                <li>仏壇店を数店見てまわる</li>
            </ol>

            <h2 className="feature-h2 mt-3" id="q8">お仏壇を設置する場所に決まりはあるのですか？</h2>
            <p>仏間があれば仏間に設置するのが一番ですが、仏間がなくても床の間、押し入れを改造して、タンスなどの家具の上など、お参りがしやすい場所であれば、お仏壇はどこに設置しても構いません。</p>
            <p>ただし、直射日光が当たる場所、湿気の多い場所、冷暖房が直接当たる場所に設置するとお仏壇の傷みが早まりますので避けてください。</p>

            <h2 className="feature-h2 mt-3" id="q9">お仏壇はどんな形がありますか？</h2>
            <p>お仏壇は大きく分類すると、</p>
            <ul className="pl-1 t-small">
                <li>黒塗りに内側が金箔貼りになった『金仏壇』</li>
                <li>木目が美しい昔ながらのお仏壇『唐木仏壇』</li>
                <li>現代住居に対応した洋間でも違和感がない『家具調仏壇』</li>
            </ul>
            <p>の３種類に分けられます。</p>
            <p>さらにそれぞれ床に直接設置できる台付タイプと、家具などの上に設置する上置きタイプの商品があります。</p>
            <p>設置場所のサイズや雰囲気から最適なお仏壇をお選びください。</p>

            <h2 className="feature-h2 mt-3" id="q10">お仏壇の色の違いは？</h2>
            <p>お仏壇の色の違いは、”塗りの違い”または”使用木材の違い”によるものです。</p>
            <p>金仏壇は漆によって黒塗りに仕上げられておりますが、木目をいかした唐木仏壇では使用している木材の色味でお仏壇の色も変わってきます。</p>
            <p>（例）</p>
            <ul className="pl-1 t-small">
                <li>黒檀系⇨黒い縞模様</li>
                <li>紫檀系⇨赤みを帯びた木肌、赤褐色・濃紫色・黒色など</li>
                <li>ウォールナット系⇨黄褐色系</li>
            </ul>

            <h2 className="feature-h2 mt-3" id="q11">お仏壇購入の予算は？</h2>
            <p>お仏壇購入の予算は、いくら位までなら出せる、というざっくりとした目安をもって行いましょう。</p>
            <p>あまり●円以下と限定して探すと、良いもの悪いものを見極めることもできなくなってしまうでしょう。</p>
            <p>まずは漠然と商品をみて、気に入ったものを選びそのなかから、販売員に相談し、気に入ったもの、予算を伝え、予算内に収まる商品の提案を受けましょう。</p>

            <h2 className="feature-h2 mt-3" id="q12">インターネットでもお仏壇が売られていますが、お店で見たほうがいい？</h2>
            <p>お仏壇は一生もので代々受け継いでいくこともできるものです。</p>
            <p>お店に行く時間がない場合や、間に合わせでなんでもいいという場合はインターネットで購入するのもいいでしょう。</p>
            <p>しかし、写真ではおなじように見えるお仏壇も実際、目の前に座ってみると、その質感や存在感など、それぞれお仏壇によって違います。</p>
            <p>お仏壇は毎日手を合わせるところです。お参りするご自身がしっくりくるお仏壇があなたにピッタリのお仏壇なのです。</p>
            <p>ですので、お仏壇を購入される際は複数店舗を回り、実際見てから購入することをお勧めします。</p>

            <h2 className="feature-h2 mt-3" id="q13">お仏壇を購入した後は、どうすればいい？</h2>
            <p>お仏壇をご購入いただき、設置が完了した状態は、まだお仏壇に魂が入っておらず、ただの家具と同じです。</p>
            <p>葬儀を行った僧侶、お墓を構えたお寺の僧侶をお呼びして、開眼法要を行っていただく必要がございます。</p>
            <p>開眼法要を行ってはじめて、仏壇や本尊が冷厳ある尊像になります。</p>
            <p>また、近年では、自宅に僧侶を招かずにご本尊をお寺にお持ちし、開眼法要を行っていただくケースも増えております。</p>

            <h2 className="feature-h2 mt-3" id="q14">お仏壇を買い換えたいのですが、古いお仏壇はどうしたらいいでしょうか？</h2>
            <p>お仏壇を処分するには、『閉眼供養・魂抜き』と呼ばれる法要が必要になります。</p>
            <p>㈱まるいちでも、古い仏壇の引取作業を行っています。</p>

            <h2 className="feature-h2 mt-3" id="q15">お仏壇のお手入れ方法を教えてください。</h2>
            <p>基本的にはやわらかい布での乾拭きのお手入れを行ってください。</p>
            <p>ひどく汚れがついてしまった場合は水拭きも可能ですが、必ず仕上げに乾拭きを行い、水分を取り除くようにしてください。</p>

            <h2 className="feature-h2 mt-3" id="q16">葬儀で使用したお位牌とは別にお位牌を用意するの？</h2>
            <p>葬儀で使用する白位牌は仮のお位牌です。</p>
            <p>四十九日法要までにご自身でお位牌をご用意いただく必要がございます。</p>
            <p>また、お位牌は注文をいただいた後、文字を入れるのに２週間ほどかかりますので、お早目に手配したほうがよいでしょう。</p>

            <h2 className="feature-h2 mt-3" id="q17">お位牌は生前に作っても大丈夫ですか？</h2>
            <p>生前にお位牌を作ることは縁起が悪いのではと思われがちですが、そうではありません。</p>
            <p>最愛の連れ合いを亡くした方が『夫婦で同じお位牌に入りたい』と想われる方も多いのではないでしょうか？</p>
            <p>亡くなられた方の戒名を授かる際、お寺様にお願いしご自身の”生前戒名”を授かることにより夫婦連名でのお位牌を作ることが可能になります。</p>
            <p>お亡くなりになられた方の戒名は金文字で、生前戒名には朱文字で入れます。</p>
            <p>このように、生前に作るお位牌を『逆修牌』といい、縁起が良く功徳も高いと言われています。</p>
            <p>なお、生前にお位牌を作るためには戒名を授からなければなりませんので、生前戒名について菩提寺などに相談しましょう。</p>

            <h2 className="feature-h2 mt-3" id="q18">浄土真宗ですが、お位牌を作ってもよいですか？</h2>
            <p>通常亡くなった方は極楽浄土に行くためのお姿（位牌）で50回忌までは修行をするという教えのため、故人のお位牌を作ります。</p>
            <p>一方で、浄土真宗では、亡くなるとすぐに極楽浄土へ行くという教えのため、極楽浄土に行くまでの修行のお姿であるお位牌は必要なく、過去帳や法名軸を用います。</p>
            <p>しかし、近年では手を合わせる際に『お位牌がないと寂しい』という方も多く、お位牌を作られる方も増えておりますので、詳しくはお寺様にご相談されることをお勧めします。</p>

            <h2 className="feature-h2 mt-3" id="q19">お位牌はお仏壇のどこに祀ればいいのですか？</h2>
            <p>比較的、内陣に余裕のあるお仏壇をお持ちの方は、ご本尊の下の段か、上段であればご本尊の脇にお位牌を置きましょう。</p>
            <p>その際、お位牌の高さができるだけご本尊の目線より低くなるよう設置してください。</p>
            <p>お仏壇内部のスペースに余裕がない場合は、光背を含めたご本尊様の大きさより低くなるようお位牌を作り、ご本尊様の脇に安置すればよいでしょう。</p>

            <h2 className="feature-h2 mt-3" id="q20">無宗派で戒名がないのですが、お位牌はどうすればいいのですか？</h2>
            <p>故人が無宗派の場合、戒名を授かっていない場合は、位牌表面には『●●●●（故人のお名前）之霊位』とするのが一般的です。</p>

            <h2 className="feature-h2 mt-3" id="q21">仏具は宗派によって異なるのでしょうか？</h2>
            <p>お祀りするご本尊・仏具の荘厳は各宗派によって異なります。</p>
            <p>当店では、お仏壇をご購入いただく際に、宗派にあわせてご提案させていただいておりますが、セットで購入した場合など他宗派のものが付属していることもありますので、確認が必要です。</p>

            <h2 className="feature-h2 mt-3" id="q22">仏具を購入する際に表示されている三具足・五具足とは？</h2>
            <p>具足とは仏具の呼称です。</p>
            <p>三具足は香炉・燭台・花立の各１つずつのセット、五具足とは香炉１つ、燭台・花立が１対の5商品のセット。</p>
            <p>それぞれ宗派によりお飾りするものが変わるので、購入する際は注意が必要です。</p>

            <h2 className="feature-h2 mt-3" id="q23">車でお店に行きたいのですが止める場所はありますか？</h2>
            <p>㈱まるいちには約２０台の駐車場がありますので、お車でのご来店も可能です。</p>
            <p>駐車場が混雑する場合もございますので、あらかじめご了承ください。</p>

            <h2 className="feature-h2 mt-3" id="q24">高額なものばかりすすめられるのではと、なかなかお店にいけないのですが？</h2>
            <p>㈱まるいちではお客様のご要望・ご予算を元に最適なお仏壇を提案させていただきます。</p>
            <p>はじめてのお客様でも安心してご相談いただけます。</p>
        </div>

      </div>
      
    </div>
  </>
  );
}

export default QA;
