// 参考記事　https://cpoint-lab.co.jp/article/202001/13526/
import React, { Component, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker/dist/es"
import {CalendarContainer} from "./Styles";
import settings from '../../settings.json';
import "react-datepicker/dist/react-datepicker.css";
import ja from 'date-fns/locale/ja';
import isSameDay from 'date-fns/isSameDay';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import addDays from 'date-fns/addDays';
import './parts.css';
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import _ from 'lodash'
import { functionFields } from "../MyFunction";


export default class Calender extends Component{
  constructor(props) {
    //var year = String(new Date().getFullYear());
    //var calenderFixMonth = new Date(year, "11");
    var todayDate = new Date();
    var calenderFixMonth = new Date(
          String(todayDate.getFullYear()), 
          String(todayDate.getMonth()), 
          String(todayDate.getDate()) 
        );

    super(props);
    this.state = {
      calenderPosition:'',
      day: calenderFixMonth,
      //Day:'',
      Day:this.props.Date,
      allExcludeTimes:[],
      excludeTimes:'',
      isOpend:false,
      date:setHours(setMinutes(new Date(), 30), 16),
      holidays:[],
      pageType:'customer',
      mobile:true,
    }
    this.getFormatDate = this.getFormatDate.bind(this);
  }

  componentDidMount () {
    if (navigator.userAgent.match(/iPhone|Android/)) {
      //this.setState({calenderPosition:'top-end'});
      this.setState({calenderPosition:'bottom-end'});
    } else {
      this.setState({calenderPosition:'bottom-end'});
      this.setState({mobile:false});
    }
    //console.log(setHours(setMinutes(new Date(), 30), 15));//明日の13:00

    const thisUrl = window.location.pathname;
    var isCustomerView = functionFields.checkFooterShow(thisUrl);
    if(!isCustomerView){
      this.setState({pageType:'admin'});
    }


    //予約済み,休店
    var url = settings.GET_NOT_RESERVE_TIME_API_URL;
    const getDBData = async () => {
      const res = await fetch(url+'?type=calender');
      const data = await res.json();
      var dateDbArray = [];
      try{
        var dateArray = JSON.parse(data.data);
        //console.dir( dateArray);
        for(var i = 0; i < dateArray.length; i++){
          var dateObjArray = functionFields.convertdate('str-obj-full', dateArray[i]);
          //console.log(dateObjArray);
          dateDbArray.push(dateObjArray[0]);
          if(dateObjArray[1]!==''){
            dateDbArray.push(dateObjArray[1]);
          }
        }
      }catch(e){
        console.log('予約なし');
      }

      if(isCustomerView){
        var limitTime = new Date();
        var limitTime = limitTime.setHours(16);
        var limitTime = new Date( limitTime );
        var limitTime = limitTime.setMinutes(59);//17時以降受付ない
        var limitTime = new Date( limitTime );
        var limitTime = limitTime.setSeconds(59);//17時以降受付ない
        var limitTime = new Date( limitTime );
        let nowTime = new Date();
        if(limitTime<nowTime){
          console.log('受付停止');
          var i = 0;
          while(i<4){
            var addTime = nowTime.setHours(17+i);
            var addTime = new Date(addTime);
            var addTime = addTime.setMinutes(0);
            var addTimeObj = new Date(addTime);
            dateDbArray.push(addTimeObj);
            i+=1;
          }
        }
      }


      this.setState({allExcludeTimes:dateDbArray});
      this.setState({excludeTimes:dateDbArray});

      //var spec_obj = JSON.parse(responseObj['spec_obj_string']);
      //this.setState({ spec_obj:spec_obj});

    };
    getDBData();


    //ページ読み込み時予約読み込み
    //const getExcludeTimesForDate = (date) =>
    //  this.state.allExcludeTimes.filter((time) => isSameDay(date, time));
    //this.setState({excludeTimes:getExcludeTimesForDate(new Date())} );

    //営業時間外を非表示に
    var startTime = settings.startTime;//開店時間 10時
    var endTime = settings.endTime;//閉店時間 20時、19時まで受付
    const $style = document.createElement("style");
    document.head.appendChild($style);
    $style.innerHTML = `
      .react-datepicker__time-list-item:nth-of-type(-n+`+String(startTime)+`),
      .react-datepicker__time-list-item:nth-of-type(n+`+String(endTime+1)+`){
        display:none;
      }
      `;


    //休み
    var url = settings.GET_NOT_RESERVE_TIME_API_URL;
    const getDBData2 = async () => {
      const res = await fetch(url+'?type=get_holidays');
      const data = await res.json();
      var dateDbArray = [];
      try{
        var dateArray = data.data;
        //console.dir( dateArray);
        for(var i = 0; i < dateArray.length; i++){
          var thisDateStr = dateArray[i];//2022-05-10 00:00:00 safari invailed
          var thisDateStr = thisDateStr.split(' ')[0];
          var dateObj = new Date(thisDateStr);
          dateObj.setHours(0);
          dateObj.setMinutes(0);
          dateDbArray.push(dateObj);
        }
      }catch(e){
        console.log('予約なし');
      }
      this.setState({holidays:dateDbArray});
      //console.dir(dateDbArray);

    };
    getDBData2();
    //var holidaysArray = [addDays(new Date(), 2)];
    //this.setState({holidays:holidaysArray});
    //console.log('DID MOUNT END');
  }



  getFormatDate(date) {
    return date.getFullYear() + "年" + (date.getMonth() + 1) + "月" +  date.getDate() + "日" + ' ' + this.state.day.getHours() + '時';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.day!==this.state.day){
      const dateText = this.state.day.getFullYear() + "年" + (this.state.day.getMonth() + 1) + "月" + this.state.day.getDate() + "日" + ' ' + this.state.day.getHours() + '時';
      this.setState({Day:dateText});
    }
    if(prevState.Day!==this.state.Day){
      this.props.changeDayFunction(this.state.Day);
    }


  }
  



  
  render(){
    //console.dir(this.state.allExcludeTimes);
    //console.dir(this.state.excludeTimes);

    registerLocale('ja', ja);
    const years = _.range(2021, getYear(new Date()) + 2, 1)
    const months = Array.from(Array(12).keys())

    //月は +1
    //const holidays = [
    //  addDays(new Date(), 1)
    //];


    //予約時間の反映用
    const getExcludeTimesForDate = (date) => this.state.allExcludeTimes.filter((time) => isSameDay(date, time));

    const MyContainer = ({ className, children }) => {
      return (
        <div style={{background: "#216ba5", color: "#fff" }}>
          <CalendarContainer className={className}>
            <div style={{ position: "relative" }}>{children}</div>
          </CalendarContainer>
        </div>
      );
    };

    
    const tapCalender = ()=>{
      //console.log('TAP');
      if(this.state.mobile){
        document.getElementsByClassName('react-datepicker-wrapper')[0].style.right = 'initial';
        //document.getElementsByClassName('form-input-row')[0].style.display = 'none';//名前とかの入力フォームを非表示に
        document.getElementsByClassName('form-input-row')[1].style.display = 'none';//住所
        document.getElementsByClassName('form-input-row')[2].style.display = 'none';//電話
        document.getElementsByClassName('form-input-row')[3].style.display = 'none';//メール
        //document.getElementsByClassName('form-input-row')[4].style.display = 'none';
      }
      document.getElementsByClassName('date-picker-button-hidden')[0].click();
    }

    const calenderChange = (date) =>{
      this.setState({day: date});
      console.log(date.getHours());
      var thisHour = date.getHours();//Number
      if(thisHour===0){
        var setVal = functionFields.convertdate('obj-str-notime', date);
        console.log(setVal);
        setTimeout(function(){
          document.getElementsByName('calender')[0].value = setVal;
        }, 50);//wait 2 seconds
        
      }
    }

    const handleCalendarClose = () => {
      //console.log("Calendar closed");
      if(this.state.mobile){
        document.getElementsByClassName('react-datepicker-wrapper')[0].style.right = '0';
        //document.getElementsByClassName('form-input-row')[0].style.display = 'flex';//名前とかの入力フォームを非表示に
        document.getElementsByClassName('form-input-row')[1].style.display = 'flex';
        document.getElementsByClassName('form-input-row')[2].style.display = 'flex';
        document.getElementsByClassName('form-input-row')[3].style.display = 'flex';
        //document.getElementsByClassName('form-input-row')[4].style.display = 'flex';
        document.getElementById("calender-close-outside").style.display='none';
        if(this.state.pageType==='admin' && window.location.pathname!=='/reserve'){
          document.getElementById("fab1").style.display='flex';
          var i = 0;
          while(i<6){
            document.getElementsByClassName("fab")[i].style.display='flex';
            i+=1;
          }
        }
      }

    }

    const handleCalendarOpen = () => {
      //console.log('open');
      if(this.state.mobile){
        document.getElementById("calender-close-outside").style.display='initial';
      }
      
      if(this.state.pageType==='admin' && window.location.pathname!=='/reserve'){
        document.getElementById("fab1").style.display='none';
        var i = 0;
        while(i<6){
          document.getElementsByClassName("fab")[i].style.display='none';
          i+=1;
        }
      }

      document.getElementsByClassName("react-datepicker__day--today")[0].click();

    }

      
    return(
      <>
        <div onClick={tapCalender} >
          <input 
            type="text" 
            name="calender"
            id="calender"
            value={this.state.Day}
            className="date-picker-button"
          />
          <DatePicker
            locale="ja"
            dateFormat="yyyy.MM.dd aa h:mm"
            popperPlacement={this.state.calenderPosition}
            selected={this.state.day}
            className="date-picker-button-hidden"
            onCalendarOpen={handleCalendarOpen}
            onCalendarClose={handleCalendarClose}
            onChange={calenderChange}
            //customInput={<button>{this.getFormatDate(this.state.day)}</button>} 

            showTimeSelect
            minTime={setHours(setMinutes(new Date(), 0), settings.startTime)}
            maxTime={setHours(setMinutes(new Date(), 31), settings.endTime+1)}
            timeFormat="HH:mm"
            timeIntervals={60}
            timeCaption="時間"
            //excludeTimes={reserved}
            excludeTimes={this.state.excludeTimes}
            //excludeTimes={this.state.allExcludeTimes}
            excludeDates={this.state.holidays}
            onSelect={(date) => {
              this.setState({excludeTimes:getExcludeTimesForDate(date)} );
              //console.log(date);
            }}
            

            //calendarContainer={MyContainer}//オリジナルのカレンダーレイアウトなど

            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled
            }) => (
              <div>
                <select
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value)}
                  className="calender-select"
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}年
                    </option>
                  ))}
                </select>
                <select
                  value={getMonth(date)}
                  onChange={({ target: { value } }) => changeMonth(value)}
                  className="calender-select"
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option + 1}月
                    </option>
                  ))}
                </select>
              </div>
            )}

          />
        </div>
      </>
    );
  };
}
  