import { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast'
import Modal from 'react-modal';
import Reserve from "./Reserve";
import Calender2 from "./Calender2";
import settings from '../../settings.json';
import './float-button.css';
import closeImg from '../assets/img/close.png';

function FloatingButton() {
  const [showIconLabel, setShowIconLabel] = useState('none');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen_2, setModalIsOpen_2] = useState(false);
  const [breaktime_1, setBreakTime_1] = useState({});
  const [breaktime_2, setBreakTime_2] = useState({});

  const showEvent = () => {
    let fab1 = document.getElementById('fab1');
    let innerFabs = document.getElementsByClassName('inner-fabs')[0];
    innerFabs.classList.toggle('show');
    if(showIconLabel==='none'){
      //console.log('SHOW');
      var labelClases = document.getElementsByClassName('icon-label');
      for(var i=0; i<labelClases.length; i++){
        labelClases[i].style.display = 'initial';
      }
      document.getElementById("overlay").style.display = "block";
      //
      setShowIconLabel('show');
    }else{
      //console.log('NONE');
      //document.getElementsByClassName('icon-label')[0].style.display='none';
      var labelClases = document.getElementsByClassName('icon-label');
      for(var i=0; i<labelClases.length; i++){
        labelClases[i].style.display = 'none';
      }

      document.getElementById("overlay").style.display = "none";
      setShowIconLabel('none');
    }
  }

  useEffect(() => {
    //console.log('footer component');
    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {
    
    }
    
  }, []);



  const showCustomer = () => {
    document.getElementById('fab1').click();
    setModalIsOpen(true);
  }

  const showBreakTime = () => {
    document.getElementById('fab1').click();
    setModalIsOpen_2(true);
  }
  const modalStatus = () =>{
    setModalIsOpen_2(false);
  }

  const changeStatus = (response) =>{
    //console.log(response);
    setModalIsOpen(response);
  }
  const toastStatus = (response) =>{
    //console.log(response);
    if(response==='reserve_ok'){
      toast.success('予約完了', {duration: 11000});
    }else if(response==='no_name'){
      toast.error('予約未完了.\n苗字未入力.', {duration: 5000});
    }else if(response==='over_time'){
      var hour = settings.StoreHowManyHoursOK;
      toast.error('予約未完了.\n'+hour+'時間後以降をご選択願います.', {duration: 7000});
    }else if(response==='phone_error'){
      toast.error('予約未完了.\n電話番号エラー.', {duration: 5000});
    }
  }
  const toastStatus2 = (response) =>{
    console.log(response);
  }

  const onChanegeDay_1 = (newDay) => {
    //console.log(newDay);
    setBreakTime_1(newDay);
  }
  const onChanegeDay_2 = (newDay) => {
    //console.log(newDay);
    setBreakTime_2(newDay);
  }

  if(modalIsOpen_2){

    try{
      document.getElementById('fab1').style.display='none';
      document.getElementsByClassName('inner-fabs')[0].style.display='none';
    }catch(e){}
    
  }else{
    try{
      document.getElementById('fab1').style.display='flex';
      document.getElementsByClassName('inner-fabs')[0].style.display='initial';
    }catch(e){}
    
  }


  return (
    <>
    <link rel='stylesheet' href='https://fonts.googleapis.com/icon?family=Material+Icons' />
    <div className="inner-fabs">
      <div className="fab round" id="fab7" >
        <a href="/crud?item=butsugu&type=new" className="">
          <i className="material-icons">create<span className="icon-label">仏具登録</span></i>
        </a>
      </div>
      <div className="fab round" id="fab5" >
        <a href="/list-item?type=butsugu" className="">
          <i className="material-icons">spa<span className="icon-label">仏具一覧</span></i>
        </a>
      </div>
      <div className="fab round" id="fab6" >
        <a href="/crud?item=butsudan&type=new">
          <i className="material-icons">create<span className="icon-label">仏壇登録</span></i>
        </a>
      </div>
      <div className="fab round" id="fab4" >
        <a href="/list-item?type=butsudan">
          <i className="material-icons">door_sliding<span className="icon-label">お仏壇一覧</span></i>
        </a>
      </div>
      <div className="fab round" id="fab3" onClick={showBreakTime}>
        <a href="/" className="none-link">
          <i className="material-icons">access_time<span className="icon-label">休店時間追加</span></i>
        </a>
      </div>
      <div className="fab round" id="fab2" onClick={showCustomer}>
        <a href="/" className="none-link">
          <i className="material-icons">calendar_month<span className="icon-label">来店予約追加</span></i>
        </a>
      </div>
    </div>
    <div className="fab round" id="fab1" onClick={showEvent}>
      <i className="material-icons" id="fabIcon">add</i>
    </div>



    <Modal 
        isOpen={modalIsOpen}
        ariaHideApp={false}
        portalClassName="modal-reserve"
    >
      <Reserve
        changeStatus = {changeStatus}
        toastStatus = {toastStatus}
      />
    </Modal>
    <div><Toaster position="top-right" /></div>



    <Modal
      isOpen={modalIsOpen_2}
      ariaHideApp={false}
      portalClassName="modal-customer-view modal-customer-view-long"
    >
      <div className="close-button">
        <button onClick={modalStatus}>
          <img src={closeImg}/>
        </button>
      </div>
      <div className="admin-view-customer-data">
        <p>休店時間の追加</p>
        <Calender2 
          changeStatus = {changeStatus}
          toastStatus2 = {toastStatus2}
        />
      </div>
    </Modal>
  </>


  );
}

export default FloatingButton;
