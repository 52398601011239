import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import parse from 'html-react-parser';
import axios from "axios";
import {functionFields} from '../../components/MyFunction';
import toast, { Toaster } from 'react-hot-toast'//https://mebee.info/2021/04/28/post-27258/
import settings from '../../settings.json';

import "./style.css";

/**
 * @component Form
 * @props - { object } -  config
 */
const Form = (props) => {
  const [mailSent, setmailSent] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({});
  const [selectedFile, setSelectFile] = useState("");
  const [radioValue, setRadioValue] = useState('on');
  const [specObj, setSpecObj] = useState({});
  const [textObj, setTextObj] = useState({});
  const [dbObj, setDbObj] = useState({});
  const [fetchFunction, setFetchFunction] = useState(false);
  const [editImageObj, setImageObj] = useState({});
  const [img_label_1, setImageLabel1] = useState('');
  const [img_label_2, setImageLabel2] = useState('');
  const [img_label_3, setImageLabel3] = useState('');
  const [editButtonMargin, setEditButtonMargin] = useState('');
  const [submitButton, setSubmitButton] = useState('');
  const [itemType, setItemType] = useState('');

  const [radioMarginLeft, setRadioMarginLeft] = useState('');
  const [radioMarginLeftStatus, setRadioMarginLeftStatus] = useState(false);

  const [toastStatus, setToastStatus] = useState(false);
  const [errorToastStatus, setErrorToastStatus] = useState(false);

  const [textareaVal, setTextAreaVal] = useState('');
  const [textareaChangeStatus, setTextareaChangeStatus] = useState(false);
  const [textareaVal2, setTextAreaVal2] = useState('');
  const [textareaChangeStatus2, setTextareaChangeStatus2] = useState(false);
  const [textareaVal3, setTextAreaVal3] = useState('');
  const [textareaChangeStatus3, setTextareaChangeStatus3] = useState(false);

  const [img_1_url, setImg_1_url] = useState(''); 
  const [img_2_url, setImg_2_url] = useState('');
  const [img_3_url, setImg_3_url] = useState('');
  const [img_1_url_status, setImg_1_url_status] = useState(false);
  const [img_2_url_status, setImg_2_url_status] = useState(false);
  const [img_3_url_status, setImg_3_url_status] = useState(false);

  const [readedPage, setReadedPage] = useState(false);
  //console.dir(formData);
 
  /**
  * @function handleFormSubmit
  * @param e { obj } - form event
  * @return void
  */

  useEffect(() => {
    var pageType = functionFields.getParameterByName('type');//new , edit
    var itemType = functionFields.getParameterByName('item');//
    setItemType(itemType);
    //console.log(pageType);
    if( pageType==='edit' && !fetchFunction){
      const getDBData = async () => {
        var url = settings.GET_OBUTSUDAN_API_URL;
        var db_id = functionFields.getParameterByName('db_id');
        var res = await fetch(
          url + '?type=edit&item='+itemType+'&db_id=' + db_id
        );
        var data = await res.json();
        //console.dir(data.data);
        var responseObj = JSON.parse(data.data)[0];
        //console.dir(responseObj );
        //console.dir(JSON.parse(responseObj['spec_obj_string']));
        setDbObj(responseObj);
        setSpecObj(JSON.parse(responseObj['spec_obj_string']));
        var textString = responseObj['text_obj_string'].replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
        setTextObj(JSON.parse(textString));
        //https://localhost:3000/crud?type=edit&item=butsugu&db_id=1

        //console.log(pageType);
        //console.dir(dbObj);
        //console.dir(dbObj.classification);
        //console.dir(specObj);
        //console.dir(textObj);  
  
        var setObj = {};
        setObj['post_type'] = 'edit';
        setObj['status'] = dbObj.status;
        setObj['type_id'] = dbObj.type_id;
        setObj['sub_type_id'] = dbObj.sub_type_id;
        setObj['name'] = dbObj.name;
        setObj['price_zeikomi'] = dbObj.price;
        setObj['price_zeinashi'] = dbObj.price_zeinashi;
        setObj['classification'] = specObj.classification;
        setObj['H'] = specObj.H;
        setObj['W'] = specObj.W;
        setObj['D'] = specObj.D;
        setObj['color'] = specObj.color;
        setObj['function_1'] = specObj.function_1;
        setObj['function_2'] =  specObj.function_2;
        setObj['function_3'] =  specObj.function_3;
        setObj['madein'] = specObj.madein;
        setObj['material'] = specObj.material;
        setObj['weight'] =  specObj.weight;
        setObj['catalog_page'] =  specObj.catalog_page;

        setObj['dairin'] =  specObj.dairin;
        setObj['toita'] =  specObj.toita;
        setObj['hyoumen'] =  specObj.hyoumen;

        setObj['mini_title_1'] = textObj.mini_title_1;
        setObj['mini_title_2'] = textObj.mini_title_2;
        setObj['mini_title_3'] = textObj.mini_title_3;
        setObj['text_1'] = textObj.text_1;
        setObj['text_2'] = textObj.text_2;
        setObj['text_3'] = textObj.text_3;
        setObj['img_1'] = textObj.img_1;
        setObj['img_2'] = textObj.img_2;
        setObj['img_3'] = textObj.img_3;

        setFormData(setObj);

        //
        setTextAreaVal(textObj.text_1);
        setTextAreaVal2(textObj.text_2);
        setTextAreaVal3(textObj.text_3);

        setFetchFunction(true);

        if((!setObj['img_1']|| !setObj['img_2'] || !setObj['img_3'])){
          //console.log('margin');
          setEditButtonMargin('button-m-t');
        }else{
          setEditButtonMargin('');
        }

        if(dbObj.status==='on'){
          setRadioValue('on');
        }else{
          setRadioValue('off');
        }

        if(setObj['img_1']){
          setImageLabel1('done');
        }
        if(setObj['img_2']){
          setImageLabel2('done');
        }
        if(setObj['img_3']){
          setImageLabel3('done');
        }
        
        

      }
      getDBData();
    }


    //
    if(!radioMarginLeftStatus){
      var contentW = document.getElementsByClassName('contact-form__container')[0].offsetWidth;
      var radioLabelW = document.getElementsByClassName('label-radio')[0].offsetWidth;
      var marginLeftRadio = contentW/2 - 40 - radioLabelW*2;
      //console.log(marginLeftRadio);
      setRadioMarginLeft(marginLeftRadio);
      setRadioMarginLeftStatus(true);
    }

    if(!readedPage && pageType==='edit'){
      setTimeout(function(){
        toast.success('読み込み完了', {duration: 3000});
      }, 1000);//wait 2 seconds
      setReadedPage(true);
    }

  })




  const handleFormSubmit = e => {
    //console.dir(formData);
    setErrorToastStatus(false);

    setImg_1_url_status(false);
    setImg_2_url_status(false);
    setImg_3_url_status(false);

    const postData = new FormData();

    console.dir(formData);

    var inputCheck = false;
    if(typeof formData.name!=='undefined' && typeof formData.color!=='undefined' && formData.name.length!==0 && formData.color.length!==0){
      var inputCheck = true;
    }

    var db_id = functionFields.getParameterByName('db_id');
    //console.log('db_id '+db_id);
    postData.append('db_id', String(db_id) );

    var changeImgArrayString = [img_label_1, img_label_2, img_label_3].toString();
    postData.append('changeImgArrayString', changeImgArrayString );
    //console.log('changeImgArrayString:'+changeImgArrayString);

    //console.log('selectedFile.length:'+String(selectedFile.length)+' img_label:'+img_label_1+' '+img_label_2+' '+img_label_3);
    if(img_label_1==='removed' && img_label_2==='removed' && img_label_3==='removed'){
      for (let i = 2; i < 0; i--) {
        //console.dir(selectedFile[i]);
        postData.append("file[]", selectedFile[i]);
      }  
    }else{
      for (let i = 0; i < selectedFile.length; i++) {
        //console.dir(selectedFile[i]);
        postData.append("file[]", selectedFile[i]);
      }  
    }



    var pageType = props.config.pageType;
    postData.append('pageType', pageType);

    postData.append('itemType', itemType);

    postData.append('inputData', JSON.stringify(formData) );
    //console.dir(postData);

    e.preventDefault();
    //axios({
    //  method: "post",
    //  url: props.config.api,
    //  headers: { "content-type": "multipart/form-data" },
    //  data: formData
    //})
    if(inputCheck){
      setSubmitButton('submit-on');
      axios.post(props.config.api, postData, {
        // receive two parameter endpoint url ,form data
      })
      .then(result => {
        if (result) {
          var pageType = functionFields.getParameterByName('type');//new , edit
          //alert('result:'+result.data.result+' type:'+pageType);
          if(result.data.result==='update_ok' && pageType==='edit'){
            if(!toastStatus){
              window.scrollTo(0, 0);
              toast.success('保存しました.', {duration: 5000});
              setToastStatus(true);
            }
            
            setTimeout(function(){
              window.location.reload(true);
            }, 3000);//wait 2 seconds
          }else if(result.data.result==='OK' && pageType==='new'){
            if(!toastStatus){
              window.scrollTo(0, 0);
              toast.success('保存しました.', {duration: 5000});
              setToastStatus(true);
            }
            setTimeout(function(){
              window.location.reload(true);
            }, 2000);//wait 2 seconds
          }
          else{
            setmailSent(result.data.sent)
          }
          
          setError(false)
        } else {
          setError(true)
        }
      })
      .catch(error => setError( error.message ));
    }else{
      if(!errorToastStatus){
        window.scrollTo(0, 0);
        toast.error('名前とカラーの入力お願いします.', {duration: 5000});
        setErrorToastStatus(true);
      }
      
    }

  };
  /**
    * @function handleChange
    * @param e { obj } - change event
    * @param field { string } - namve of the field
    * @return void
    */
  const handleChange = (e, field) => {
    let value = e.target.value;
    if(field!=='status'){
      //console.log(field);
      setFormData({
        ...formData,
        [field]: value,
      });
    }else{
      //console.dir(formData);
      //console.log(field);
      //console.dir(e);
      if(radioValue==='on'){
        setRadioValue('off');
        setFormData({
          ...formData,
          [field]: 'off',
        });
      }else{
        setRadioValue('on');
        setFormData({
          ...formData,
          [field]: 'on',
        });
      }
    }

    if(field==='text_1'){
      setTextareaChangeStatus(false);
      setTextAreaVal(value);
    }else if(field==='text_2'){
      setTextareaChangeStatus2(false);
      setTextAreaVal2(value);
    }else if(field==='text_3'){
      setTextareaChangeStatus3(false);
      setTextAreaVal3(value);
    }
    //e.preventDefault();
    //console.dir(formData);
  };


  const handleClick = (e, field) => {
    //console.dir(e);
    //console.dir(field);
  }

  const handleChangeImage = (event) => {
    //console.dir(event.target.files);
    setSelectFile(event.target.files);

    //console.log('img1:'+formData['img_1']+' 2:'+formData['img_2']+' 3:'+formData['img_3']);
    var thiskey = event.target;
    //console.dir(thiskey);
    //console.log('img_label1:'+img_label_1+' img_label2:'+img_label_2+' img_label3:'+img_label_3);

    if(event.target.files.length===1){
      for (let i = 0; i < event.target.files.length; i++) {
        if(!img_label_1 || img_label_1==='removed'){//null
          setImageLabel1('added');
          setImg_1_url_status(false);
        }
        else if(!img_label_2 || img_label_2==='removed'){
          setImageLabel2('added');
          setImg_2_url_status(false);
        }
        else if(!img_label_3 || img_label_3==='removed'){
          setImageLabel3('added');
          setImg_3_url_status(false);
        }
      }  
    }else if(event.target.files.length<=3){
      for (let i = 0; i < event.target.files.length; i++) {
        if(!img_label_1 || img_label_1==='removed'){//null
          setImageLabel1('added');
          setImg_1_url_status(false);
        }
        if(!img_label_2 || img_label_2==='removed'){
          setImageLabel2('added');
          setImg_2_url_status(false);
        }
        if(!img_label_3 || img_label_3==='removed'){
          setImageLabel3('added');
          setImg_3_url_status(false);
        }
      }  
    }

    event.preventDefault();

  }

  //⭐️
  const handleChangeImageSingle = (e) => {
    var fileName = e.target.value;
    var fileName = fileName.slice(12);
    //console.dir(e.target.name);
//    if(e.target.name==='img_1'){
//      setImageLabel1(fileName);
//    }else if(e.target.name==='img_2'){
//      setImageLabel2(fileName);
//    }else if(e.target.name==='img_3'){
//      setImageLabel3(fileName);
//    }
    
    //var thisNumber = Number(e.target.name.slice(4))-1;
    //setSelectFile(e.target.files);

    if(img_label_1!=='' && img_label_2!=='' && img_label_3!==''){
      setEditButtonMargin('');
    }

    //console.dir(editImageObj);//img_3
    //editImageObj[e.target.name] = e.target.value;
    //setImageObj(editImageObj);
    //console.dir(editImageObj);
  }

  //画像削除
  const removeImage = async(e) => {
    var thisClassName = e.target.className;
    if(thisClassName.indexOf('img-type') != -1){//img-type
      //var db_id = functionFields.getParameterByName('db_id');
      //var postResponse = await fetchData('obutsudan', 'removeImage', db_id, e.target.id);
      //console.log(postResponse);
      var thiskey = e.target.id;
      var thiskey = thiskey.replace('trash_', 'img_');
      //if(postResponse==='remove_ok'){
      //  setFormData({
      //    ...formData,
      //    [thiskey]: "",
      //  });
      //}

      if(thiskey==='img_1'){
        setImageLabel1('removed');
        setImg_1_url_status(false);
      }else if(thiskey==='img_2'){
        setImageLabel2('removed');
        setImg_2_url_status(false);
      }else if(thiskey==='img_3'){
        setImageLabel3('removed');
        setImg_3_url_status(false);
      }

      setFormData({
        ...formData,
        [thiskey]: "",
      });

      setEditButtonMargin('button-m-t');

    }else if(thisClassName.indexOf('text-type') != -1){
      if(e.target.id==='trash_1'){
        setImageLabel1('');
      }else if(e.target.id==='trash_2'){
        setImageLabel2('');
      }else if(e.target.id==='trash_3'){
        setImageLabel3('');
      }
    }

    //e.preventDefault();
  }

  const fetchData = async (type, key, db_id, imgId) =>{//画像削除
    var url = 'https://kyoto-butsudan.com/API/db_crud.php?'+key+'='+imgId+'&type='+type+'&db_id='+db_id;
    var res = await fetch(url);
    var data = await res.json();
    //console.dir(data);
    return data.result;
  }
  

    const { pageType, title, description, reverse, successMessage, errorMessage, fieldsConfigButsudan } = props.config;

    //console.log(formData);
    //console.dir(selectedFile);
    
    return (
      <div className="contact-form">
        <div className="contact-form__header">
            <h2>{title}</h2>
            <p>{description}</p>
            <a href={reverse} className="mini-button-g">戻る</a>
        </div>
      <div className="contact-form__container">
        <div><Toaster position="top-right" /></div>
        <div>
          <form action="#" className="item-input-form">
            {fieldsConfigButsudan &&
              fieldsConfigButsudan.map(field => {
                
                var labelName = field.fieldName;
                //console.log('field.fieldName '+field.fieldName);
                if(formData[labelName]===undefined || formData[labelName]===null){
                  var thisVal = '';
                }else{
                  var thisVal = formData[labelName];//空データが上書きされて空白に...
                }
                if(field.label==='型番'){
                  var m_t_2 = 'm_t_2';
                }else if(itemType==='butsugu' && field.fieldName==='name'){
                  var m_t_2 = 'm_t_2';
                }else{
                  var m_t_2 = '';
                }
                if( field.fieldName==='price_zeikomi' || field.fieldName==='price_zeinashi' || field.fieldName==='catalog_page' || field.fieldName==='mini_title_1' || field.fieldName==='mini_title_2' || field.fieldName==='mini_title_3' || field.fieldName==='type_id' || field.fieldName==='sub_type_id' ){
                  var addClass = 'input-img-tag';//hidden
                }else if( (itemType==='butsugu' && field.fieldName==='type_id') || (itemType==='butsugu' && field.fieldName==='sub_type_id') || (itemType==='butsugu' && field.fieldName==='H') || (itemType==='butsugu' && field.fieldName==='W') || (itemType==='butsugu' && field.fieldName==='D') || (itemType==='butsugu' && field.fieldName==='function_1') || (itemType==='butsugu' && field.fieldName==='function_2') || (itemType==='butsugu' && field.fieldName==='function_3') || (itemType==='butsugu' && field.fieldName==='madein') || (itemType==='butsugu' && field.fieldName==='weight') || (itemType==='butsugu' && field.fieldName==='catalog_page') || (itemType==='butsugu' && field.fieldName==='text_2') || (itemType==='butsugu' && field.fieldName==='text_3') || (itemType==='butsugu' && field.fieldName==='dairin') || (itemType==='butsugu' && field.fieldName==='toita') || (itemType==='butsugu' && field.fieldName==='hyoumen') ){
                  var addClass = 'input-img-tag';//hidden
                }else if( (itemType==='butsudan' && field.fieldName==='classification') ){
                  var addClass = 'input-img-tag';//hidden
                }else{
                  var addClass = '';
                }



                return (
                  <React.Fragment key={field.id}>
                    {(() => {
                      if (field.type==='text' && field.fieldName==='post_type' ) {
                        return <> 
                          <React.Fragment>
                            <label className="d-block input-img-tag">{field.label}</label>
                            <input
                              type={field.type}
                              className={field.klassName}
                              placeholder={field.placeholder}
                              value={pageType}
                              onChange={e => handleChange(e, field.fieldName)}
                            />
                            <h4>{pageType==='new' ? '新規登録モード' : '編集モード' }</h4>
                          </React.Fragment>
                        </>
                      }
                    })()}

                    
                    {(() => {
                      if(field.type==='radio'){
                        if (field.label==='公開') {
                          return <> 
                            <React.Fragment>
                              <label className="label-radio" style={{marginLeft:radioMarginLeft+'px'}}>{field.label}</label>
                              <input
                                type={field.type}
                                className={field.klassName}
                                value={radioValue}
                                onChange={e => handleChange(e, field.fieldName)}
                                checked = {radioValue==='on'}
                              />
                            </React.Fragment>
                          </>
                        }else if(field.label==='非公開'){
                          return <> 
                            <React.Fragment>
                              <label className="label-radio label-radio-ml">{field.label}</label>
                              <input
                                type={field.type}
                                className={field.klassName}
                                value={radioValue}
                                onChange={e => handleChange(e, field.fieldName)}
                                checked = {radioValue==='off'}
                              />
                              <p className="p-height-07">　</p>
                            </React.Fragment>
                          </>
                        }
                      }

                    })()}
                    





                    {(() => {
                      if ( (field.type==='text' && field.fieldName!=='post_type') || (field.type==='number' && field.fieldName!=='post_type') ) {
                        var labelName = field.fieldName;

                        if( field.fieldName==='text_1' || (itemType==='butsudan' && field.fieldName==='text_2') || (itemType==='butsudan' && field.fieldName==='text_3') ){

                          if(field.fieldName==='text_1'){
                            var supportMark = true;
                            //var supportHTML = '<details><summary>'+field.label+'</summary><p><img src="/images/admin/input-support-1.jpg"></p></details>';
                            if(field.fieldName==='text_1' && thisVal.length>0 && !textareaChangeStatus){
                              setTextAreaVal(thisVal);
                              setTextareaChangeStatus(true);
                              //console.log('textareaVal_set '+textareaVal);
                            }
                            //console.log('textareaVal__ '+textareaVal);
                            return<>
                              <React.Fragment>
                                <label 
                                  className={ "d-block " + m_t_2  + ' ' + addClass }
                                >
                                  {field.label }
                                  {/*{ supportMark && typeof supportHTML!=='undefined' ? parse(supportHTML) : field.label }*/}
                                </label>
                                <textarea value={textareaVal} onChange={e => handleChange(e, field.fieldName)} rows={5} className={toastStatus ? 'input-ban' : ''} />
                              </React.Fragment>
                            </>
                          }


                          if(field.fieldName==='text_2' && itemType==='butsudan'){
                            var supportMark = true;
                            //var supportHTML = '<details><summary>'+field.label+'</summary><p><img src="/images/admin/input-support-1.jpg"></p></details>';
                            if(field.fieldName==='text_2' && thisVal.length>0 && !textareaChangeStatus2){
                              setTextAreaVal2(thisVal);
                              setTextareaChangeStatus2(true);
                              //console.log('textareaVal2_set '+textareaVal2);
                            }
                            //console.log('textareaVal2__ '+textareaVal2);
                            return<>
                              <React.Fragment>
                                <label 
                                  className={ "d-block " + m_t_2  + ' ' + addClass }
                                >
                                  {field.label }
                                  {/*{ supportMark && typeof supportHTML!=='undefined' ? parse(supportHTML) : field.label }*/}
                                </label>
                                <textarea value={textareaVal2} onChange={e => handleChange(e, field.fieldName)} rows={5} className={toastStatus ? 'input-ban' : ''} />
                              </React.Fragment>
                            </>
                          }


                          if(field.fieldName==='text_3' && itemType==='butsudan'){
                            var supportMark = true;
                            //var supportHTML = '<details><summary>'+field.label+'</summary><p><img src="/images/admin/input-support-1.jpg"></p></details>';
                            if(field.fieldName==='text_3' && thisVal.length>0 && !textareaChangeStatus3){
                              setTextAreaVal3(thisVal);
                              setTextareaChangeStatus3(true);
                              //console.log('textareaVal3_set '+textareaVal3);
                            }
                            //console.log('textareaVal3__ '+textareaVal3);
                            return<>
                              <React.Fragment>
                                <label 
                                  className={ "d-block " + m_t_2  + ' ' + addClass }
                                >
                                  {field.label }
                                  {/*{ supportMark && typeof supportHTML!=='undefined' ? parse(supportHTML) : field.label }*/}
                                </label>
                                <textarea value={textareaVal3} onChange={e => handleChange(e, field.fieldName)} rows={5} className={toastStatus ? 'input-ban' : ''} />
                              </React.Fragment>
                            </>
                          }


                        }else{
                          var supportMark = false;
                          if(labelName==='name' || labelName==='color'){
                            var requireClass = 'input-require';
                          }else{
                            var requireClass = '';
                          }
                          return <> 

                            <label className={addClass + ' ' + requireClass}>{field.label}</label>
                            <input
                              type={field.type}
                              className={toastStatus ? field.klassName + ' ' + addClass + ' input-ban' : field.klassName + ' ' + addClass }
                              placeholder={field.placeholder}
                              value={thisVal}
                              onChange={e => handleChange(e, field.fieldName)}
                            />

                          </>

                        }
                      }
                    })()}





                    {(() => {
                      if (field.type==='file' && pageType==='new') {
                        return <React.Fragment>
                          <label>{field.label}</label>
                          <input
                            type={field.type}
                            className={field.klassName}
                            placeholder={field.placeholder}
                            value={field.name}
                            multiple
                            onChange={e => handleChangeImage(e, field.fieldName)}
                          />
                          <p className="small-text">{itemType==='butsudan' ? '（画像は3枚まで、選択順に表示されます。PCの場合は、Ctrlキーを押しながら選択で複数選択可。）' : '画像ファイルを1つ選択して下さい' }</p>
                        </React.Fragment>
                    //画像　編集
                      }else if(field.type==='file' && pageType==='edit'){
                        var img_1 = formData['img_1'];
                        var img_2 = formData['img_2'];
                        var img_3 = formData['img_3'];//null
                        var trashImg = '/images/common/trash.svg';

                        if(!img_1_url_status && typeof img_1!=='undefined'){
                          setImg_1_url(settings.IMG_BASE_URL_BUTSUDAN+img_1);
                          setImg_1_url_status(true);
                          //console.log('setImg_1_url_status(true);');
                        }else if(!img_2_url_status && typeof img_2!=='undefined'){
                          setImg_2_url(settings.IMG_BASE_URL_BUTSUDAN+img_2);
                          setImg_2_url_status(true);
                          //console.log('setImg_2_url_status(true);');
                        }else if(!img_3_url_status && typeof img_3!=='undefined'){
                          setImg_3_url(settings.IMG_BASE_URL_BUTSUDAN+img_3);
                          setImg_3_url_status(true);
                          //console.log('setImg_3_url_status(true);');
                        }
                        //console.log('img_1_url_status '+img_1_url_status+' '+img_1+' '+img_1_url);
                        //console.log('img_2_url_status '+img_2_url_status+' '+img_2+' '+img_2_url);
                        //console.log('img_3_url_status '+img_3_url_status+' '+img_3+' '+img_3_url);

                        return <div className="edit-img-box">
                          {img_1 || img_1_url_status ? <div className="edit-img">{/* img_1 && img_1_url_status  */}
                            <img src={typeof img_1==='undefined' && img_1_url_status ? img_1_url : settings.IMG_BASE_URL_BUTSUDAN+img_1} />
                            <img 
                              id="trash_1" 
                              name="trash_1" 
                              src={trashImg} 
                              alt="trash_1" 
                              className="trash-icon img-type" 
                              onClick={removeImage}
                            />
                          </div> : <>
                            <React.Fragment>
                              {img_label_1==='' ? <>
                                <label className="input-img-label">
                                  <span>{field.label+'１'}</span><br/>なし
                                </label>
                              </> : <div className="edit-img">
                                <p className="small-text">{img_label_1==='added' ? '追加' : ''}{img_label_1==='removed' ? '削除' : ''}</p>
                              </div>
                                
                              

                              }
                            </React.Fragment>
                          </> }
                          {/*画像2*/}
                          { (img_2 || img_2_url_status) && itemType==='butsudan' ? <div className="edit-img">
                            <img src={typeof img_2==='undefined' && img_2_url_status ? img_2_url : settings.IMG_BASE_URL_BUTSUDAN+img_2} />
                            <img 
                              id="trash_2" 
                              name="trash_2" 
                              src={trashImg} 
                              alt="trash_2" 
                              className="trash-icon img-type" 
                              onClick={removeImage}
                            />
                          </div> : <>
                            <React.Fragment>
                              {img_label_2==='' && itemType==='butsudan' ? <>
                                <label className="input-img-label">
                                  <span>{field.label+'２'}</span><br/>なし
                                </label>
                              </> : <>
                                <p className={'small-text ' + itemType==='butsudan' ? '' : 'input-img-tag' }>{img_label_2==='added' ? '追加' : ''}{img_label_2==='removed' ? '削除' : ''}</p>
                              </>}

                              
                            </React.Fragment>
                          </> }
                          {/*画像3*/}
                          { (img_3 || img_3_url_status) && itemType==='butsudan' ? <div className="edit-img">
                            <img src={typeof img_3==='undefined' && img_3_url_status ? img_3_url : settings.IMG_BASE_URL_BUTSUDAN+img_3} />
                            <img 
                              id="trash_3" 
                              name="trash_3" 
                              src={trashImg} 
                              alt="trash_3" 
                              className="trash-icon img-type" 
                              onClick={removeImage}
                            />
                          </div> : <>
                            <React.Fragment>
                              {img_label_3==='' && itemType==='butsudan' ? <>
                                <label className="input-img-label">
                                  <span>{field.label+'3'}</span><br/>なし
                                </label>
                              </> : <>
                              <p className={'small-text ' + itemType==='butsudan' ? '' : 'input-img-tag' }>{img_label_3==='added' ? '追加' : ''}{img_label_3==='removed' ? '削除' : ''}</p>
                              </>}
                              
                            </React.Fragment>
                          </> }



                          {
                            (() => {
                              //console.log(img_1+' '+img_2+' '+img_3+'  '+img_1_url_status+' '+img_2_url_status+' '+img_3_url_status+'  '+img_1_url+' '+img_2_url+' '+img_3_url);

                              if ( (img_label_1==='removed' || img_label_2==='removed' || img_label_3==='removed' || !img_1 || !img_2 || !img_3) ) {

                                return(<div className="edit-input-img-area">
                                  <label></label>
                                  <input
                                    type={field.type}
                                    className={field.klassName}
                                    placeholder={field.placeholder}
                                    value={field.name}
                                    multiple
                                    onChange={e => handleChangeImage(e, field.fieldName)}
                                  />
                                  <p className="small-text">{itemType==='butsudan' ? '（複数画像選択の場合は、左詰（1,2,3）で画像が設定されます）' : '画像更新する場合は、削除して１画像追加ください' }</p>
                                </div>);

                              }else{

                              }
                            })()
                          }



                        </div> 
                      }

                    })()}

                  </React.Fragment>
                );
              })}
            <input type="submit" className={"submit-button " + editButtonMargin + " " + submitButton } onClick={e => handleFormSubmit(e)} value={props.config.pageType==='new' ? '追加する' : '変更する' } />
            <div>
              {mailSent && <div className="sucsess">{successMessage}</div>}
              {error && <div className="error">{errorMessage}</div>}
            </div>
          </form>

          <details className="input-detail-tag"><summary>テキスト欄表示の様子</summary><p><img src="/images/admin/input-support-1.jpg" /></p></details>
          <p className="input-detail-tag mt-1-style">*ページ読み込み時に入力データなき場合、ページ再読み込みしてみて下さい。</p>
        </div>
        </div>
      </div>
    );
}

export default Form;
//propTypes for the component
Form.propTypes = {
  config: PropTypes.object.isRequired
};
