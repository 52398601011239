//import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";

import TopHeader from './TopHeader';
import FloatingButton from "../components/parts/FloatingButton";
//import StoreSchedule from "./StoreSchedule";

import './assets/admin.css';
import StoreSchedule from "./StoreSchedule";
import {functionFields} from '../components/MyFunction';



function SchedulePage() {
  var todayDate = new Date();
  var todayDateString = todayDate.getFullYear() + '-' + String(todayDate.getMonth()+1) + '-' +  todayDate.getDate();

  useEffect(() => {
    var url = window.location.href;
    if(url.indexOf('admin') != -1){
      //document.getElementsByClassName('header')[0].style.display = 'none';
      
    }
    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {

    }

    //
    document.getElementsByTagName('body')[0].style.borderTop = 'initial';
    document.getElementsByClassName('header')[0].style.display = 'none';

    var authCookieVal = functionFields.getAuthCookieVal('__kb_user_auth');
    if(authCookieVal===''){//未認証
      window.location.href = '/google_auth';
    }
    
  }, []);





  return (
    <>
      <TopHeader/>
      <StoreSchedule />
      <FloatingButton />
    </>
  );
}

export default SchedulePage;
