import ReactPaginate from "react-paginate";
import { useEffect, useState } from "react";
import './common.css';
import itemImg from './assets/img/10.jpg';
import settings from '../settings.json';
import mikan from 'mikanjs';
import {Helmet} from 'react-helmet';
import Header from './parts/Header';
import {functionFields} from './MyFunction';
//import bootstrap from './assets/bootstrap.css';

import InfiniteScroll from "react-infinite-scroller";
// infiniteになってない、https://www.ey-office.com/blog_archive/2022/08/10/checked-the-library-for-infinite-scroll-for-react/

function ItemList() {
  const [items, setItems] = useState([]);
  const [items_1, setItems_1] = useState([]);
  const [items_2, setItems_2] = useState([]);

  const [total, setTotal] = useState();
  const [total_1, setTotal_1] = useState();
  const [total_2, setTotal_2] = useState();
  
  //const [searchType, setSearchType] = useState('butsudan');
  const [butsudanView, setButsudanView] = useState(true);
  const [butsuguView, setButsuguView] = useState(false);
  const [gridCss, setGridCss] = useState();
  const [mapView, setMapView] = useState('op-02');
  const [headerDescri, setHeaderDescri] = useState('');

  const itemsPerPage = 6;
  const [hasMore, setHasMore] = useState(true);
  const [records, setrecords] = useState(itemsPerPage);
  const [go998, setGo998] = useState(false);


  var url = settings.GET_OBUTSUDAN_API_URL;

  useEffect(() => {
    //
    var myView = localStorage.getItem("myView");
    //console.log(myView);
    if(myView!==null){
      if(myView==='butsuguView'){
        setButsuguView(true);
        setButsudanView(false);
        var urlKey = 'butsugu';
      }else{
        setButsuguView(false);
        setButsudanView(true);
        var urlKey = 'butsudan';
      }
    }else{
      setButsuguView(false);
      setButsudanView(true);
      var urlKey = 'butsudan';
    }

    //console.log('⭐️');
    const getDBData = async () => {
      const res = await fetch(
        url + `?type=`+urlKey+'&sort_type=""'
      );
      const data = await res.json();
      const total = JSON.parse(data.total);

      var dataText = data.data;
      var dataText = dataText.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
      //console.log(dataText);
      //console.dir(JSON.parse(dataText));
      setItems(JSON.parse(dataText));
      setTotal(data.total);


      const res_1 = await fetch(
        url + `?type=`+'butsudan'+'&sort_type=""'
      );
      const data_1 = await res_1.json();
      const total_1 = JSON.parse(data_1.total);

      var dataText = data_1.data;
      var dataText = dataText.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
      //console.log(dataText);
      //console.dir(JSON.parse(dataText));
      setItems_1(JSON.parse(dataText));
      setTotal_1(data_1.total);


      const res_2 = await fetch(
        url + `?type=`+'butsugu'+'&sort_type=""'
      );
      const data_2 = await res_2.json();
      const total_2 = JSON.parse(data_2.total);

      var dataText = data_2.data;
      var dataText = dataText.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
      //console.log(dataText);
      //console.dir(JSON.parse(dataText));
      setItems_2(JSON.parse(dataText));
      setTotal_2(data_2.total);

    };

    getDBData();


    //モバイル
    if (navigator.userAgent.match(/iPhone|Android/)) {
      if(myView==='butsuguView'){
        setGridCss('item-content-total');
      }else{
        setGridCss('item-content-butsudan-2');
      }
    }else{
      //console.log('_style_pc');
      const $style = document.createElement("link");
      $style.rel = "stylesheet";
      $style.type = "text/css";
      $style.href = "/css/pc.css";
      document.head.appendChild($style);
      
      if(myView==='butsuguView'){
        setGridCss('item-content-total');
      }else{
        setGridCss('item-content-butsudan-2');
      }
    }

    setHeaderDescri('お仏壇・お仏具の商品一覧ページ:'+settings.DESCRIPTION);


    //Bootstrap
    //const link = document.createElement('link');
    //link.href = {bootstrap};
    //link.rel = "stylesheet";
    //link.integrity = "sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3";
    //document.head.appendChild(link);


  }, []);


  const fetchData = async (paramerter, value) => {
    //console.log('⭐️_1');
    //console.log(paramerter);
    //console.log(value);
    //if(paramerter==='currentPage'){
    //  var fetchUrl = url + `?type=`+searchType+`&text=`+searchText+`&page=`+String(value)+`&limit=`+String(limit);
    //}else if(paramerter==='text'){
    //  var fetchUrl = url + `?type=`+searchType+`&text=`+value+`&page=`+String(currentPage)+`&limit=`+String(limit);
    //}else if(paramerter==='type'){
    //  var fetchUrl = url + `?type=`+value+`&text=`+searchText+`&page=`+String(currentPage)+`&limit=`+String(limit);
    //}else if(paramerter==='list'){
    //  var fetchUrl = url + `?type=`+searchType+`&text=`+searchText+`&page=`+String(currentPage)+`&limit=`+value;
    //}
    //console.log(fetchUrl);
//
//
    //const res = await fetch(fetchUrl);
    //const data = await res.json();
    //console.dir(data);
    //return data;
  };




//LIST変更
  const handleChangeSelect = async(event) => {
    console.log('⭐️_5');

  }

  const changeView = async(e) => {
    //console.dir(e.target.innerText);
    setMapView('op-02');
    if(e.target.innerText==='お仏具' && !butsuguView){
      {/*
      const getDBData_1 = async () => {
        const res = await fetch(
          url + `?type=`+'butsugu'+'&sort_type=""'
        );
        const data = await res.json();
        const total = JSON.parse(data.total);
        var dataText = data.data;
        var dataText = dataText.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
        //console.log(dataText);
        setItems(JSON.parse(dataText));
        setTotal(data.total);
      };
      getDBData_1();
      */}
      setItems(items_2);
      setTotal(total_2);

      setButsudanView(false);
      setButsuguView(true);
      setGridCss('item-content-total');
      localStorage.setItem('myView', 'butsuguView');
    }else if(e.target.innerText==='お仏壇' && !butsudanView){
      {/*
      const getDBData_2 = async () => {
        const res = await fetch(
          url + `?type=`+'butsudan'+'&sort_type=""'
        );
        const data = await res.json();
        const total = JSON.parse(data.total);
        var dataText = data.data;
        var dataText = dataText.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
        //console.log(dataText);
        setItems(JSON.parse(dataText));
        setTotal(data.total);
      };
      getDBData_2();
    */}

      setItems(items_1);
      setTotal(total_1);

      setButsudanView(true);
      setButsuguView(false);
      setGridCss('item-content-butsudan-2');
      localStorage.setItem('myView', 'butsudanView');
    }
    
  }

  function scrollEvent(){
    console.log('GO');
    try{
      if(go998===false){
          let y_position = document.getElementById('i_21').getBoundingClientRect().top;
          //console.log(y_position);
          window.scrollTo({ top:y_position, behavior:'smooth' })
          setGo998(true);
      }
      //section.scrollIntoView( { behavior: 'smooth', block: 'bottom' } );
    }catch (error) {
      //console.dir(error);
    }
  }

 
  const loadMore = () => {
    console.log('r:'+String(records)+' i:'+String(items.length));
    if (records === items.length || records > 200) {
      setHasMore(false);
    } else {
      setTimeout(() => {
        setrecords(records + itemsPerPage);
      }, 300);
    }
  };


  return (
    <>
      <Helmet>
        {/*<title>お仏壇・お仏具商品一覧/{settings.TITLE}</title>*/}
        <title>お仏壇一覧｜上置仏壇専門店　京都【株式会社 まるいち】</title>
        <meta
          name="description"
          content="お仏壇一覧｜京都の「現代風小型（上置）仏壇」を専門に販売している【株式会社 まるいち】洋風でおしゃれな小さい仏壇に標準仏具を付属し、お求めやすいセット価格にて販売。完全予約制・同一時間1組限定。"
        />
      </Helmet>

      <Header />
    
      <div className="ItemList">
        <p className="pankuzu">
          <a href="/">トップ</a> ＞ 商品一覧
        </p>

        <h2 className="page-h2 pb-08">{butsudanView ? 'お仏壇' : 'お仏具'}一覧</h2>
        {/*}
        <select className="item-sort" value="_" onChange={handleChangeSelect}>
          <option disabled value="_">並び替え</option>
          <option value="1">低価格順</option>
          <option value="2">高価格順</option>
          <option value="3">小さい順</option>
          <option value="4">大きい順</option>
          <option value="5">色別</option>
        </select>
        */}

        <div className="view-icon">
          表示：
          <button onClick={changeView}>{butsudanView ? '✔️' : ''} お仏壇</button>
          <button onClick={changeView}>{butsuguView ? '✔️' : ''} お仏具</button>
        </div>

        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          //loader={<h4 className="loader">Loading...</h4>}
          useWindow={false}
        >

          {butsudanView===true ? <>
            <div className={gridCss + " grid-gap-butsudan " + mapView}>
                {items.map((item, i)=>{
                  var itemText = item.text_obj_string.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
                  var thisItemObj = JSON.parse(itemText);
                  if(thisItemObj.img_1!==null){
                    var thisImage = settings.IMG_BASE_URL_BUTSUDAN + thisItemObj.img_1;
                  }else{
                    var thisImage = 'https://maruichi-kyoto.com/img/no-img.jpg';
                  }
                  var thistext = thisItemObj.text_1;
                  if(thisImage==='https://kyoto-butsudan.com/API/uploads/'){
                    if(thisItemObj.img_2!=='null'){
                      var nextImage = settings.IMG_BASE_URL_BUTSUDAN + thisItemObj.img_2;
                    }else{
                      var nextImage = 'https://maruichi-kyoto.com/img/no-img.jpg';
                    }
                    if(nextImage==='https://kyoto-butsudan.com/API/uploads/'){
                      if(thisItemObj.img_3!=='null'){
                        var nextImage = settings.IMG_BASE_URL_BUTSUDAN + thisItemObj.img_3;
                      }else{
                        var nextImage = 'https://maruichi-kyoto.com/img/no-img.jpg';
                      }
                    }
                  }
                  if(total===item.id){
                    if(mapView==='op-02'){
                      //console.log('LAST');
                      setTimeout(function(){
                        setMapView('op-1');
                      }, 300);//wait 1 seconds
                    }

                  }
                  return(
                    <>
                      <div key={item.id} id={"i_"+item.id}>
                        <a href={settings.DEV+"/item?type=butsudan_single&db_id=" + item.db_id} >
                          <div className="item-box-relative butsugu-list-view-grid">
                            <div><img alt={"京都 仏壇 "+item.name} src={thisImage!=='https://kyoto-butsudan.com/API/uploads/' ? thisImage : nextImage}/></div>
                            <div className="butsugu-list-text">{item.name}</div>
                            {/*
                              {item.id>=19 && item.id<=23 &&
                                <div className="_998_label">998シリーズ</div>
                              }
                            */}

                          </div>
                        </a>
                      </div>


                      {(() => {
                        if(items.length-1===i){
                          let this_url = window.location.href;
                          //console.log(this_url);
                          //console.log(go998);
                          if (this_url.indexOf('#998') !== -1 && go998===false) {
                            scrollEvent();
                          } 
                        }
                      })()}

                    </>
                  )
                })}



            </div>
              
          </> : <>
            <div className={gridCss + " grid-gap-butsugu " + mapView}>
                {items.map((item)=>{
                  var itemText = item.text_obj_string.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
                  var thisItemObj = JSON.parse(itemText);
                  if(thisItemObj.img_1!==null){
                    var thisImage = settings.IMG_BASE_URL_BUTSUDAN + thisItemObj.img_1;
                  }else{
                    var thisImage = 'https://maruichi-kyoto.com/img/no-img.jpg';
                  }
                  var thistext = thisItemObj.text_1;
                  if(thisImage==='https://kyoto-butsudan.com/API/uploads/'){
                    var nextImage = settings.IMG_BASE_URL_BUTSUDAN + thisItemObj.img_2;
                    if(nextImage==='https://kyoto-butsudan.com/API/uploads/'){
                      var nextImage = settings.IMG_BASE_URL_BUTSUDAN + thisItemObj.img_3;
                    }
                  }
                  if(total===item.id){
                    if(mapView==='op-02'){
                      //console.log('LAST');
                      setTimeout(function(){
                        setMapView('op-1');
                      }, 300);//wait 1 seconds
                    }
                  }
                  return(
                    <>
                      <div key={item.id} >
                        <a href={settings.DEV+"/item?type=butsugu_single&db_id=" + item.db_id} >
                          <div className="item-box-relative butsugu-list-view-grid">
                            <div><img alt={"京都 仏壇 仏具 "+item.name} src={thisImage!=='https://kyoto-butsudan.com/API/uploads/' ? thisImage : nextImage}/></div>
                            <div className="butsugu-list-text">{item.name}</div>
                          </div>
                        </a>
                      </div>
                    </>
                  )
                  
                })}
            </div>
              
          </> }

        </InfiniteScroll>


      </div>
    </>
  );
}

export default ItemList;
