import { useEffect, useState } from "react";
import GoogleLogin from 'react-google-login';
import settings from '../settings.json';
import authList from './AuthList.json';

function GoogleAuth(){
  const handleLogin = (googleData) => {
    //console.log(googleData.Iu.yv);
    //console.dir(googleData);
    //console.dir(authList.member);
    var responseData = JSON.stringify(googleData);
    //if(authList.member.includes(googleData.Iu.yv)){
    //if(authList.member.includes(googleData.Qu.Gv)){
    for(var i = 0; i < authList.member.length; i++){
      var checkMail = authList.member[i];
      if ( responseData.indexOf(checkMail) != -1) {
        //console.log('OK!');
        document.cookie = '__kb_user_auth='+checkMail+'; max-age=31536000';//1年
        window.location.href = '/admin';
      }
    }
  }

  useEffect(() => {
    setTimeout(()=>document.getElementsByTagName('button')[0].click(), 2000);
  }, []);



  return(
    <>
      <GoogleLogin
        clientId={settings.GCP_ID}
        buttonText = 'ログイン'
        onSuccess={handleLogin}
        cookiePolicy={'single_host_origin'}
      >
      </GoogleLogin>
    
    </>
  )
}
export default GoogleAuth;